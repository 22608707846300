import * as React from 'react';
import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import DialogHeader from '../../../shared/components/dialogs/DialogHeader';
import DragAndDrop from '../../../shared/components/DragAndDrop';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';
import { useMemo, useState } from 'react';
import { Check, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid'
import { calculateMd5Hash } from '../../../shared/tools/fileUpladTools';
import { useS3Client } from '../../../clients/S3Client';
import { useFileApiClient } from '../../../clients/FileApiClient';
import { FileEntity } from '../../../model/FileEntity';
import { ErrorResponse } from '../../../clients/error/ErrorResponse';
import DisabledToolTip from '../../../shared/components/DisabledToolTip';
import { useTranslation } from 'react-i18next';
import EditableTextField from '../../../shared/components/EditableTextField';

export interface IUploadFileProps {
    bot_id: string
    subfolder?: string
    open?: boolean
    onClose?(): void
}

enum UploadStatus {
    PENDING, INITIALIZING, UPLOADING, FINISHED
}

interface FileItem {
    file: File
    id: string
    uploadProgress?: number
    status: UploadStatus,
    fileEntity?: FileEntity
}

const UploadFileModal: React.FunctionComponent<IUploadFileProps> = (props: IUploadFileProps) => {

    const { t } = useTranslation()

    const chatbotClient = useChatbotApiClient({ preventNotification: true })
    const fileClient = useFileApiClient()
    const s3Client = useS3Client()

    const [files, setFiles] = useState<FileItem[]>([])

    const [pageError, setPageError] = useState<string>()


    const handleDrop = (addedFiles: File[]) => {
        let newFiles = addedFiles.map(f => { return { file: f, id: uuidv4(), status: UploadStatus.PENDING } })
        setFiles([...files, ...newFiles])
        newFiles.forEach(f => announceAndUploadFile(f))
    }

    const updateFile = (f: FileItem) => {
        setFiles(files => files.map(cf => f.id === cf.id ? f : cf))
    }

    const announceAndUploadFile = async (f: FileItem) => {
        const md5Hash = await calculateMd5Hash(f.file)
        f.status = UploadStatus.INITIALIZING
        updateFile(f)
        await chatbotClient.createFileForChatbot(props.bot_id, { name: f.file.name, subfolder: (props.subfolder ? props.subfolder : "docs"), size: f.file.size, md5Hash: md5Hash })
            .then(fileEntity => {
                f.fileEntity = fileEntity
                f.status = UploadStatus.UPLOADING
                updateFile(f)
            })
            .then(() => s3Client.uploadFile(f.fileEntity!.uploadLink ?? '', f.file, (event) => {
                f.uploadProgress = event.progress
                updateFile(f)
            }))
            .then(() => fileClient.verifyFile(f.fileEntity!.id))
            .then(() => {
                f.status = UploadStatus.FINISHED
                updateFile(f)
            })
            .catch((e: ErrorResponse) => {
                setPageError(e.message)
                removeFile(f)
            })

    }

    const removeFile = (f: FileItem) => {
        if (f.fileEntity) {
            fileClient.deleteFile(f.fileEntity.id)
                .catch(() => { })
        }
        setFiles(files => files.filter(cf => cf.id !== f.id))
    }

    const onClose = () => {
        setFiles([])
        setPageError(undefined)
        props.onClose && props.onClose()
    }

    const allFilesFinished = useMemo(() => files.findIndex(f => f.status !== UploadStatus.FINISHED) === -1, [files])

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'lg'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={t('chatbot.uploadFiles.addFilesToChatbot')}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2}>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DragAndDrop
                                multiple
                                handleDrop={handleDrop}
                            />
                        </Grid>
                        {!allFilesFinished &&
                            <Grid item xs={12}>
                                <Typography variant='body1' align="center"><i>{t('chatbot.uploadFiles.uploadingFiles')}.</i></Typography>
                            </Grid>
                        }
                        <Grid item xs={12} container justifyContent='center' spacing={2} >
                            {files.map((f) => {
                                return <Grid key={f.id}
                                    item xs={12}
                                >
                                    <Paper elevation={3} >
                                        <Grid container sx={{ padding: "0.25em 0.5em" }}>
                                            <Grid item xs={12} container spacing={2} alignItems='center'>
                                                <Grid item xs={5}>
                                                    <Tooltip title={f.file.name}>
                                                        <Typography noWrap variant="body1">{f.file.name}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <EditableTextField
                                                        label={t('chatbot.fileTopic')}
                                                        value={f.fileEntity?.topic}
                                                        onValueChangeSubmit={topic => f.fileEntity &&
                                                            fileClient.updateFileTopic(f.fileEntity.id, topic)
                                                                .then(file => setFiles(files => files.map(mf => mf.id === f.id ? { ...mf, fileEntity: file } : mf)))
                                                                .catch((err: ErrorResponse) => setPageError(err.message))
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2} container alignItems={'center'} justifyContent={'flex-end'}>
                                                    {(f.uploadProgress && f.status === UploadStatus.UPLOADING) && <Grid item>
                                                        <Typography sx={{ color: theme => theme.palette.grey[500] }} variant="body2"> {(f.uploadProgress * 100).toFixed(0)}%</Typography>
                                                    </Grid>}
                                                    {f.status === UploadStatus.FINISHED && <Grid item>
                                                        <IconButton disabled >
                                                            <Check sx={{ color: (theme) => theme.palette.success.main }} fontSize='small' />
                                                        </IconButton>
                                                    </Grid>}
                                                    {(f.status === UploadStatus.INITIALIZING || f.status === UploadStatus.PENDING) && <Grid item>
                                                        <CircularProgress size='17px' />
                                                    </Grid>}
                                                    <Grid item>
                                                        <IconButton disabled={f.status !== UploadStatus.FINISHED} onClick={() => removeFile(f)} color="error">
                                                            <Delete fontSize='small' />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <DisabledToolTip disabled={!allFilesFinished} tooltip={t('chatbot.uploadFiles.filesStillUploading')}>
                        <LoadingButton disabled={!allFilesFinished} onClick={onClose} variant="contained">
                            {t('chatbot.uploadFiles.submit')}
                        </LoadingButton>
                    </DisabledToolTip>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UploadFileModal;