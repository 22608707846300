import {Box, Grid} from '@mui/material';
import * as React from 'react';
import Chat from "../../pages/chat/chatbot/Chat";
import Typewriter from "../components/Typewriter";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useChatbotApiClient} from "../../clients/ChatbotApiClient";
import {useCallback, useEffect, useState} from "react";
import {Chatbot} from "../../model/Chatbot";
import { VideoSearchMatch } from '../../model/ChatResponse';

export interface IIFrameBotIntegrationProps {
    botId?: string;
}

/*
All this content is intended to render in an iframe to include the chatbot in any page...
 */
const IFrameBotIntegration: React.FunctionComponent<IIFrameBotIntegrationProps> = (props: IIFrameBotIntegrationProps) => {
    const { t, i18n } = useTranslation()
    const [helperBotOpen, setHelperBotOpen] = React.useState<boolean>(false)
    const [mirrorIFrameIcon, setMirrorIFrameIcon] = React.useState<boolean>(false)
    const [hideChatIconInIFrame, setHideChatIconInIFrame] = React.useState<boolean>(false)
    const [questionToAsk, setQuestionToAsk] = React.useState<string>("")
    const [botId, setBotId] = React.useState<string | null>(null);
    const [bot, setBot] = useState<Chatbot>()
    const [userGaveConsent, setUserGaveConsent] = useState<boolean>(false)
    
    // Get botID from URL
    const location = useLocation();
    const extractBotId = (url: string): string | null => {
        const match = url.match(/\/bot\/([0-9a-fA-F-]{36})\/chat\/iframe\/undecorated/);
        return match ? match[1] : null;
    };
    React.useEffect(() => {
        if (props.botId) {
            setBotId(props.botId)
        } else {
            const extractedBotId = extractBotId(location.pathname);
            setBotId(extractedBotId);    
        }
        // eslint-disable-next-line
    }, [location.pathname]);
    
    const chatbotClient = useChatbotApiClient()
    const fetchChatbot = useCallback(() => {
        if (botId) {
            chatbotClient.getChatbot(botId)
                .then((theBot: Chatbot) => {
                    setBot(theBot)
                    if (theBot.settings?.mirrorIFrameIcon) {
                        setMirrorIFrameIcon(theBot.settings?.mirrorIFrameIcon)
                    }
                    if (theBot.settings?.hideChatIconInIFrame) {
                        setHideChatIconInIFrame(theBot.settings?.hideChatIconInIFrame)
                    }
                    if (theBot.settings?.chatInIFrameInitiallyOpen) {
                        setHelperBotOpen(theBot.settings?.chatInIFrameInitiallyOpen)
                    }
                    // todo: topics?
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    }, [botId, chatbotClient])

    useEffect(() => {
        fetchChatbot()
    }, [fetchChatbot])
    
    
    
    // listen for incomming postMessage events from parent window to the iFrame
    const questionHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'questionToAsk') return
        console.log("questionToAsk: " + e.data.message);
        setHelperBotOpen(true)
        setUserGaveConsent(true)
        setQuestionToAsk(e.data.message)
    }) as EventListener
    
    React.useEffect(() => {
        window.addEventListener("message", questionHandler);
        return () => window.removeEventListener('message', questionHandler)
        // eslint-disable-next-line
    }, []);

    
    // listen for incomming postMessage events from parent window to open the bot
    const botOpenCloseHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'toggleBotOpenState') return
        console.log("toggleBotOpenState: " + e.data.message);
        setHelperBotOpen((e.data.message === "true"))
    }) as EventListener

    React.useEffect(() => {
        window.addEventListener("message", botOpenCloseHandler);
        return () => window.removeEventListener('message', botOpenCloseHandler)
        // eslint-disable-next-line
    }, []);

    
    // listen for incomming postMessage events from parent window to agree on usage terms
    const botTermsConsentHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'toggleBotTermsConsentState') return
        console.log("toggleBotTermsConsentState: " + e.data.message);
        setUserGaveConsent((e.data.message === "true"))
    }) as EventListener

    React.useEffect(() => {
        window.addEventListener("message", botTermsConsentHandler);
        return () => window.removeEventListener('message', botTermsConsentHandler)
        // eslint-disable-next-line
    }, []);
    
    
    // listen for incoming postMessage events from parent window to the iFrame about changed languages
    const langHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'languageChange') return
        console.log("languageChange: " + e.data.message)
        i18n.changeLanguage(e.data.message)
    }) as EventListener
    
    React.useEffect(() => {
        window.addEventListener("message", langHandler);
        return () => window.removeEventListener('message', langHandler)
        // eslint-disable-next-line
    }, []);
    
    // Inform parent about the open/close state of the bot so parent can adjust size of our iFrame
    React.useEffect(() => {
        if (window.parent) {
            console.log("chatbotIsOpen: " + questionToAsk)
            window.parent.postMessage(
                {
                    type: 'chatbotIsOpen',
                    message: helperBotOpen + ""
                },
                '*'
            )
        }
        // eslint-disable-next-line
    }, [helperBotOpen])
    
    const sendEventFromIFrameToParent = (eventName: string, eventPayload: string) => {
        if (window.parent) {
            console.log("Send event '" + eventName + "' to parent with payload '" + eventPayload + "'")
            window.parent.postMessage(
                {
                    type: eventName,
                    message: eventPayload
                },
                '*'
            )
        }
    }
    
    const postVideoToIframe = (videoData: {title: string, url: string, jwt: string, searchMatches: VideoSearchMatch[], thumbnail: string}) => {
        window.parent.postMessage(
            {
                type: 'video_match',
                message: JSON.stringify(videoData)
            },
            '*'
        )
    }
    
    const toggleHelperBotOpen = () => {
        setHelperBotOpen(!helperBotOpen)
    }
    
    // https://neondigitalarts.com/how-to-make-a-gif-using-gimp-software/
    return (
        <>
            <Grid container justifyContent={"right"} spacing={0}
                  sx={{position: "fixed", right: "2px", bottom: "2px", zIndex: 1000}}
            >
                {helperBotOpen && bot && 
                    <Grid item xs={12} justifyContent='right'>
                        <Chat 
                            botId={bot.id}
                            botName={bot.name}
                            botDescription={bot.description}
                            botSettings={bot.settings}
                            nlpTask={"RAG"}
                            undecorated={true}
                            questionToAsk={(questionToAsk && questionToAsk.length > 0 ? questionToAsk : undefined )}
                            postVideoToIframe={postVideoToIframe}
                            userGaveConsent={userGaveConsent}
                            onClose={toggleHelperBotOpen}
                            sendEventFromIFrameToParent={sendEventFromIFrameToParent}
                        />
                    </Grid>
                }
    
                {!helperBotOpen && (bot?.settings?.showBookAnimationAboveIFrameIcon ? bot?.settings?.showBookAnimationAboveIFrameIcon : false) &&
                    <Grid item xs={12} container justifyContent='right'>
                        <Box src={"/img/animation/Books4.gif"} component="img"
                             sx={{ width: '50px'}}
                        />
                    </Grid>
                }
    
                {!helperBotOpen && (bot?.settings?.showIFrameHelperText ? bot?.settings?.showIFrameHelperText : false) && 
                    <Grid item xs={11} container justifyContent={'right'} 
                          sx={{paddingTop: (helperBotOpen ? '10px' : '3px'), paddingRight: '15px'}}>
                        <Typewriter
                            text={t("helperBot.iCanHelp")}
                            delay={80}
                            infinite={true}
                        />
                    </Grid>
                }

                {!hideChatIconInIFrame && 
                    <Grid item xs={(!helperBotOpen ? 1 : 12)} container justifyContent={'right'} 
                        sx={{paddingTop: (helperBotOpen ? '10px' : '3px'), cursor: 'pointer'}}>
                        <Box src={(bot?.settings?.icon ? bot?.settings?.icon : "/img/bb_solo_small2.png")} component="img"
                             onClick={toggleHelperBotOpen}
                             sx={{
                                width: '50px', 
                                transform: mirrorIFrameIcon ? 'scaleX(-1)': 'scaleX(1)',
                                borderRadius: '50%'
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default IFrameBotIntegration;