import * as React from 'react';
import {useState} from 'react';
import {Chatbot} from "../../../model/Chatbot";
import {
    Fab,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import Headline from "../../../shared/components/Headline";
import {t} from "i18next";
import {Add, Delete} from "@mui/icons-material";
import {useChatbotApiClient} from "../../../clients/ChatbotApiClient";
import {useNotificationContext} from "../../../shared/contexts/NotificationContext";
import Paper from "@mui/material/Paper";

export interface IAllowUserSettingProps {
    chatBot: Chatbot
    onBotChange?: (bot: Chatbot) => void
}

const AllowUserSetting: React.FunctionComponent<IAllowUserSettingProps> = (props: IAllowUserSettingProps) => {
    const chatbotClient = useChatbotApiClient()

    const notificationCtx = useNotificationContext();
    
    const [email, setEmail] = useState<string>("");


    const handleAddUser = () => {
        if (email) {
            chatbotClient.grantUserAccess(props.chatBot.id, email, {preventNotification: false})
                .then(props.onBotChange)
                .then(() => {
                    notificationCtx.notify({message: t('chatbot.settingsModal.userAdded'), severity: 'success'});
                    setEmail("");
                })
                .catch(() => {
                });
        }
    }
    const handleRemoveUser = (email: string) => {
        chatbotClient.revokeUserAccess(props.chatBot.id, email, {preventNotification: false})
            .then(props.onBotChange)
            .then(() => {
                notificationCtx.notify({message: t('chatbot.settingsModal.userRemoved'), severity: 'success'});
            })
            .catch(() => {
            });
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} justifyContent='right' sx={{ marginBottom: '0.5em'}}>
                    <Headline title={t('chatbot.settingsModal.allowUsers')} size='section'/>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label={t('chatbot.settingsModal.email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('chatbot.settingsModal.enterEmail')}
                    />
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                    <Fab
                        color="primary"
                        aria-label="add"
                        title={t('chatbot.settingsModal.addUser')}
                        disabled={!email}
                        onClick={handleAddUser}
                    >
                        <Add/>
                    </Fab>
                </Grid>
                <Grid item xs={12}>
                    <Headline title={t('chatbot.settingsModal.allowedUsers')} size='section'/>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('chatbot.settingsModal.username')}</TableCell>
                                    <TableCell>{t('chatbot.settingsModal.email')}</TableCell>
                                    <TableCell>{t('chatbot.settingsModal.actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.chatBot.allowedUsers?.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleRemoveUser(user.email)}
                                                    title={t('chatbot.settingsModal.removeUser')}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {props.chatBot.allowedUsers?.length === 0 && <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                        <i>{t('chatbot.settingsModal.noUsersAllowed')} </i>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default AllowUserSetting;