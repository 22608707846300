import {
    Grid, IconButton,
    Paper,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import InputBase from "@mui/material/InputBase";
import {IoIosSend} from "react-icons/io";
import ListItem from "@mui/material/ListItem";

export interface IShowroomProps {
}

const chatbots = [
    "6496555a-00f6-48b7-9b27-6dc65ca75c2c",  // Baden-Baden
    "c4260523-216a-47e1-ba66-a4b522c7ab23",  // Bretten 
    "376b6b31-7bd1-447f-99af-b1c3a636b97f",  // Bruchsal
    "5e2605d7-dd56-43eb-aa37-807ee9c2fbec",  // Bühl
    "3e995b63-4bea-4483-9b3c-dff3649b3661",  // Ettlingen
    "e4ccf189-a535-435a-8cef-e5010549d099",  // Gaggenau
    "7fe601fd-c018-4eea-b096-fab1bf2781b6",  // Rastatt
    "d1b4c389-e734-452e-a085-fe35291f9e51",  // Rheinstetten
    "21b02142-2eb5-478b-bba0-191bab6b80ca",  // Stutensee

]

const Showroom: React.FunctionComponent<IShowroomProps> = (props: IShowroomProps) => {

    const [userResponse, setUserResponse] = useState<string>("");

    const iframeRefs = React.useRef<{ [key: string]: HTMLIFrameElement | null }>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserResponse(e.target.value);
    };

    const handleSubmit = (theQuestion: string, extraParam?: string) => {
        chatbots.forEach((botId: string) => {
            sendQuestionToChatbot(botId, theQuestion)
        })
    }

    // Function to send a question to the chatbot iframe
    const sendQuestionToChatbot = (botId: string, questionToAsk: string) => {
        const iframe = iframeRefs.current[botId];
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
                { type: 'questionToAsk', message: questionToAsk }, // Message format
                '*' // Target origin
            );
        }
    };

    return (
        <>
            <Grid container justifyContent="center" spacing={2}>
                {chatbots.map((botId:string, idx: number) => {
                    
                    return (     
                        <>
                            {idx === 5 &&
                                <Grid container item xs={6} sx={{ }}>
                                    <ListItem alignItems="center" sx={{ justifyContent: "center", alignItems: "center", padding: "20px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        <h2>Chat mit den re@di Städten!</h2>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ margin: "20px", padding: "0px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        <Paper
                                            component="form"
                                            sx={{
                                                p: '8px 8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: "100%",
                                                borderBottomLeftRadius: "10px",
                                                backgroundColor: "white",
                                                borderBottomRightRadius: "10px",
                                                borderTopRightRadius: "10px",
                                                borderTopLeftRadius: "10px",
                                            }}
                                        >
                                            
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder={"Ihre EIngabe"}
                                                inputProps={{ 'aria-label': 'user input' }}
                                                onChange={e => handleInputChange(e)}
                                                value={userResponse}
                                            />
                                            <IconButton sx={{color: "gray"}} onClick={
                                                () => handleSubmit(userResponse, "")
                                            }>
                                                 <IoIosSend />
                                            </IconButton>
                                        </Paper>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ padding: "20px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        
                                    </ListItem>
                                </Grid>
                            }
                            <Grid container item xs={3} sx={{ height: '450px', zoom: 0.65 }}>
                                    <iframe
                                        ref={(el) => {
                                            iframeRefs.current[botId] = el;
                                        }}
                                        title={"HelperBot"}
                                        src={"https://int.botbucket.de/bot/" + botId + "/chat/undecorated"}
                                        style={{borderWidth: "0px", width: "500px"}}
                                        allowTransparency
                                    />
                            </Grid>
                            
                        </>
                    )
                })}
            </Grid>
        </>
    );
}

export default Showroom;