import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// https://locize.com/blog/react-i18next/

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            de: {
                translation: {
                    dragAndDrop: {
                        chooseFile: 'Datei auswählen oder hierhin ziehen'
                    },
                    common: {
                        somethingWentWrong: 'Hups, da ist etwas schief gelaufen!',
                        entriesPerPage: 'Einträge pro Seite',
                        confirmPhrase: 'Bitte <strong>{{confirmPhrase}}</strong> zum Bestätigen eingeben',
                        confirm: 'Bestätigen',
                        cancel: 'Abbrechen',
                        copiedToClipboard: 'Zur Zwischenablage hinzugefügt!',
                    },
                    homepage: {
                        header: {
                            products: "Produkte",
                            pricing: "Preise",
                            aboutUs: "Über Uns",
                            docs: "Doku",
                            contact: "Kontakt",
                        },
                        hero0: "Ihr maßgescheiderter Chatbot-Assistent. Füttern Sie ihn mit Ihren Handbüchern, Webseiten und FAQs, um Kundenanfragen automatisch, intelligent und präzise zu beantworten. Eine benutzerfreundliche, interaktive und mehrsprachige Software-as-a-Service Anwendung für effizienten und personalisierten Kundensupport.",
                        hero1: "Registrieren",
                        section0: "Individuell trainierter Chatbot-Assistent für eine maßgeschneiderte Kundeninteraktion.",
                        section1: "Entlastung ihrer Fachexperten durch KI-gestützte Übernahme der meisten Supportanfragen.",
                        section2: "Optimierte Benutzererfahrung durch kontextuelles Lernen, interaktives Training und kontinuierlicher Verbesserung der Antwortqualität.",
                        section3: "Globale Reichweite und Flexibilität durch die Unterstützung vieler Sprachen, Quellen und Dokumententypen.",
                        section4: "Ein flexibler Zahlungsplan steht für all unsere Dienstleistungen bereit.",
                        tech0: "Technologie",
                        tech1: "BotBucket verwendet die NLP-Technik der 'kontextbewussten Frage-Beantwortung': Die von Ihnen bereitgestellten Informationen werden in einen einheitlichen Speicher geladen, der für die natürliche Sprachverarbeitung (NLP) optimiert ist. Dieser Speicher wird während des Chats verwendet, um relevante Informationsblöcke in Bezug auf die Frage des Benutzers zu finden. Ein KI-Modell kombiniert diese Blöcke dann zu einer sinnvollen Antwort.",
                        tech2: "Mehr Erfahren",
                        aboutUs0: "Über Uns",
                        aboutUs1: "Willkommen bei BotBucket! Hier füttern Sie die neusten GPT-Modelle mit Ihren Dokumenten. So erstellen Sie Ihren individuellen Chat-Assistent in Sekunden. Mit BotBucket verbinden Sie Ihren Wissensschatz mit den Möglichkeiten modernster natürlicher Sprachverarbeitung. So entlastet BotBucket ihren Kundensupprt, schafft Transparenz und verbessert Ihre Prozesse.",
                        aboutUs2: "Kontakt",
                        contact0: "Kontaktieren Sie uns!",
                        contact1: "Name",
                        contact2: "Email",
                        contact3: "Betreff",
                        contact4: "Nachricht",
                        contact5: "Absenden",
                    },
                    userMenu: {
                        login: "Einloggen",
                        profile: "Profil",
                        settings: "Einstellungen",
                        logout: "Ausloggen",
                    },
                    dashboard: {
                        welcome: "Willkommen",
                        myChatbots: "Meine Chatbots",
                        newChatbot: "Neuer Chatbot",
                        instantChatbot: "Instant Chatbot",
                    },
                    chatbot: {
                        myBot: 'Mein Bot - {{botName}}',
                        settings: 'Einstellungen',
                        fileTopic: 'Thema (, getrennt)',
                        topic: 'Thema',
                        includedInTraining: 'Diese Datei wurde im letzten Training berücksichtigt und nun vom Chatbot bekannt.',
                        notIncludedInTraining: 'Diese Datei wurde noch nicht berücksichtig. Jetzt trainieren, um diese Datei mit einzubeziehen.',
                        trainingStatus: {
                            notTrained: 'Noch nicht trainiert',
                            pending: 'Training startet bald',
                            training: 'Training läuft',
                            trained: 'Training war erfolgreich',
                            failed: 'Training ist fehlgeschlagen',
                            label: 'Training Status'
                        },
                        settingsModal: {
                            customizeYourBot: 'Passe deinen Chatbot an!',
                            publicAvailability: 'Öffentliche Verfügbarkeit',
                            publicAvailable: 'Ihr Chatbot ist für alle sichtbar. Sie können Ihn mit dem unten stehenden Link teilen.',
                            notPublicAvailable: 'Nur Sie können ihren Chatbot sehen. Durch den Toggle können Sie ihn für alle sichtbar machen.',
                            publicLink: 'Öffentlicher Link',
                            deleteYourBot: 'Ihren Chatbot löschen',
                            allowUsers: 'Benutzer berechtigen',
                            searchUser: 'Benutzer suchen',
                            addUser: 'Benutzer hinzufügen',
                            allowedUsers: 'Berechtigte Benutzer',
                            username: 'Nutzername',
                            email: 'Email',
                            enterEmail: 'Email des zu berechtigen Nutzers eingeben',
                            actions: 'Aktionen',
                            userAdded: 'Benutzer hinzugefügt',
                            userRemoved: 'Benutzer entfernt',
                            noUsersAllowed: 'Keine Benutzer berechtigt',
                            removeUser: 'Benutzer entfernen',
                        },
                        uploadFiles: {
                            addFilesToChatbot: 'Dokumente zum Chatbot hinzufügen',
                            uploadingFiles: 'Dokumente hochladen...',
                            filesStillUploading: 'Manche Dokumente sind noch nicht vollständig hochgeladen, bitte warten',
                            submit: 'Bestätigen'
                        },
                        trainChatbot: {
                            trainYourChatbot: 'Trainiere deinen Chatbot',
                            embeddings: 'Embeddings',
                            startTraining: 'Starte Training'
                        },
                        botNotTrainedNoTrainingPossible: 'Bot ist nicht trainiert - aktuell noch kein Chat möglich',
                        chat: 'Chat',
                        botTraining: 'Bot ist aktuell im Training - bitte warten',
                        trainNow: 'Trainiere jetzt',
                        uploadedDocuments: 'Trainings-Dokumente',
                        document: 'Dokument',
                        uploadedDate: 'Upload Datum',
                        noFilesUploaded: 'Noch keine Dokumente hochgeladen',
                        deleteBot: 'Chatbot Löschen',
                        confirmDelete: 'Möchten Sie wirklich Ihren Chatbot {{botName}} löschen?'
                    },
                    chatbots: {
                        myChatbots: "Meine Chatbots",
                        newChatbot: "Neuer Chatbot",
                        chat: "Chatten",
                        edit: "Bearbeiten",
                        name: "Name",
                        numTrainingDocs: "# Trainings-Dokumente",
                        numQueries: "# Anfragen",
                        trainingStatus: "Status",
                        noChatbotCreated: 'Es sind noch keine Chatbots vorhanden.',
                        delete: 'Löschen',
                    },
                    createChatbots: {
                        createNewBotHeadline: 'Neuen Chatbot anlegen',
                        name: 'Name',
                        description: 'Beschreibung',
                        createBot: 'Bot anlegen'
                    },
                    chat: {
                        help: {
                            whatAreTopicsGoodFor: "Wozu sind 'Topics' gut in meinem Chat?",
                        },
                        topic: "Thema",
                        myChatWith: "Mein Chat mit {{botName}}",
                        initMessage: "Hi, ich bin {{botName}}. Ich weiß alles über {{description}}. Was möchtest du wissen?",
                        initMessageCIS: "Hi, ich bin {{botName}}. Ich möchte mehr über Ihre täglichen Aufgaben erfahren, um sie festzuhalten. Das soll Ihnen und Ihren Kolleg*innen helfen, sich besser einzuarbeiten oder Sie zu vertreten. ",
                        foundTheseSources: "Weitere Informationen hier:",
                        userInput: "Deine Nachricht",
                        dontSharePersonalData: "Chatverlauf wird für drei Monate gespeichert. Bitte teile keine personenbezogenen Daten.",
                    },
                    helperBot: {
                        iCanHelp: "Ich kann dir helfen!   ",
                        helpBotWelcome: "Hi, ich bin BotBucket. Wie kann ich helfen?",
                    },
                    login: {
                        login: 'Einloggen',
                        signUp: 'Registrieren',
                        username: 'Nutzername',
                        password: 'Passwort',
                        passwordRepeat: 'Passwort (wdh.)',
                        mail: 'Email',
                        navToLogin: 'Einloggen',
                        navToSignUp: 'Registrieren',
                        usernameOrEmail: 'Nutzername oder Email',
                        forgotPassword: 'Passwort vergessen?',
                        resetPassword: 'Passwort zurücksetzen',
                        reset: 'Zurücksetzen',
                        passwordResetted: 'Passwort zurückgesetzt',
                        thanksWeSentResetMail: 'Vielen Dank! Wir haben Ihnen eine Email mit weiteren Anleitungen gesendet.',
                        passwordSuccessfullyResettedLoginAgain: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit Ihrem neuen Passwort einloggen.',
                        helloPleaseChooseNewPassword: 'Hey, {{username}}! Bitte legen Sie Ihr neues Passwort fest!',
                        newPassword: 'Neues Passwort',
                        newPasswordRepeat: 'Neues Passwort (wdh)',
                        resetLinkNotValid: 'Leider ist Ihr Link ungültig, oder abgelaufen. Bitte fragen Sie erneut die Zurücksetzung Ihres Passworts an.',
                        or: 'Oder',
                        userNotFound: 'Benutzer nicht gefunden - bitte',
                        userAlreadyTaken: 'Benutzer bereits vergeben - bitte',
                    },
                    validations: {
                        usernameMinChar: 'Nutzername muss mind. 3 Zeichen lang sein',
                        chatbotNameMinChar: 'Der Chatbot Name muss mind. 6 Zeichen lang sein',
                        enterValidMail: 'Email ist nicht valid',
                        passwordMinChar: 'Passwort muss mind. 8 Zeichen lang sein',
                        passwordContainDigit: 'Passwort muss eine Zahl enthalten',
                        passwordContainUppercase: 'Passwort muss einen Großbuchstaben enthalten',
                        passwordContainLowercase: 'Passwort muss einen Kleinbuchstaben enthalten',
                        passwordSame: 'Die Passwörter müssen übereinstimmen',

                    },
                    prices: {
                        price: "Preis",
                        join: "Beitreten",
                        susbcribe: "Subscribe",
                        active: "Aktiv",
                    },
                    profile: {
                        updateProfileButton: 'Profil Updaten',
                        noCustomer: 'Keinen Kunde angelegt',
                        pricesButton: 'Übersicht der Produkte',
                        createCustomer: 'Profil aktualisieren',
                    },
                    overview: {
                        free: {
                            description1: 'Maximale Anzahl an Seiten: 15',
                            description2: 'Maximale Aufrufe: 10',
                            description3: 'Weitere Informationen...',
                        },
                        basic: {
                            description1: 'Maximale Anzahl an Seiten: 20',
                            description2: 'Maximale Aufrufe: 50',
                            description3: 'Weitere Informationen...',
                        },
                        premium: {
                            description1: 'Maximale Anzahl an Seiten: 50',
                            description2: 'Maximale Aufrufe: 100',
                            description3: 'Weitere Informationen...',
                        }
                    },
                    success: {
                        headline: {
                            success: 'Subscription erfolgreich!',
                            updated: 'Profile akutalisiert!',
                            cancel: 'Ein Fehler ist aufgetreten!',
                            created: 'Kunde erfolgreich angelegt!',
                        },
                        invoice: 'Invoice einsehen: ',
                        invoiceLink: 'Invoice',
                        button: 'Zurück zum Menu!',
                    },
                }
            },
            
            en: {
                translation: {
                    dragAndDrop: {
                        chooseFile: 'Choose a file or drag it here'
                    },
                    common: {
                        somethingWentWrong: 'Whoops, something went wrong!',
                        entriesPerPage: 'Entries per page',
                        confirmPhrase: 'Please type <strong>{{confirmPhrase}}</strong> to confirm.',
                        confirm: 'Confirm',
                        cancel: 'Cancel',
                        copiedToClipboard: 'Copied to clipboard!'
                    },
                    homepage: {
                        header: {
                            products: "Products",
                            pricing: "Pricing",
                            aboutUs: "About Us",
                            docs: "Docs",
                            contact: "Contact",
                        },
                        hero0: "Your tailor-made chatbot assistant. Feed it with your manuals, websites, and FAQs to automatically, intelligently, and precisely answer customer inquiries. A user-friendly, interactive, and multilingual Software-as-a-Service application for efficient and personalized customer support.",
                        hero1: "Sign Up",
                        section0: "Custom-trained chatbot assistant for tailored customer interaction.",
                        section1: "Relief for your experts through AI-supported handling of most support inquiries.",
                        section2: "Optimized user experience through contextual learning, interactive training, and continuous improvement of response quality.",
                        section3: "Global reach and flexibility through the support of many languages, sources, and document types.",
                        section4: "A flexible payment plan is available for all our services.",
                        tech0: "Technology",
                        tech1: "BotBucket uses the NLP technique of 'context-aware question answering.': The information you provide will be loaded into a unified storage optimized for natural language processing (NLP). This storage is used during chats to find relevant chunks of information related to the user's question. An AI model combines these chunks to a meaningful answer.",
                        tech2: "Read more",
                        aboutUs0: "About Us",
                        aboutUs1: "Welcome to BotBucket! Here, you feed the latest GPT models with your documents, creating your personalized chat assistant in seconds. With BotBucket, you connect your knowledge base with the capabilities of cutting-edge natural language processing. In doing so, BotBucket relieves your customer support, creates transparency, and improves your processes.",
                        aboutUs2: "Contact",
                        contact0: "Contact us!",
                        contact1: "Name",
                        contact2: "Email",
                        contact3: "Subject",
                        contact4: "Message",
                        contact5: "Submit",
                    },
                    dashboard: {
                        welcome: "Welcome",
                        myChatbots: "My Chatbots",
                        newChatbot: "New Chatbot",
                        instantChatbot: "Instant Chatbot",
                    },
                    userMenu: {
                        login: "Login",
                        profile: "Profile",
                        settings: "Settings",
                        logout: "Logout",
                    },
                    chatbot: {
                        myBot: 'My Bot - {{botName}}',
                        settings: 'Settings',
                        fileTopic: 'Topic (sep by ,)',
                        topic: 'Topic',
                        includedInTraining: 'This file was used in the last training and is included in the knowledge of your bot.',
                        notIncludedInTraining: 'This file was not used in any training yet. Train now to include this file.',
                        trainingStatus: {
                            notTrained: 'Not trained yet',
                            pending: 'Training will start soon',
                            training: 'Training is running',
                            trained: 'Training was successful',
                            failed: 'Training failed',
                            label: 'Training Status'
                        },
                        settingsModal: {
                            customizeYourBot: 'Customize your Chatbot!',
                            publicAvailability: 'Public Availability',
                            publicAvailable: 'Your chatbot is visible to anyone. You can share it using the link below.',
                            notPublicAvailable: 'Your chatbot is only visible to you. Click to toggle the Availability.',
                            publicLink: 'Public Link',
                            deleteYourBot: 'Delete your Chatbot',
                            allowUsers: 'Permit users',
                            searchUser: 'Search user',
                            addUser: 'Add user',
                            allowedUsers: 'Permitted users',
                            username: 'Username',
                            email: 'Email',
                            enterEmail: 'Enter the email of the user you want to permit',
                            actions: 'Actions',
                            userAdded: 'User added',
                            userRemoved: 'User removed',
                            noUsersAllowed: 'No users permitted',
                            removeUser: 'Remove user'
                        },
                        uploadFiles: {
                            addFilesToChatbot: 'Add files to chatbot',
                            uploadingFiles: 'Uploading Files...',
                            filesStillUploading: 'Some files are still uploading, please wait for them to finish',
                            submit: 'Submit'
                        },
                        trainChatbot: {
                            trainYourChatbot: 'Train your chatbot',
                            embeddings: 'Embeddings',
                            startTraining: 'Start training'
                        },
                        botNotTrainedNoTrainingPossible: 'Bot is not trained - no chat possible at the moment.',
                        chat: 'Chat',
                        botTraining: 'Bot is already training - wait for it to finish',
                        trainNow: 'Train now',
                        uploadedDocuments: 'Training documents',
                        document: 'Document',
                        uploadedDate: 'Upload Date',
                        noFilesUploaded: 'No files uploaded yet',
                        deleteBot: 'Delete Chatbot',
                        confirmDelete: 'Do you really want to delete your chatbot {{botName}}?'
                    },
                    chatbots: {
                        myChatbots: "My Chatbots",
                        newChatbot: "New Chatbot",
                        chat: "Chat",
                        edit: "Edit",
                        name: "Name",
                        numTrainingDocs: "# training documents",
                        trainingStatus: "status",
                        numQueries: "# queries",
                        noChatbotCreated: 'You have no chatbots created yet.',
                        delete: 'Delete'
                    },
                    createChatbots: {
                        createNewBotHeadline: 'Create new Chatbot',
                        name: 'Name',
                        description: 'Description',
                        createBot: 'Create new Bot'
                    },
                    chat: {
                        help: {
                            whatAreTopicsGoodFor: "What are 'Topics' good for in my chat?",
                        },
                        topic: "Topic",
                        myChatWith: "My chat with {{botName}}",
                        initMessage: "Hi, I'm {{botName}}, I can tell you about {{description}}. How may I serve?",
                        initMessageCIS: "Hi, I'm {{botName}}. I would like to learn more about your daily tasks in order to document them. This will help you and your colleagues to get better acquainted with the work or to substitute for you.",
                        foundTheseSources: "Further information here:",
                        userInput: "Your Message",
                        dontSharePersonalData: "Chat history is stored for three months. Please do not share any personal data.",
                    },
                    helperBot: {
                        iCanHelp: "I can help you!   ",
                        helpBotWelcome: "Hi, I'm BotBucket. How may I serve?",
                    },
                    login: {
                        login: 'Login',
                        signUp: 'Sign up',
                        username: 'Username',
                        password: 'Password',
                        passwordRepeat: 'Password (repeat)',
                        mail: 'Email',
                        navToLogin: 'Login',
                        navToSignUp: 'Sign up',
                        usernameOrEmail: 'Username or email',
                        forgotPassword: 'Forgot your password?',
                        resetPassword: 'Reset password',
                        reset: 'Reset',
                        passwordResetted: 'Pasword resetted',
                        thanksWeSentResetMail: 'Thanks! We\'ve sent an email with further instructions.',
                        passwordSuccessfullyResettedLoginAgain: 'Your password was resetted successfully. You can proceed to login now.',
                        helloPleaseChooseNewPassword: 'Hey, {{username}}! Please choose your new password!',
                        newPassword: 'New password',
                        newPasswordRepeat: 'New password (repeat)',
                        resetLinkNotValid: 'Unfortunately, your reset link is invalid or expired. Please try to reset the password again.',
                        or: 'Or',
                        userNotFound: 'User not found - please',
                        userAlreadyTaken: 'User already registered - please',
                    },
                    validations: {
                        usernameMinChar: 'Username must have at least 3 characters',
                        chatbotNameMinChar: 'Chatbot name must have at least 6 characters',
                        enterValidMail: 'Please enter a valid email',
                        passwordMinChar: 'password must have at least 8 characters',
                        passwordContainDigit: 'password must contain a digit',
                        passwordContainUppercase: 'password must contain an uppercase letter',
                        passwordContainLowercase: 'password must contain a lowercase letter',
                        passwordSame: 'passwords must be the same',

                    },
                    prices: {
                        price: "Price",
                        join: "Join",
                        subscribe: "Subscribe",
                        active: "Active",
                    },
                    profile: {
                        updateProfileButton: 'Update Profile',
                        noCustomer: 'No customer created',
                        pricesButton: 'Overview of products',
                        createCustomer: 'Update profile',
                    },
                    overview: {
                        free: {
                            description1: 'Maximum number of pages: 15',
                            description2: 'Maximum calls: 10',
                            description3: 'Further information...',
                        },
                        basic: {
                            description1: 'Maximum number of pages: 20',
                            description2: 'Maximum calls: 50',
                            description3: 'Further information...',
                        },
                        premium: {
                            description1: 'Maximum number of pages: 50',
                            description2: 'Maximum calls: 100',
                            description3: 'Further information...',
                        }
                    },
                    success: {
                        headline: {
                            success: 'Subscription successfull!',
                            updated: 'Profile updated!',
                            cancel: 'An error occured!',
                            created: 'Customer successfully created!',
                        },
                        invoice: 'See invoice here: ',
                        invoiceLink: 'Invoice',
                        button: 'Back to Menu!',
                    },
                }
            },

            // Türkisch
            "tr": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Dosya seç veya buraya sürükle"
                    },
                    "common": {
                        "somethingWentWrong": "Hata, bir şeyler ters gitti!",
                        "entriesPerPage": "Sayfa başına giriş",
                        "confirmPhrase": "Onaylamak için lütfen <strong>{{confirmPhrase}}</strong> yazın",
                        "confirm": "Onayla",
                        "cancel": "İptal",
                        "copiedToClipboard": "Panoya kopyalandı!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Ürünler",
                            "pricing": "Fiyatlar",
                            "aboutUs": "Hakkımızda",
                            "docs": "Belgeler",
                            "contact": "İletişim"
                        },
                        "hero0": "Özel yapım sohbet botu asistanınız. Kullanıcı kılavuzları, web siteleri ve SSS'lerle besleyerek müşteri sorularını otomatik, akıllı ve doğru bir şekilde yanıtlayın. Verimli ve kişiselleştirilmiş müşteri desteği için kullanıcı dostu, interaktif ve çok dilli bir Hizmet olarak Yazılım uygulaması.",
                        "hero1": "Kayıt Ol",
                        "section0": "Kişiye özel müşteri etkileşimi için bireysel olarak eğitilmiş sohbet botu asistanı.",
                        "section1": "Uzmanlarınızın çoğu destek sorularını yapay zeka ile yanıtlayarak yükünü hafifletin.",
                        "section2": "Bağlamsal öğrenme, etkileşimli eğitim ve sürekli yanıt kalitesi iyileştirme ile optimize edilmiş kullanıcı deneyimi.",
                        "section3": "Birçok dil, kaynak ve belge türünü destekleyerek küresel erişim ve esneklik.",
                        "section4": "Tüm hizmetlerimiz için esnek bir ödeme planı mevcuttur.",
                        "tech0": "Teknoloji",
                        "tech1": "BotBucket, 'bağlamsal soru-cevap' NLP tekniğini kullanır: Sağladığınız bilgiler, doğal dil işleme (NLP) için optimize edilmiş birleşik bir belleğe yüklenir. Bu bellek, sohbet sırasında kullanıcının sorusuna ilişkin ilgili bilgi bloklarını bulmak için kullanılır. Bir yapay zeka modeli, bu blokları anlamlı bir yanıt oluşturmak için birleştirir.",
                        "tech2": "Daha Fazla Bilgi",
                        "aboutUs0": "Hakkımızda",
                        "aboutUs1": "BotBucket'e hoş geldiniz! Burada en yeni GPT modellerini belgelerinizle besliyorsunuz. Bu şekilde birkaç saniye içinde kişisel sohbet asistanınızı oluşturabilirsiniz. BotBucket ile bilgi hazinenizi modern doğal dil işleme olanaklarıyla birleştiriyorsunuz. Bu, müşteri desteğinizi hafifletir, şeffaflık yaratır ve süreçlerinizi iyileştirir.",
                        "aboutUs2": "İletişim",
                        "contact0": "Bize Ulaşın!",
                        "contact1": "Ad",
                        "contact2": "E-posta",
                        "contact3": "Konu",
                        "contact4": "Mesaj",
                        "contact5": "Gönder"
                    },
                    "userMenu": {
                        "login": "Giriş Yap",
                        "profile": "Profil",
                        "settings": "Ayarlar",
                        "logout": "Çıkış Yap"
                    },
                    "dashboard": {
                        "welcome": "Hoş Geldiniz",
                        "myChatbots": "Sohbet Botlarım",
                        "newChatbot": "Yeni Sohbet Botu",
                        "instantChatbot": "Anında Sohbet Botu"
                    },
                    "chatbot": {
                        "myBot": "Botum - {{botName}}",
                        "settings": "Ayarlar",
                        "fileTopic": "Konu (, ile ayrılmış)",
                        "topic": "Konu",
                        "includedInTraining": "Bu dosya son eğitimde dikkate alındı ve şimdi sohbet botu tarafından biliniyor.",
                        "notIncludedInTraining": "Bu dosya henüz dikkate alınmadı. Bu dosyayı dahil etmek için şimdi eğitin.",
                        "trainingStatus": {
                            "notTrained": "Henüz eğitilmedi",
                            "pending": "Eğitim yakında başlayacak",
                            "training": "Eğitim devam ediyor",
                            "trained": "Eğitim başarılı",
                            "failed": "Eğitim başarısız",
                            "label": "Eğitim Durumu"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Sohbet botunuzu özelleştirin!",
                            "publicAvailability": "Herkese Açık Kullanılabilirlik",
                            "publicAvailable": "Sohbet botunuz herkes tarafından görülebilir. Aşağıdaki bağlantıyı paylaşabilirsiniz.",
                            "notPublicAvailable": "Sadece siz sohbet botunuzu görebilirsiniz. Botu herkese açık hale getirmek için geçiş düğmesini kullanabilirsiniz.",
                            "publicLink": "Herkese Açık Bağlantı",
                            deleteYourBot: 'Chatbot\'unuzu silin',
                            allowUsers: 'Kullanıcıları izin ver',
                            searchUser: 'Kullanıcı ara',
                            addUser: 'Kullanıcı ekle',
                            allowedUsers: 'İzin verilen kullanıcılar',
                            username: 'Kullanıcı adı',
                            email: 'E-posta',
                            enterEmail: 'İzin vermek istediğiniz kullanıcının e-postasını girin',
                            actions: 'Eylemler',
                            userAdded: 'Kullanıcı eklendi',
                            userRemoved: 'Kullanıcı kaldırıldı',
                            noUsersAllowed: 'İzin verilen kullanıcı yok',
                            removeUser: 'Kullanıcıyı kaldır',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Sohbet Botuna Belgeler Ekle",
                            "uploadingFiles": "Belgeler Yükleniyor...",
                            "filesStillUploading": "Bazı belgeler henüz tamamen yüklenmedi, lütfen bekleyin",
                            "submit": "Onayla"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Sohbet Botunu Eğit",
                            "embeddings": "Yerleştirmeler",
                            "startTraining": "Eğitimi Başlat"
                        },
                        "botNotTrainedNoTrainingPossible": "Bot eğitilmedi - şu anda sohbet mümkün değil",
                        "chat": "Sohbet",
                        "botTraining": "Bot şu anda eğitimde - lütfen bekleyin",
                        "trainNow": "Şimdi Eğit",
                        "uploadedDocuments": "Eğitim Belgeleri",
                        "document": "Belge",
                        "uploadedDate": "Yüklenme Tarihi",
                        "noFilesUploaded": "Henüz belge yüklenmedi",
                        "deleteBot": "Sohbet Botunu Sil",
                        "confirmDelete": "Sohbet botunuzu {{botName}} gerçekten silmek istiyor musunuz?"
                    },
                    "chatbots": {
                        "myChatbots": "Sohbet Botlarım",
                        "newChatbot": "Yeni Sohbet Botu",
                        "chat": "Sohbet Et",
                        "edit": "Düzenle",
                        "name": "İsim",
                        "numTrainingDocs": "# Eğitim Belgeleri",
                        "numQueries": "# Sorgular",
                        "trainingStatus": "Durum",
                        "noChatbotCreated": "Henüz sohbet botu oluşturulmadı.",
                        "delete": "Sil"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Yeni Sohbet Botu Oluştur",
                        "name": "İsim",
                        "description": "Açıklama",
                        "createBot": "Botu Oluştur"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "'Konular' sohbetimde ne işe yarar?"
                        },
                        "topic": "Konu",
                        "myChatWith": "{{botName}} ile Sohbetim",
                        "initMessage": "Merhaba, ben {{botName}}. {{description}} hakkında her şeyi biliyorum. Ne bilmek istersiniz?",
                        "initMessageCIS": "Merhaba, ben {{botName}}. Günlük görevleriniz hakkında daha fazla bilgi edinmek istiyorum, böylece onları kaydedebilirim. Bu, sizin ve meslektaşlarınızın daha iyi bir şekilde işe alışmanıza veya yerinizi doldurmanıza yardımcı olacaktır.",
                        "foundTheseSources": "Daha fazla bilgi burada:",
                        "userInput": "Girdiğiniz",
                        "dontSharePersonalData": "Sohbet geçmişi üç ay boyunca saklanır. Lütfen kişisel verileri paylaşmayın."
                    },
                    "helperBot": {
                        "iCanHelp": "Size yardımcı olabilirim!",
                        "helpBotWelcome": "Merhaba, ben BotBucket. Nasıl yardımcı olabilirim?"
                    },
                    "login": {
                        "login": "Giriş Yap",
                        "signUp": "Kayıt Ol",
                        "username": "Kullanıcı Adı",
                        "password": "Şifre",
                        "passwordRepeat": "Şifre (tekrar)",
                        "mail": "E-posta",
                        "navToLogin": "Giriş Yap",
                        "navToSignUp": "Kayıt Ol",
                        "usernameOrEmail": "Kullanıcı adı veya E-posta",
                        "forgotPassword": "Şifrenizi mi unuttunuz?",
                        "resetPassword": "Şifreyi Sıfırla",
                        "reset": "Sıfırla",
                        "passwordResetted": "Şifre sıfırlandı",
                        "thanksWeSentResetMail": "Teşekkürler! Size daha fazla talimat içeren bir e-posta gönderdik.",
                        "passwordSuccessfullyResettedLoginAgain": "Şifreniz başarıyla sıfırlandı. Yeni şifrenizle giriş yapabilirsiniz.",
                        "helloPleaseChooseNewPassword": "Merhaba, {{username}}! Lütfen yeni şifrenizi belirleyin!",
                        "newPassword": "Yeni Şifre",
                        "newPasswordRepeat": "Yeni Şifre (tekrar)",
                        "resetLinkNotValid": "Maalesef bağlantınız geçersiz veya süresi dolmuş. Lütfen şifrenizi sıfırlama talebini tekrar yapın.",
                        "or": "Veya",
                        "userNotFound": "Kullanıcı bulunamadı - lütfen",
                        "userAlreadyTaken": "Kullanıcı adı zaten alınmış - lütfen"
                    },
                    "validations": {
                        "usernameMinChar": "Kullanıcı adı en az 3 karakter uzunluğunda olmalıdır",
                        "chatbotNameMinChar": "Sohbet botu adı en az 6 karakter uzunluğunda olmalıdır",
                        "enterValidMail": "E-posta geçerli değil",
                        "passwordMinChar": "Şifre en az 8 karakter uzunluğunda olmalıdır",
                        "passwordContainDigit": "Şifre bir rakam içermelidir",
                        "passwordContainUppercase": "Şifre bir büyük harf içermelidir",
                        "passwordContainLowercase": "Şifre bir küçük harf içermelidir",
                        "passwordSame": "Şifreler eşleşmelidir"
                    },
                    "prices": {
                        "price": "Fiyat",
                        "join": "Katıl",
                        "susbcribe": "Abone Ol",
                        "active": "Aktif"
                    },
                    "profile": {
                        "updateProfileButton": "Profili Güncelle",
                        "noCustomer": "Müşteri oluşturulmadı",
                        "pricesButton": "Ürünlerin Özeti",
                        "createCustomer": "Profili güncelle"
                    },
                    "overview": {
                        "free": {
                            "description1": "Maksimum sayfa sayısı: 15",
                            "description2": "Maksimum görüntüleme: 10",
                            "description3": "Daha fazla bilgi..."
                        },
                        "basic": {
                            "description1": "Maksimum sayfa sayısı: 20",
                            "description2": "Maksimum görüntüleme: 50",
                            "description3": "Daha fazla bilgi..."
                        },
                        "premium": {
                            "description1": "Maksimum sayfa sayısı: 50",
                            "description2": "Maksimum görüntüleme: 100",
                            "description3": "Daha fazla bilgi..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Abonelik başarılı!",
                            "updated": "Profil güncellendi!",
                            "cancel": "Bir hata oluştu!",
                            "created": "Müşteri başarıyla oluşturuldu!"
                        },
                        "invoice": "Faturayı inceleyin: ",
                        "invoiceLink": "Fatura",
                        "button": "Menüye Dön!"
                    }
                }
            },

            // Ukrainisch
            "uk": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Виберіть файл або перетягніть його сюди"
                    },
                    "common": {
                        "somethingWentWrong": "Упс, щось пішло не так!",
                        "entriesPerPage": "Записів на сторінку",
                        "confirmPhrase": "Будь ласка, введіть <strong>{{confirmPhrase}}</strong> для підтвердження",
                        "confirm": "Підтвердити",
                        "cancel": "Скасувати",
                        "copiedToClipboard": "Скопійовано в буфер обміну!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Продукти",
                            "pricing": "Ціни",
                            "aboutUs": "Про Нас",
                            "docs": "Документація",
                            "contact": "Контакт"
                        },
                        "hero0": "Ваш індивідуальний чат-бот асистент. Наповніть його вашими інструкціями, веб-сайтами та FAQ, щоб автоматично, інтелектуально та точно відповідати на запити клієнтів. Зручний у використанні, інтерактивний та багатомовний SaaS для ефективної та персоналізованої підтримки клієнтів.",
                        "hero1": "Зареєструватися",
                        "section0": "Індивідуально навчений чат-бот асистент для персоналізованої взаємодії з клієнтами.",
                        "section1": "Зменшення навантаження на ваших фахівців за рахунок AI-підтримки більшості запитів підтримки.",
                        "section2": "Оптимізований користувацький досвід завдяки контекстному навчанню, інтерактивному тренуванню та постійному покращенню якості відповідей.",
                        "section3": "Глобальне охоплення та гнучкість завдяки підтримці багатьох мов, джерел та типів документів.",
                        "section4": "Гнучкий платіжний план доступний для всіх наших послуг.",
                        "tech0": "Технологія",
                        "tech1": "BotBucket використовує NLP техніку 'контекстуального питання-відповіді': надана вами інформація завантажується в єдине сховище, оптимізоване для обробки природної мови (NLP). Це сховище використовується під час чату для пошуку відповідних інформаційних блоків стосовно питання користувача. AI-модель потім поєднує ці блоки у змістовну відповідь.",
                        "tech2": "Дізнатися більше",
                        "aboutUs0": "Про Нас",
                        "aboutUs1": "Ласкаво просимо до BotBucket! Тут ви годуєте найновіші GPT-моделі вашими документами. Таким чином, ви створюєте свого індивідуального чат-асистента за секунди. З BotBucket ви об'єднуєте свій скарб знань з можливостями найсучаснішої обробки природної мови. Це розвантажує вашу підтримку клієнтів, створює прозорість і покращує ваші процеси.",
                        "aboutUs2": "Контакт",
                        "contact0": "Зв'яжіться з нами!",
                        "contact1": "Ім'я",
                        "contact2": "Електронна пошта",
                        "contact3": "Тема",
                        "contact4": "Повідомлення",
                        "contact5": "Надіслати"
                    },
                    "userMenu": {
                        "login": "Увійти",
                        "profile": "Профіль",
                        "settings": "Налаштування",
                        "logout": "Вийти"
                    },
                    "dashboard": {
                        "welcome": "Ласкаво просимо",
                        "myChatbots": "Мої чат-боти",
                        "newChatbot": "Новий чат-бот",
                        "instantChatbot": "Інстант чат-бот"
                    },
                    "chatbot": {
                        "myBot": "Мій бот - {{botName}}",
                        "settings": "Налаштування",
                        "fileTopic": "Тема (, розділено)",
                        "topic": "Тема",
                        "includedInTraining": "Цей файл було враховано під час останнього навчання і тепер відомий чат-боту.",
                        "notIncludedInTraining": "Цей файл ще не враховано. Навчіть зараз, щоб включити цей файл.",
                        "trainingStatus": {
                            "notTrained": "Ще не навчено",
                            "pending": "Навчання скоро почнеться",
                            "training": "Триває навчання",
                            "trained": "Навчання успішне",
                            "failed": "Навчання не вдалося",
                            "label": "Статус навчання"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Налаштуйте свого чат-бота!",
                            "publicAvailability": "Публічна доступність",
                            "publicAvailable": "Ваш чат-бот доступний для всіх. Ви можете поділитися ним за нижченаведеним посиланням.",
                            "notPublicAvailable": "Тільки ви можете бачити свого чат-бота. Використовуйте перемикач, щоб зробити його видимим для всіх.",
                            "publicLink": "Публічне посилання",
                            "deleteYourBot": "Видалити свого чат-бота",
                            allowUsers: 'Дозволити користувачів',
                            searchUser: 'Пошук користувача',
                            addUser: 'Додати користувача',
                            allowedUsers: 'Дозволені користувачі',
                            username: 'Ім\'я користувача',
                            email: 'Електронна пошта',
                            enterEmail: 'Введіть електронну пошту користувача, якому хочете дати доступ',
                            actions: 'Дії',
                            userAdded: 'Користувача додано',
                            userRemoved: 'Користувача видалено',
                            noUsersAllowed: 'Немає дозволених користувачів',
                            removeUser: 'Видалити користувача',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Додати документи до чат-бота",
                            "uploadingFiles": "Завантаження документів...",
                            "filesStillUploading": "Деякі документи ще не завантажено повністю, будь ласка, зачекайте",
                            "submit": "Підтвердити"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Навчіть свого чат-бота",
                            "embeddings": "Вбудовування",
                            "startTraining": "Почати навчання"
                        },
                        "botNotTrainedNoTrainingPossible": "Бот не навчено - наразі чат неможливий",
                        "chat": "Чат",
                        "botTraining": "Бот наразі на навчанні - будь ласка, зачекайте",
                        "trainNow": "Навчити зараз",
                        "uploadedDocuments": "Навчальні документи",
                        "document": "Документ",
                        "uploadedDate": "Дата завантаження",
                        "noFilesUploaded": "Ще не завантажено жодного документа",
                        "deleteBot": "Видалити чат-бота",
                        "confirmDelete": "Ви дійсно хочете видалити свого чат-бота {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Мої чат-боти",
                        "newChatbot": "Новий чат-бот",
                        "chat": "Чат",
                        "edit": "Редагувати",
                        "name": "Ім'я",
                        "numTrainingDocs": "# Навчальні документи",
                        "numQueries": "# Запити",
                        "trainingStatus": "Статус",
                        "noChatbotCreated": "Ще не створено жодного чат-бота.",
                        "delete": "Видалити"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Створити нового чат-бота",
                        "name": "Ім'я",
                        "description": "Опис",
                        "createBot": "Створити бота"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Для чого корисні 'Теми' в моєму чаті?"
                        },
                        "topic": "Тема",
                        "myChatWith": "Мій чат з {{botName}}",
                        "initMessage": "Привіт, я {{botName}}. Я знаю все про {{description}}. Що хочете дізнатися?",
                        "initMessageCIS": "Привіт, я {{botName}}. Я хочу дізнатися більше про ваші щоденні завдання, щоб їх зафіксувати. Це допоможе вам та вашим колегам краще орієнтуватися або замінити вас.",
                        "foundTheseSources": "Додаткова інформація тут:",
                        "userInput": "Ваш введений текст",
                        "dontSharePersonalData": "Історія чату зберігається протягом трьох місяців. Будь ласка, не діліться особистими даними."
                    },
                    "helperBot": {
                        "iCanHelp": "Я можу допомогти вам!",
                        "helpBotWelcome": "Привіт, я BotBucket. Як я можу допомогти?"
                    },
                    "login": {
                        "login": "Увійти",
                        "signUp": "Зареєструватися",
                        "username": "Ім'я користувача",
                        "password": "Пароль",
                        "passwordRepeat": "Пароль (повтор)",
                        "mail": "Електронна пошта",
                        "navToLogin": "Увійти",
                        "navToSignUp": "Зареєструватися",
                        "usernameOrEmail": "Ім'я користувача або електронна пошта",
                        "forgotPassword": "Забули пароль?",
                        "resetPassword": "Скинути пароль",
                        "reset": "Скинути",
                        "passwordResetted": "Пароль скинуто",
                        "thanksWeSentResetMail": "Дякуємо! Ми надіслали вам листа з подальшими інструкціями.",
                        "passwordSuccessfullyResettedLoginAgain": "Ваш пароль успішно скинуто. Тепер ви можете увійти з новим паролем.",
                        "helloPleaseChooseNewPassword": "Привіт, {{username}}! Будь ласка, встановіть новий пароль!",
                        "newPassword": "Новий пароль",
                        "newPasswordRepeat": "Новий пароль (повтор)",
                        "resetLinkNotValid": "На жаль, ваше посилання недійсне або його термін дії минув. Будь ласка, повторіть запит на скидання пароля.",
                        "or": "Або",
                        "userNotFound": "Користувача не знайдено - будь ласка",
                        "userAlreadyTaken": "Користувач вже зайнятий - будь ласка"
                    },
                    "validations": {
                        "usernameMinChar": "Ім'я користувача повинно бути не менше 3 символів",
                        "chatbotNameMinChar": "Ім'я чат-бота повинно бути не менше 6 символів",
                        "enterValidMail": "Електронна пошта недійсна",
                        "passwordMinChar": "Пароль повинен бути не менше 8 символів",
                        "passwordContainDigit": "Пароль повинен містити цифру",
                        "passwordContainUppercase": "Пароль повинен містити велику літеру",
                        "passwordContainLowercase": "Пароль повинен містити малу літеру",
                        "passwordSame": "Паролі повинні збігатися"
                    },
                    "prices": {
                        "price": "Ціна",
                        "join": "Приєднатися",
                        "susbcribe": "Підписатися",
                        "active": "Активний"
                    },
                    "profile": {
                        "updateProfileButton": "Оновити профіль",
                        "noCustomer": "Клієнт не створений",
                        "pricesButton": "Огляд продуктів",
                        "createCustomer": "Оновити профіль"
                    },
                    "overview": {
                        "free": {
                            "description1": "Максимальна кількість сторінок: 15",
                            "description2": "Максимальна кількість переглядів: 10",
                            "description3": "Додаткова інформація..."
                        },
                        "basic": {
                            "description1": "Максимальна кількість сторінок: 20",
                            "description2": "Максимальна кількість переглядів: 50",
                            "description3": "Додаткова інформація..."
                        },
                        "premium": {
                            "description1": "Максимальна кількість сторінок: 50",
                            "description2": "Максимальна кількість переглядів: 100",
                            "description3": "Додаткова інформація..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Підписка успішна!",
                            "updated": "Профіль оновлено!",
                            "cancel": "Сталася помилка!",
                            "created": "Клієнт успішно створений!"
                        },
                        "invoice": "Переглянути рахунок: ",
                        "invoiceLink": "Рахунок",
                        "button": "Назад до меню!"
                    }
                }
            },

            // Arabisch
            "ar": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "اختر ملفًا أو اسحبه هنا"
                    },
                    "common": {
                        "somethingWentWrong": "عذرًا، حدث خطأ ما!",
                        "entriesPerPage": "إدخالات لكل صفحة",
                        "confirmPhrase": "يرجى إدخال <strong>{{confirmPhrase}}</strong> للتأكيد",
                        "confirm": "تأكيد",
                        "cancel": "إلغاء",
                        "copiedToClipboard": "تم النسخ إلى الحافظة!"
                    },
                    "homepage": {
                        "header": {
                            "products": "المنتجات",
                            "pricing": "الأسعار",
                            "aboutUs": "معلومات عنا",
                            "docs": "الوثائق",
                            "contact": "اتصال"
                        },
                        "hero0": "مساعد الدردشة الشخصي الخاص بك. قم بتزويده بدليل الاستخدام، المواقع الإلكترونية والأسئلة الشائعة للإجابة على استفسارات العملاء تلقائيًا وبذكاء ودقة. تطبيق سهل الاستخدام، تفاعلي وبلغات متعددة لتقديم دعم العملاء بكفاءة وشخصية.",
                        "hero1": "تسجيل",
                        "section0": "مساعد دردشة مُدرب بشكل فردي للتفاعل مع العملاء بشكل مخصص.",
                        "section1": "تخفيف عبء خبرائك من خلال استجابة الذكاء الاصطناعي لمعظم استفسارات الدعم.",
                        "section2": "تجربة مستخدم محسّنة من خلال التعلم السياقي، التدريب التفاعلي والتحسين المستمر لجودة الإجابات.",
                        "section3": "وصول عالمي ومرونة من خلال دعم العديد من اللغات والمصادر وأنواع الوثائق.",
                        "section4": "خطة دفع مرنة متاحة لجميع خدماتنا.",
                        "tech0": "التكنولوجيا",
                        "tech1": "يستخدم BotBucket تقنية 'الإجابة على الأسئلة السياقية' في معالجة اللغة الطبيعية (NLP): يتم تحميل المعلومات التي توفرها في ذاكرة موحدة، والتي تم تحسينها لمعالجة اللغة الطبيعية. يتم استخدام هذه الذاكرة أثناء الدردشة للعثور على كتل المعلومات ذات الصلة بالسؤال المطروح. يقوم نموذج الذكاء الاصطناعي بعد ذلك بدمج هذه الكتل لتكوين إجابة منطقية.",
                        "tech2": "المزيد",
                        "aboutUs0": "معلومات عنا",
                        "aboutUs1": "مرحبًا بكم في BotBucket! هنا تقومون بتزويد أحدث نماذج GPT بوثائقكم. وبهذه الطريقة، تقومون بإنشاء مساعد الدردشة الشخصي الخاص بكم في ثوانٍ. مع BotBucket، تقومون بربط كنز معرفتكم بإمكانيات معالجة اللغة الطبيعية الحديثة. هذا يساعد على تخفيف عبء دعم العملاء، خلق الشفافية وتحسين عملياتكم.",
                        "aboutUs2": "اتصال",
                        "contact0": "اتصل بنا!",
                        "contact1": "الاسم",
                        "contact2": "البريد الإلكتروني",
                        "contact3": "الموضوع",
                        "contact4": "الرسالة",
                        "contact5": "إرسال"
                    },
                    "userMenu": {
                        "login": "تسجيل الدخول",
                        "profile": "الملف الشخصي",
                        "settings": "الإعدادات",
                        "logout": "تسجيل الخروج"
                    },
                    "dashboard": {
                        "welcome": "مرحبًا",
                        "myChatbots": "روبوتات الدردشة الخاصة بي",
                        "newChatbot": "روبوت دردشة جديد",
                        "instantChatbot": "روبوت دردشة فوري"
                    },
                    "chatbot": {
                        "myBot": "الروبوت الخاص بي - {{botName}}",
                        "settings": "الإعدادات",
                        "fileTopic": "الموضوع (، مفصول)",
                        "topic": "الموضوع",
                        "includedInTraining": "تم أخذ هذا الملف في الاعتبار في التدريب الأخير وهو معروف الآن بواسطة روبوت الدردشة.",
                        "notIncludedInTraining": "لم يتم أخذ هذا الملف في الاعتبار بعد. قم بالتدريب الآن لتضمين هذا الملف.",
                        "trainingStatus": {
                            "notTrained": "لم يتم تدريبه بعد",
                            "pending": "سيبدأ التدريب قريبًا",
                            "training": "التدريب جاري",
                            "trained": "تم التدريب بنجاح",
                            "failed": "فشل التدريب",
                            "label": "حالة التدريب"
                        },
                        "settingsModal": {
                            "customizeYourBot": "قم بتخصيص روبوت الدردشة الخاص بك!",
                            "publicAvailability": "إتاحة عامة",
                            "publicAvailable": "روبوت الدردشة الخاص بك مرئي للجميع. يمكنك مشاركته باستخدام الرابط أدناه.",
                            "notPublicAvailable": "فقط يمكنك رؤية روبوت الدردشة الخاص بك. استخدم التبديل لجعله مرئيًا للجميع.",
                            "publicLink": "الرابط العام",
                            "deleteYourBot": "حذف روبوت الدردشة الخاص بك",
                            allowUsers: 'السماح للمستخدمين',
                            searchUser: 'البحث عن مستخدم',
                            addUser: 'إضافة مستخدم',
                            allowedUsers: 'المستخدمون المسموح بهم',
                            username: 'اسم المستخدم',
                            email: 'البريد الإلكتروني',
                            enterEmail: 'أدخل بريدًا إلكترونيًا للمستخدم الذي ترغب في منحه الوصول',
                            actions: 'الإجراءات',
                            userAdded: 'تمت إضافة المستخدم',
                            userRemoved: 'تمت إزالة المستخدم',
                            noUsersAllowed: 'لا يوجد مستخدمون مسموح بهم',
                            removeUser: 'إزالة المستخدم',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "إضافة مستندات إلى روبوت الدردشة",
                            "uploadingFiles": "جاري تحميل المستندات...",
                            "filesStillUploading": "بعض المستندات لم يتم تحميلها بالكامل بعد، يرجى الانتظار",
                            "submit": "تأكيد"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "قم بتدريب روبوت الدردشة الخاص بك",
                            "embeddings": "التضمينات",
                            "startTraining": "بدء التدريب"
                        },
                        "botNotTrainedNoTrainingPossible": "لم يتم تدريب الروبوت - لا يمكن إجراء الدردشة حاليًا",
                        "chat": "الدردشة",
                        "botTraining": "الروبوت في التدريب حاليًا - يرجى الانتظار",
                        "trainNow": "قم بالتدريب الآن",
                        "uploadedDocuments": "مستندات التدريب",
                        "document": "المستند",
                        "uploadedDate": "تاريخ التحميل",
                        "noFilesUploaded": "لم يتم تحميل أي مستندات بعد",
                        "deleteBot": "حذف روبوت الدردشة",
                        "confirmDelete": "هل تريد حقًا حذف روبوت الدردشة الخاص بك {{botName}}؟"
                    },
                    "chatbots": {
                        "myChatbots": "روبوتات الدردشة الخاصة بي",
                        "newChatbot": "روبوت دردشة جديد",
                        "chat": "الدردشة",
                        "edit": "تحرير",
                        "name": "الاسم",
                        "numTrainingDocs": "# مستندات التدريب",
                        "numQueries": "# الاستفسارات",
                        "trainingStatus": "الحالة",
                        "noChatbotCreated": "لم يتم إنشاء أي روبوتات دردشة بعد.",
                        "delete": "حذف"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "إنشاء روبوت دردشة جديد",
                        "name": "الاسم",
                        "description": "الوصف",
                        "createBot": "إنشاء الروبوت"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "ما فائدة 'الموضوعات' في محادثتي؟"
                        },
                        "topic": "الموضوع",
                        "myChatWith": "دردشتي مع {{botName}}",
                        "initMessage": "مرحبًا، أنا {{botName}}. أعرف كل شيء عن {{description}}. ماذا تريد أن تعرف؟",
                        "initMessageCIS": "مرحبًا، أنا {{botName}}. أود معرفة المزيد عن مهامك اليومية لتوثيقها. سيساعد هذا زملائك على العمل بشكل أفضل أو تغطيتك.",
                        "foundTheseSources": "مزيد من المعلومات هنا:",
                        "userInput": "إدخال المستخدم",
                        "dontSharePersonalData": "سيتم حفظ سجل الدردشة لمدة ثلاثة أشهر. من فضلك لا تشارك أي بيانات شخصية."
                    },
                    "helperBot": {
                        "iCanHelp": "يمكنني مساعدتك!",
                        "helpBotWelcome": "مرحبًا، أنا BotBucket. كيف يمكنني مساعدتك؟"
                    },
                    "login": {
                        "login": "تسجيل الدخول",
                        "signUp": "التسجيل",
                        "username": "اسم المستخدم",
                        "password": "كلمة المرور",
                        "passwordRepeat": "كلمة المرور (تكرار)",
                        "mail": "البريد الإلكتروني",
                        "navToLogin": "تسجيل الدخول",
                        "navToSignUp": "التسجيل",
                        "usernameOrEmail": "اسم المستخدم أو البريد الإلكتروني",
                        "forgotPassword": "هل نسيت كلمة المرور؟",
                        "resetPassword": "إعادة تعيين كلمة المرور",
                        "reset": "إعادة تعيين",
                        "passwordResetted": "تم إعادة تعيين كلمة المرور",
                        "thanksWeSentResetMail": "شكرًا! لقد أرسلنا لك بريدًا إلكترونيًا يحتوي على تعليمات إضافية.",
                        "passwordSuccessfullyResettedLoginAgain": "تم إعادة تعيين كلمة مرورك بنجاح. يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة.",
                        "helloPleaseChooseNewPassword": "مرحبًا، {{username}}! يرجى تعيين كلمة مرور جديدة!",
                        "newPassword": "كلمة المرور الجديدة",
                        "newPasswordRepeat": "كلمة المرور الجديدة (تكرار)",
                        "resetLinkNotValid": "عذرًا، الرابط غير صالح أو انتهت صلاحيته. يرجى طلب إعادة تعيين كلمة المرور مرة أخرى.",
                        "or": "أو",
                        "userNotFound": "المستخدم غير موجود - من فضلك",
                        "userAlreadyTaken": "اسم المستخدم محجوز بالفعل - من فضلك"
                    },
                    "validations": {
                        "usernameMinChar": "يجب أن يكون اسم المستخدم مكونًا من 3 أحرف على الأقل",
                        "chatbotNameMinChar": "يجب أن يكون اسم روبوت الدردشة مكونًا من 6 أحرف على الأقل",
                        "enterValidMail": "البريد الإلكتروني غير صالح",
                        "passwordMinChar": "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
                        "passwordContainDigit": "يجب أن تحتوي كلمة المرور على رقم",
                        "passwordContainUppercase": "يجب أن تحتوي كلمة المرور على حرف كبير",
                        "passwordContainLowercase": "يجب أن تحتوي كلمة المرور على حرف صغير",
                        "passwordSame": "يجب أن تتطابق كلمات المرور"
                    },
                    "prices": {
                        "price": "السعر",
                        "join": "انضم",
                        "susbcribe": "الاشتراك",
                        "active": "نشط"
                    },
                    "profile": {
                        "updateProfileButton": "تحديث الملف الشخصي",
                        "noCustomer": "لم يتم إنشاء عميل",
                        "pricesButton": "ملخص المنتجات",
                        "createCustomer": "تحديث الملف الشخصي"
                    },
                    "overview": {
                        "free": {
                            "description1": "الحد الأقصى لعدد الصفحات: 15",
                            "description2": "الحد الأقصى للمشاهدات: 10",
                            "description3": "مزيد من المعلومات..."
                        },
                        "basic": {
                            "description1": "الحد الأقصى لعدد الصفحات: 20",
                            "description2": "الحد الأقصى للمشاهدات: 50",
                            "description3": "مزيد من المعلومات..."
                        },
                        "premium": {
                            "description1": "الحد الأقصى لعدد الصفحات: 50",
                            "description2": "الحد الأقصى للمشاهدات: 100",
                            "description3": "مزيد من المعلومات..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "تم الاشتراك بنجاح!",
                            "updated": "تم تحديث الملف الشخصي!",
                            "cancel": "حدث خطأ!",
                            "created": "تم إنشاء العميل بنجاح!"
                        },
                        "invoice": "عرض الفاتورة: ",
                        "invoiceLink": "الفاتورة",
                        "button": "العودة إلى القائمة!"
                    }
                }
            },

            // Kroatisch
            "hr": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Odaberite datoteku ili je povucite ovdje"
                    },
                    "common": {
                        "somethingWentWrong": "Ups, nešto je pošlo po zlu!",
                        "entriesPerPage": "Unosa po stranici",
                        "confirmPhrase": "Molimo unesite <strong>{{confirmPhrase}}</strong> za potvrdu",
                        "confirm": "Potvrdi",
                        "cancel": "Odustani",
                        "copiedToClipboard": "Kopirano u međuspremnik!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Proizvodi",
                            "pricing": "Cijene",
                            "aboutUs": "O Nama",
                            "docs": "Dokumentacija",
                            "contact": "Kontakt"
                        },
                        "hero0": "Vaš prilagođeni chatbot asistent. Napunite ga svojim priručnicima, web stranicama i FAQ-ovima kako bi automatski, inteligentno i precizno odgovarao na upite korisnika. Korisnički prijazan, interaktivan i višejezičan SaaS za učinkovit i personaliziran korisnički support.",
                        "hero1": "Registriraj se",
                        "section0": "Individualno trenirani chatbot asistent za prilagođenu interakciju s korisnicima.",
                        "section1": "Oslobađanje vaših stručnjaka preuzimanjem većine upita za podršku pomoću AI.",
                        "section2": "Optimizirano korisničko iskustvo kroz kontekstualno učenje, interaktivnu obuku i kontinuirano poboljšanje kvalitete odgovora.",
                        "section3": "Globalni doseg i fleksibilnost kroz podršku mnogih jezika, izvora i vrsta dokumenata.",
                        "section4": "Fleksibilan plan plaćanja dostupan je za sve naše usluge.",
                        "tech0": "Tehnologija",
                        "tech1": "BotBucket koristi NLP tehniku 'kontekstualnog odgovaranja na pitanja': informacije koje pružate učitavaju se u jedinstvenu memoriju optimiziranu za obradu prirodnog jezika (NLP). Ova memorija se koristi tijekom razgovora za pronalaženje relevantnih informacija vezanih uz pitanje korisnika. AI model zatim kombinira ove blokove u smislen odgovor.",
                        "tech2": "Saznajte više",
                        "aboutUs0": "O Nama",
                        "aboutUs1": "Dobrodošli u BotBucket! Ovdje hranite najnovije GPT modele vašim dokumentima. Tako stvarate svog individualnog chat asistenta u sekundi. S BotBucketom povezujete svoje znanje s mogućnostima moderne obrade prirodnog jezika. Tako BotBucket rasterećuje vašu korisničku podršku, stvara transparentnost i poboljšava vaše procese.",
                        "aboutUs2": "Kontakt",
                        "contact0": "Kontaktirajte nas!",
                        "contact1": "Ime",
                        "contact2": "Email",
                        "contact3": "Predmet",
                        "contact4": "Poruka",
                        "contact5": "Pošalji"
                    },
                    "userMenu": {
                        "login": "Prijava",
                        "profile": "Profil",
                        "settings": "Postavke",
                        "logout": "Odjava"
                    },
                    "dashboard": {
                        "welcome": "Dobrodošli",
                        "myChatbots": "Moji Chatboti",
                        "newChatbot": "Novi Chatbot",
                        "instantChatbot": "Instant Chatbot"
                    },
                    "chatbot": {
                        "myBot": "Moj Bot - {{botName}}",
                        "settings": "Postavke",
                        "fileTopic": "Tema (, odvojeno)",
                        "topic": "Tema",
                        "includedInTraining": "Ova datoteka je uzeta u obzir u zadnjem treningu i sada je poznata chatbotu.",
                        "notIncludedInTraining": "Ova datoteka još nije uzeta u obzir. Trenutno trenirajte da uključite ovu datoteku.",
                        "trainingStatus": {
                            "notTrained": "Još nije trenirano",
                            "pending": "Trening uskoro počinje",
                            "training": "Trening u tijeku",
                            "trained": "Trening uspješan",
                            "failed": "Trening nije uspio",
                            "label": "Status treninga"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Prilagodite svoj chatbot!",
                            "publicAvailability": "Javna dostupnost",
                            "publicAvailable": "Vaš chatbot je vidljiv svima. Možete ga podijeliti pomoću donje poveznice.",
                            "notPublicAvailable": "Samo vi možete vidjeti svog chatbota. Koristite prekidač da ga učinite vidljivim svima.",
                            "publicLink": "Javna poveznica",
                            "deleteYourBot": "Izbrišite svoj chatbot",
                            allowUsers: 'Dozvoli korisnike',
                            searchUser: 'Pretraži korisnika',
                            addUser: 'Dodaj korisnika',
                            allowedUsers: 'Dozvoljeni korisnici',
                            username: 'Korisničko ime',
                            email: 'E-pošta',
                            enterEmail: 'Unesite e-poštu korisnika kojem želite dati pristup',
                            actions: 'Radnje',
                            userAdded: 'Korisnik dodan',
                            userRemoved: 'Korisnik uklonjen',
                            noUsersAllowed: 'Nema dozvoljenih korisnika',
                            removeUser: 'Ukloni korisnika',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Dodajte dokumente u chatbot",
                            "uploadingFiles": "Učitavanje dokumenata...",
                            "filesStillUploading": "Neki dokumenti još nisu potpuno učitani, molimo pričekajte",
                            "submit": "Potvrdi"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Trenirajte svog chatbota",
                            "embeddings": "Embeddings",
                            "startTraining": "Počnite s treningom"
                        },
                        "botNotTrainedNoTrainingPossible": "Bot nije treniran - trenutno nije moguća chat opcija",
                        "chat": "Chat",
                        "botTraining": "Bot trenutno na treningu - molimo pričekajte",
                        "trainNow": "Trenirajte sada",
                        "uploadedDocuments": "Trening dokumenti",
                        "document": "Dokument",
                        "uploadedDate": "Datum učitavanja",
                        "noFilesUploaded": "Još nema učitanih dokumenata",
                        "deleteBot": "Izbrišite Chatbot",
                        "confirmDelete": "Želite li stvarno izbrisati svog chatbota {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Moji Chatboti",
                        "newChatbot": "Novi Chatbot",
                        "chat": "Chat",
                        "edit": "Uredi",
                        "name": "Ime",
                        "numTrainingDocs": "# Trening dokumenti",
                        "numQueries": "# Upita",
                        "trainingStatus": "Status",
                        "noChatbotCreated": "Još nisu kreirani chatbotovi.",
                        "delete": "Izbriši"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Kreirajte novog chatbota",
                        "name": "Ime",
                        "description": "Opis",
                        "createBot": "Kreirajte bota"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Zašto su 'Teme' korisne u mom chatu?"
                        },
                        "topic": "Tema",
                        "myChatWith": "Moj chat s {{botName}}",
                        "initMessage": "Bok, ja sam {{botName}}. Znam sve o {{description}}. Što želite znati?",
                        "initMessageCIS": "Bok, ja sam {{botName}}. Želim saznati više o vašim dnevnim zadacima kako bih ih zabilježio. Ovo će pomoći vama i vašim kolegama da se bolje uhodate ili zamijenite.",
                        "foundTheseSources": "Dodatne informacije ovdje:",
                        "userInput": "Vaš unos",
                        "dontSharePersonalData": "Povijest chata se čuva tri mjeseca. Molimo ne dijelite osobne podatke."
                    },
                    "helperBot": {
                        "iCanHelp": "Mogu vam pomoći!",
                        "helpBotWelcome": "Bok, ja sam BotBucket. Kako mogu pomoći?"
                    },
                    "login": {
                        "login": "Prijava",
                        "signUp": "Registracija",
                        "username": "Korisničko ime",
                        "password": "Lozinka",
                        "passwordRepeat": "Lozinka (ponovno)",
                        "mail": "Email",
                        "navToLogin": "Prijava",
                        "navToSignUp": "Registracija",
                        "usernameOrEmail": "Korisničko ime ili Email",
                        "forgotPassword": "Zaboravljena lozinka?",
                        "resetPassword": "Resetiraj lozinku",
                        "reset": "Resetiraj",
                        "passwordResetted": "Lozinka resetirana",
                        "thanksWeSentResetMail": "Hvala! Poslali smo vam email s daljnjim uputama.",
                        "passwordSuccessfullyResettedLoginAgain": "Vaša lozinka je uspješno resetirana. Sada se možete prijaviti s novom lozinkom.",
                        "helloPleaseChooseNewPassword": "Hej, {{username}}! Molimo postavite novu lozinku!",
                        "newPassword": "Nova lozinka",
                        "newPasswordRepeat": "Nova lozinka (ponovno)",
                        "resetLinkNotValid": "Nažalost, vaša poveznica nije valjana ili je istekla. Molimo zatražite ponovno resetiranje lozinke.",
                        "or": "Ili",
                        "userNotFound": "Korisnik nije pronađen - molimo",
                        "userAlreadyTaken": "Korisničko ime je već zauzeto - molimo"
                    },
                    "validations": {
                        "usernameMinChar": "Korisničko ime mora imati najmanje 3 znaka",
                        "chatbotNameMinChar": "Ime chatbota mora imati najmanje 6 znakova",
                        "enterValidMail": "Email nije valjan",
                        "passwordMinChar": "Lozinka mora imati najmanje 8 znakova",
                        "passwordContainDigit": "Lozinka mora sadržavati broj",
                        "passwordContainUppercase": "Lozinka mora sadržavati veliko slovo",
                        "passwordContainLowercase": "Lozinka mora sadržavati malo slovo",
                        "passwordSame": "Lozinke se moraju podudarati"
                    },
                    "prices": {
                        "price": "Cijena",
                        "join": "Pridruži se",
                        "susbcribe": "Pretplati se",
                        "active": "Aktivno"
                    },
                    "profile": {
                        "updateProfileButton": "Ažuriraj profil",
                        "noCustomer": "Nema kreiranog korisnika",
                        "pricesButton": "Pregled proizvoda",
                        "createCustomer": "Ažuriraj profil"
                    },
                    "overview": {
                        "free": {
                            "description1": "Maksimalan broj stranica: 15",
                            "description2": "Maksimalan broj pregleda: 10",
                            "description3": "Dodatne informacije..."
                        },
                        "basic": {
                            "description1": "Maksimalan broj stranica: 20",
                            "description2": "Maksimalan broj pregleda: 50",
                            "description3": "Dodatne informacije..."
                        },
                        "premium": {
                            "description1": "Maksimalan broj stranica: 50",
                            "description2": "Maksimalan broj pregleda: 100",
                            "description3": "Dodatne informacije..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Pretplata uspješna!",
                            "updated": "Profil ažuriran!",
                            "cancel": "Dogodila se pogreška!",
                            "created": "Korisnik uspješno kreiran!"
                        },
                        "invoice": "Pogledajte račun: ",
                        "invoiceLink": "Račun",
                        "button": "Povratak na izbornik!"
                    }
                }
            },

            // Rumänisch
            "ro": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Alegeți un fișier sau trageți-l aici"
                    },
                    "common": {
                        "somethingWentWrong": "Ups, ceva a mers prost!",
                        "entriesPerPage": "Intrări pe pagină",
                        "confirmPhrase": "Vă rugăm să introduceți <strong>{{confirmPhrase}}</strong> pentru confirmare",
                        "confirm": "Confirmați",
                        "cancel": "Anulați",
                        "copiedToClipboard": "Copiat în clipboard!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Produse",
                            "pricing": "Prețuri",
                            "aboutUs": "Despre Noi",
                            "docs": "Documentație",
                            "contact": "Contact"
                        },
                        "hero0": "Asistentul chatbot personalizat al dumneavoastră. Hrăniți-l cu manualele, site-urile web și întrebările frecvente pentru a răspunde automat, inteligent și precis la întrebările clienților. O aplicație SaaS ușor de utilizat, interactivă și multilingvă pentru suportul clienților eficient și personalizat.",
                        "hero1": "Înregistrați-vă",
                        "section0": "Asistent chatbot antrenat individual pentru o interacțiune personalizată cu clienții.",
                        "section1": "Eliberarea experților dumneavoastră prin preluarea majorității întrebărilor de suport de către AI.",
                        "section2": "Experiență optimizată a utilizatorului prin învățare contextuală, antrenament interactiv și îmbunătățirea continuă a calității răspunsurilor.",
                        "section3": "Acoperire globală și flexibilitate prin suportul pentru multe limbi, surse și tipuri de documente.",
                        "section4": "Un plan de plată flexibil este disponibil pentru toate serviciile noastre.",
                        "tech0": "Tehnologie",
                        "tech1": "BotBucket utilizează tehnica NLP de 'răspuns la întrebări contextuale': informațiile furnizate de dumneavoastră sunt încărcate într-o memorie unificată, optimizată pentru procesarea limbajului natural (NLP). Această memorie este utilizată în timpul chat-ului pentru a găsi blocuri de informații relevante în raport cu întrebarea utilizatorului. Un model AI combină apoi aceste blocuri într-un răspuns logic.",
                        "tech2": "Aflați mai multe",
                        "aboutUs0": "Despre Noi",
                        "aboutUs1": "Bun venit la BotBucket! Aici hrăniți cele mai noi modele GPT cu documentele dumneavoastră. Astfel, creați asistentul de chat personalizat în câteva secunde. Cu BotBucket, vă conectați tezaurul de cunoștințe cu posibilitățile procesării limbajului natural moderne. Astfel, BotBucket eliberează suportul dumneavoastră pentru clienți, creează transparență și îmbunătățește procesele dumneavoastră.",
                        "aboutUs2": "Contact",
                        "contact0": "Contactați-ne!",
                        "contact1": "Nume",
                        "contact2": "Email",
                        "contact3": "Subiect",
                        "contact4": "Mesaj",
                        "contact5": "Trimiteți"
                    },
                    "userMenu": {
                        "login": "Conectare",
                        "profile": "Profil",
                        "settings": "Setări",
                        "logout": "Deconectare"
                    },
                    "dashboard": {
                        "welcome": "Bun venit",
                        "myChatbots": "Chatbot-urile mele",
                        "newChatbot": "Chatbot nou",
                        "instantChatbot": "Chatbot instant"
                    },
                    "chatbot": {
                        "myBot": "Bot-ul meu - {{botName}}",
                        "settings": "Setări",
                        "fileTopic": "Subiect (, separat)",
                        "topic": "Subiect",
                        "includedInTraining": "Acest fișier a fost inclus în ultimul antrenament și acum este cunoscut de chatbot.",
                        "notIncludedInTraining": "Acest fișier nu a fost încă inclus. Antrenați acum pentru a include acest fișier.",
                        "trainingStatus": {
                            "notTrained": "Nu a fost încă antrenat",
                            "pending": "Antrenamentul va începe în curând",
                            "training": "Antrenament în curs",
                            "trained": "Antrenamentul a fost reușit",
                            "failed": "Antrenamentul a eșuat",
                            "label": "Statusul antrenamentului"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Personalizați chatbot-ul dumneavoastră!",
                            "publicAvailability": "Disponibilitate publică",
                            "publicAvailable": "Chatbot-ul dumneavoastră este vizibil pentru toată lumea. Îl puteți partaja folosind linkul de mai jos.",
                            "notPublicAvailable": "Numai dumneavoastră puteți vedea chatbot-ul dumneavoastră. Utilizați comutatorul pentru a-l face vizibil pentru toată lumea.",
                            "publicLink": "Link public",
                            "deleteYourBot": "Ștergeți chatbot-ul dumneavoastră",
                            allowUsers: 'Permiteți utilizatorilor',
                            searchUser: 'Căutați utilizator',
                            addUser: 'Adăugați utilizator',
                            allowedUsers: 'Utilizatori permisi',
                            username: 'Nume de utilizator',
                            email: 'Email',
                            enterEmail: 'Introduceți adresa de email a utilizatorului căruia doriți să-i acordați acces',
                            actions: 'Acțiuni',
                            userAdded: 'Utilizator adăugat',
                            userRemoved: 'Utilizator eliminat',
                            noUsersAllowed: 'Nu sunt permisi utilizatori',
                            removeUser: 'Eliminați utilizatorul',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Adăugați documente la chatbot",
                            "uploadingFiles": "Încărcare documente...",
                            "filesStillUploading": "Unele documente nu sunt încă complet încărcate, vă rugăm să așteptați",
                            "submit": "Confirmați"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Antrenați chatbot-ul dumneavoastră",
                            "embeddings": "Încorporări",
                            "startTraining": "Începeți antrenamentul"
                        },
                        "botNotTrainedNoTrainingPossible": "Bot-ul nu este antrenat - chat-ul nu este posibil în prezent",
                        "chat": "Chat",
                        "botTraining": "Bot-ul este în prezent în antrenament - vă rugăm să așteptați",
                        "trainNow": "Antrenați acum",
                        "uploadedDocuments": "Documente de antrenament",
                        "document": "Document",
                        "uploadedDate": "Data încărcării",
                        "noFilesUploaded": "Nu au fost încărcate documente",
                        "deleteBot": "Ștergeți chatbot-ul",
                        "confirmDelete": "Sigur doriți să ștergeți chatbot-ul dumneavoastră {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Chatbot-urile mele",
                        "newChatbot": "Chatbot nou",
                        "chat": "Chat",
                        "edit": "Editează",
                        "name": "Nume",
                        "numTrainingDocs": "# Documente de antrenament",
                        "numQueries": "# Întrebări",
                        "trainingStatus": "Status",
                        "noChatbotCreated": "Nu au fost create încă chatbot-uri.",
                        "delete": "Șterge"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Creați un chatbot nou",
                        "name": "Nume",
                        "description": "Descriere",
                        "createBot": "Creați bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "La ce sunt utile 'Subiectele' în chat-ul meu?"
                        },
                        "topic": "Subiect",
                        "myChatWith": "Chat-ul meu cu {{botName}}",
                        "initMessage": "Bună, eu sunt {{botName}}. Știu totul despre {{description}}. Ce vrei să știi?",
                        "initMessageCIS": "Bună, eu sunt {{botName}}. Vreau să aflu mai multe despre sarcinile tale zilnice pentru a le documenta. Acest lucru este destinat să te ajute pe tine și pe colegii tăi să te orientezi mai bine sau să te înlocuiești.",
                        "foundTheseSources": "Mai multe informații aici:",
                        "userInput": "Intrarea ta",
                        "dontSharePersonalData": "Istoricul chat-ului va fi păstrat timp de trei luni. Vă rugăm să nu împărtășiți date personale."
                    },
                    "helperBot": {
                        "iCanHelp": "Pot să te ajut!",
                        "helpBotWelcome": "Bună, eu sunt BotBucket. Cum pot ajuta?"
                    },
                    "login": {
                        "login": "Conectare",
                        "signUp": "Înregistrare",
                        "username": "Nume de utilizator",
                        "password": "Parolă",
                        "passwordRepeat": "Parolă (repetare)",
                        "mail": "Email",
                        "navToLogin": "Conectare",
                        "navToSignUp": "Înregistrare",
                        "usernameOrEmail": "Nume de utilizator sau Email",
                        "forgotPassword": "Ați uitat parola?",
                        "resetPassword": "Resetați parola",
                        "reset": "Resetați",
                        "passwordResetted": "Parolă resetată",
                        "thanksWeSentResetMail": "Vă mulțumim! V-am trimis un email cu instrucțiuni suplimentare.",
                        "passwordSuccessfullyResettedLoginAgain": "Parola a fost resetată cu succes. Vă puteți conecta acum cu noua parolă.",
                        "helloPleaseChooseNewPassword": "Bună, {{username}}! Vă rugăm să setați o nouă parolă!",
                        "newPassword": "Parolă nouă",
                        "newPasswordRepeat": "Parolă nouă (repetare)",
                        "resetLinkNotValid": "Din păcate, linkul dumneavoastră nu este valid sau a expirat. Vă rugăm să solicitați din nou resetarea parolei.",
                        "or": "Sau",
                        "userNotFound": "Utilizatorul nu a fost găsit - vă rugăm",
                        "userAlreadyTaken": "Utilizatorul este deja luat - vă rugăm"
                    },
                    "validations": {
                        "usernameMinChar": "Numele de utilizator trebuie să aibă cel puțin 3 caractere",
                        "chatbotNameMinChar": "Numele chatbot-ului trebuie să aibă cel puțin 6 caractere",
                        "enterValidMail": "Email-ul nu este valid",
                        "passwordMinChar": "Parola trebuie să aibă cel puțin 8 caractere",
                        "passwordContainDigit": "Parola trebuie să conțină un număr",
                        "passwordContainUppercase": "Parola trebuie să conțină o literă mare",
                        "passwordContainLowercase": "Parola trebuie să conțină o literă mică",
                        "passwordSame": "Parolele trebuie să se potrivească"
                    },
                    "prices": {
                        "price": "Preț",
                        "join": "Alăturați-vă",
                        "susbcribe": "Abonare",
                        "active": "Activ"
                    },
                    "profile": {
                        "updateProfileButton": "Actualizați profilul",
                        "noCustomer": "Niciun client creat",
                        "pricesButton": "Prezentare produse",
                        "createCustomer": "Actualizați profilul"
                    },
                    "overview": {
                        "free": {
                            "description1": "Numărul maxim de pagini: 15",
                            "description2": "Vizualizări maxime: 10",
                            "description3": "Mai multe informații..."
                        },
                        "basic": {
                            "description1": "Numărul maxim de pagini: 20",
                            "description2": "Vizualizări maxime: 50",
                            "description3": "Mai multe informații..."
                        },
                        "premium": {
                            "description1": "Numărul maxim de pagini: 50",
                            "description2": "Vizualizări maxime: 100",
                            "description3": "Mai multe informații..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Abonament reușit!",
                            "updated": "Profil actualizat!",
                            "cancel": "A apărut o eroare!",
                            "created": "Client creat cu succes!"
                        },
                        "invoice": "Vizualizați factura: ",
                        "invoiceLink": "Factură",
                        "button": "Înapoi la meniul principal!"
                    }
                }
            },

            // Albanisch
            "sq": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Zgjidhni një skedar ose tërhiqeni këtu"
                    },
                    "common": {
                        "somethingWentWrong": "Ups, diçka shkoi keq!",
                        "entriesPerPage": "Hyrjet për faqe",
                        "confirmPhrase": "Ju lutemi shkruani <strong>{{confirmPhrase}}</strong> për të konfirmuar",
                        "confirm": "Konfirmo",
                        "cancel": "Anulo",
                        "copiedToClipboard": "Kopjuar në kujtesën e përkohshme!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Produkte",
                            "pricing": "Çmimet",
                            "aboutUs": "Rreth Nesh",
                            "docs": "Dokumentacioni",
                            "contact": "Kontakt"
                        },
                        "hero0": "Asistenti juaj i personalizuar chatbot. Ushqeni atë me manualet tuaja, faqet e internetit dhe FAQ-të për të përgjigjur automatikisht, inteligjent dhe saktë në pyetjet e klientëve. Një aplikacion SaaS i thjeshtë për përdorim, interaktiv dhe shumëgjuhësh për mbështetje të personalizuar dhe efikase për klientët.",
                        "hero1": "Regjistrohu",
                        "section0": "Asistent chatbot i trajnuar individualisht për një ndërveprim të personalizuar me klientët.",
                        "section1": "Lehtësimi i ekspertëve tuaj duke marrë përsipër shumicën e pyetjeve të mbështetjes nga AI.",
                        "section2": "Përvojë e optimizuar e përdoruesit përmes mësimit kontekstual, trajnimit interaktiv dhe përmirësimit të vazhdueshëm të cilësisë së përgjigjeve.",
                        "section3": "Arrihet globalisht dhe fleksibilitet përmes mbështetjes për shumë gjuhë, burime dhe lloje dokumentesh.",
                        "section4": "Një plan fleksibël pagese është në dispozicion për të gjitha shërbimet tona.",
                        "tech0": "Teknologjia",
                        "tech1": "BotBucket përdor teknikën NLP të 'përgjigjes së pyetjeve kontekstuale': Informacionet që ofroni ngarkohen në një memorie të unifikuar, e cila është optimizuar për përpunimin e gjuhës natyrore (NLP). Kjo memorie përdoret gjatë bisedës për të gjetur blloqe informacioni relevante në lidhje me pyetjen e përdoruesit. Një model AI i kombinon këto blloqe në një përgjigje kuptimplotë.",
                        "tech2": "Mëso më shumë",
                        "aboutUs0": "Rreth Nesh",
                        "aboutUs1": "Mirë se vini në BotBucket! Këtu ushqeni modelet më të fundit GPT me dokumentet tuaja. Kështu krijoni asistentin tuaj të personalizuar të chat-it në sekonda. Me BotBucket, lidhni thesarin tuaj të njohurive me mundësitë e përpunimit modern të gjuhës natyrore. Kështu BotBucket lehtëson mbështetjen tuaj për klientët, krijon transparencë dhe përmirëson proceset tuaja.",
                        "aboutUs2": "Kontakt",
                        "contact0": "Na kontaktoni!",
                        "contact1": "Emri",
                        "contact2": "Email",
                        "contact3": "Subjekti",
                        "contact4": "Mesazhi",
                        "contact5": "Dërgo"
                    },
                    "userMenu": {
                        "login": "Hyrje",
                        "profile": "Profili",
                        "settings": "Cilësimet",
                        "logout": "Dalje"
                    },
                    "dashboard": {
                        "welcome": "Mirë se vini",
                        "myChatbots": "Chatbot-ët e mi",
                        "newChatbot": "Chatbot i ri",
                        "instantChatbot": "Chatbot i menjëhershëm"
                    },
                    "chatbot": {
                        "myBot": "Boti im - {{botName}}",
                        "settings": "Cilësimet",
                        "fileTopic": "Tema (, e ndarë)",
                        "topic": "Tema",
                        "includedInTraining": "Ky skedar është përfshirë në trajnimin e fundit dhe tani është i njohur nga chatbot.",
                        "notIncludedInTraining": "Ky skedar nuk është përfshirë ende. Trajno tani për të përfshirë këtë skedar.",
                        "trainingStatus": {
                            "notTrained": "Ende nuk është trajnuar",
                            "pending": "Trajnimi do të fillojë së shpejti",
                            "training": "Trajnimi në proces",
                            "trained": "Trajnimi ishte i suksesshëm",
                            "failed": "Trajnimi dështoi",
                            "label": "Statusi i trajnimit"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Përshtatni chatbot-in tuaj!",
                            "publicAvailability": "Disponueshmëri publike",
                            "publicAvailable": "Chatbot-i juaj është i dukshëm për të gjithë. Mund ta ndani atë me lidhjen më poshtë.",
                            "notPublicAvailable": "Vetëm ju mund të shihni chatbot-in tuaj. Përdorni kaluesin për ta bërë atë të dukshëm për të gjithë.",
                            "publicLink": "Lidhje publike",
                            "deleteYourBot": "Fshini chatbot-in tuaj",
                            allowUsers: 'Lejo përdoruesit',
                            searchUser: 'Kërko përdorues',
                            addUser: 'Shto përdorues',
                            allowedUsers: 'Përdoruesit e lejuar',
                            username: 'Emri i përdoruesit',
                            email: 'Email',
                            enterEmail: 'Vendosni adresën e emailit të përdoruesit që dëshironi të lejoni qasje',
                            actions: 'Veprimet',
                            userAdded: 'Përdoruesi u shtua',
                            userRemoved: 'Përdoruesi u hoq',
                            noUsersAllowed: 'Nuk lejohen përdorues',
                            removeUser: 'Hiq përdoruesin',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Shtoni dokumente në chatbot",
                            "uploadingFiles": "Ngarkimi i dokumenteve...",
                            "filesStillUploading": "Disa dokumente nuk janë ngarkuar ende plotësisht, ju lutem prisni",
                            "submit": "Konfirmo"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Trajno chatbot-in tuaj",
                            "embeddings": "Integrime",
                            "startTraining": "Filloni trajnimin"
                        },
                        "botNotTrainedNoTrainingPossible": "Boti nuk është trajnuar - aktualisht nuk është e mundur të bëhet chat",
                        "chat": "Chat",
                        "botTraining": "Boti është aktualisht në trajnim - ju lutem prisni",
                        "trainNow": "Trajno tani",
                        "uploadedDocuments": "Dokumentet e trajnimit",
                        "document": "Dokumenti",
                        "uploadedDate": "Data e ngarkimit",
                        "noFilesUploaded": "Ende nuk janë ngarkuar dokumente",
                        "deleteBot": "Fshini chatbot-in",
                        "confirmDelete": "A jeni të sigurt që doni të fshini chatbot-in tuaj {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Chatbot-ët e mi",
                        "newChatbot": "Chatbot i ri",
                        "chat": "Chat",
                        "edit": "Redakto",
                        "name": "Emri",
                        "numTrainingDocs": "# Dokumentet e trajnimit",
                        "numQueries": "# Pyetjet",
                        "trainingStatus": "Statusi",
                        "noChatbotCreated": "Ende nuk janë krijuar chatbot-e.",
                        "delete": "Fshije"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Krijoni një chatbot të ri",
                        "name": "Emri",
                        "description": "Përshkrimi",
                        "createBot": "Krijo bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Për çfarë janë të dobishme 'Temat' në chat-in tim?"
                        },
                        "topic": "Tema",
                        "myChatWith": "Chat-i im me {{botName}}",
                        "initMessage": "Përshëndetje, unë jam {{botName}}. Di gjithçka për {{description}}. Çfarë dëshiron të dish?",
                        "initMessageCIS": "Përshëndetje, unë jam {{botName}}. Dua të mësoj më shumë rreth detyrave tuaja të përditshme për t'i dokumentuar ato. Kjo ka për qëllim t'ju ndihmojë ju dhe kolegët tuaj të orientoheni më mirë ose të mbuloni për ju.",
                        "foundTheseSources": "Informacione të tjera këtu:",
                        "userInput": "Hyrja juaj",
                        "dontSharePersonalData": "Historia e chat-it ruhet për tre muaj. Ju lutemi mos ndani të dhëna personale."
                    },
                    "helperBot": {
                        "iCanHelp": "Mund t'ju ndihmoj!",
                        "helpBotWelcome": "Përshëndetje, unë jam BotBucket. Si mund të ndihmoj?"
                    },
                    "login": {
                        "login": "Hyrje",
                        "signUp": "Regjistrimi",
                        "username": "Emri i përdoruesit",
                        "password": "Fjalëkalimi",
                        "passwordRepeat": "Fjalëkalimi (përsëritje)",
                        "mail": "Email",
                        "navToLogin": "Hyrje",
                        "navToSignUp": "Regjistrimi",
                        "usernameOrEmail": "Emri i përdoruesit ose Email",
                        "forgotPassword": "Keni harruar fjalëkalimin?",
                        "resetPassword": "Rivendosni fjalëkalimin",
                        "reset": "Rivendosni",
                        "passwordResetted": "Fjalëkalimi është rivendosur",
                        "thanksWeSentResetMail": "Faleminderit! Ju kemi dërguar një email me udhëzime të mëtejshme.",
                        "passwordSuccessfullyResettedLoginAgain": "Fjalëkalimi juaj është rivendosur me sukses. Tani mund të hyni me fjalëkalimin tuaj të ri.",
                        "helloPleaseChooseNewPassword": "Përshëndetje, {{username}}! Ju lutemi vendosni një fjalëkalim të ri!",
                        "newPassword": "Fjalëkalim i ri",
                        "newPasswordRepeat": "Fjalëkalim i ri (përsëritje)",
                        "resetLinkNotValid": "Për fat të keq, lidhja juaj nuk është e vlefshme ose ka skaduar. Ju lutemi kërkoni përsëri rivendosjen e fjalëkalimit.",
                        "or": "Ose",
                        "userNotFound": "Përdoruesi nuk u gjet - ju lutemi",
                        "userAlreadyTaken": "Përdoruesi është marrë tashmë - ju lutemi"
                    },
                    "validations": {
                        "usernameMinChar": "Emri i përdoruesit duhet të ketë të paktën 3 karaktere",
                        "chatbotNameMinChar": "Emri i chatbot-it duhet të ketë të paktën 6 karaktere",
                        "enterValidMail": "Email nuk është i vlefshëm",
                        "passwordMinChar": "Fjalëkalimi duhet të ketë të paktën 8 karaktere",
                        "passwordContainDigit": "Fjalëkalimi duhet të përmbajë një numër",
                        "passwordContainUppercase": "Fjalëkalimi duhet të përmbajë një shkronjë të madhe",
                        "passwordContainLowercase": "Fjalëkalimi duhet të përmbajë një shkronjë të vogël",
                        "passwordSame": "Fjalëkalimet duhet të përputhen"
                    },
                    "prices": {
                        "price": "Çmimi",
                        "join": "Bashkohuni",
                        "susbcribe": "Abonohu",
                        "active": "Aktiv"
                    },
                    "profile": {
                        "updateProfileButton": "Përditësoni profilin",
                        "noCustomer": "Asnjë klient i krijuar",
                        "pricesButton": "Përmbledhje e produkteve",
                        "createCustomer": "Përditësoni profilin"
                    },
                    "overview": {
                        "free": {
                            "description1": "Numri maksimal i faqeve: 15",
                            "description2": "Shikime maksimale: 10",
                            "description3": "Informacione të tjera..."
                        },
                        "basic": {
                            "description1": "Numri maksimal i faqeve: 20",
                            "description2": "Shikime maksimale: 50",
                            "description3": "Informacione të tjera..."
                        },
                        "premium": {
                            "description1": "Numri maksimal i faqeve: 50",
                            "description2": "Shikime maksimale: 100",
                            "description3": "Informacione të tjera..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Abonimi i suksesshëm!",
                            "updated": "Profili i përditësuar!",
                            "cancel": "Ndodhi një gabim!",
                            "created": "Klienti i krijuar me sukses!"
                        },
                        "invoice": "Shikoni faturën: ",
                        "invoiceLink": "Fatura",
                        "button": "Kthehuni në menu!"
                    }
                }
            },

            // Serbisch
            "sr": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Izaberite datoteku ili je prevucite ovde"
                    },
                    "common": {
                        "somethingWentWrong": "Ups, nešto je pošlo naopako!",
                        "entriesPerPage": "Unosi po stranici",
                        "confirmPhrase": "Molimo unesite <strong>{{confirmPhrase}}</strong> za potvrdu",
                        "confirm": "Potvrdi",
                        "cancel": "Otkaži",
                        "copiedToClipboard": "Kopirano u međumemoriju!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Proizvodi",
                            "pricing": "Cene",
                            "aboutUs": "O nama",
                            "docs": "Dokumentacija",
                            "contact": "Kontakt"
                        },
                        "hero0": "Vaš prilagođeni chatbot asistent. Nahranite ga svojim priručnicima, web stranicama i FAQ-ovima kako bi automatski, inteligentno i precizno odgovarao na pitanja korisnika. Korisnički prilagođena, interaktivna i višejezična SaaS aplikacija za efikasan i personalizovan korisnički servis.",
                        "hero1": "Registrujte se",
                        "section0": "Pojedinačno trenirani chatbot asistent za personalizovanu interakciju sa korisnicima.",
                        "section1": "Oslobađanje vaših stručnjaka preuzimanjem većine pitanja za podršku pomoću AI.",
                        "section2": "Optimizovano korisničko iskustvo kroz kontekstualno učenje, interaktivnu obuku i kontinuirano poboljšanje kvaliteta odgovora.",
                        "section3": "Globalni domet i fleksibilnost kroz podršku za mnoge jezike, izvore i tipove dokumenata.",
                        "section4": "Fleksibilan plan plaćanja je dostupan za sve naše usluge.",
                        "tech0": "Tehnologija",
                        "tech1": "BotBucket koristi NLP tehniku 'kontekstualnog odgovaranja na pitanja': informacije koje pružate se učitavaju u jedinstvenu memoriju, optimizovanu za obradu prirodnog jezika (NLP). Ova memorija se koristi tokom razgovora za pronalaženje relevantnih blokova informacija u vezi sa korisnikovim pitanjem. AI model zatim kombinuje ove blokove u smisleni odgovor.",
                        "tech2": "Saznajte više",
                        "aboutUs0": "O nama",
                        "aboutUs1": "Dobrodošli u BotBucket! Ovdje hranite najnovije GPT modele svojim dokumentima. Tako stvarate svog individualnog asistenta za chat u sekundi. Sa BotBucket-om povezujete svoj riznicu znanja sa mogućnostima moderne obrade prirodnog jezika. Tako BotBucket oslobađa vašu korisničku podršku, stvara transparentnost i poboljšava vaše procese.",
                        "aboutUs2": "Kontakt",
                        "contact0": "Kontaktirajte nas!",
                        "contact1": "Ime",
                        "contact2": "Email",
                        "contact3": "Predmet",
                        "contact4": "Poruka",
                        "contact5": "Pošalji"
                    },
                    "userMenu": {
                        "login": "Prijava",
                        "profile": "Profil",
                        "settings": "Podešavanja",
                        "logout": "Odjava"
                    },
                    "dashboard": {
                        "welcome": "Dobrodošli",
                        "myChatbots": "Moji chatboti",
                        "newChatbot": "Novi chatbot",
                        "instantChatbot": "Instant chatbot"
                    },
                    "chatbot": {
                        "myBot": "Moj bot - {{botName}}",
                        "settings": "Podešavanja",
                        "fileTopic": "Tema (, odvojeno)",
                        "topic": "Tema",
                        "includedInTraining": "Ova datoteka je uključena u poslednji trening i sada je poznata chatbot-u.",
                        "notIncludedInTraining": "Ova datoteka još nije uključena. Trenutno trenirajte kako biste uključili ovu datoteku.",
                        "trainingStatus": {
                            "notTrained": "Još nije treniran",
                            "pending": "Trening će uskoro početi",
                            "training": "Trening u toku",
                            "trained": "Trening je uspešan",
                            "failed": "Trening je neuspešan",
                            "label": "Status treninga"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Prilagodite svog chatbota!",
                            "publicAvailability": "Javna dostupnost",
                            "publicAvailable": "Vaš chatbot je vidljiv svima. Možete ga podeliti sa donjim linkom.",
                            "notPublicAvailable": "Samo vi možete videti svog chatbota. Koristite prekidač da ga učinite vidljivim za sve.",
                            "publicLink": "Javni link",
                            "deleteYourBot": "Obrišite svog chatbota",
                            allowUsers: 'Dozvoli korisnike',
                            searchUser: 'Pretraži korisnika',
                            addUser: 'Dodaj korisnika',
                            allowedUsers: 'Dozvoljeni korisnici',
                            username: 'Korisničko ime',
                            email: 'E-pošta',
                            enterEmail: 'Unesite e-poštu korisnika kojem želite da dozvolite pristup',
                            actions: 'Radnje',
                            userAdded: 'Korisnik dodat',
                            userRemoved: 'Korisnik uklonjen',
                            noUsersAllowed: 'Nema dozvoljenih korisnika',
                            removeUser: 'Ukloni korisnika',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Dodajte dokumente chatbot-u",
                            "uploadingFiles": "Učitavanje dokumenata...",
                            "filesStillUploading": "Neki dokumenti još nisu potpuno učitani, molimo sačekajte",
                            "submit": "Potvrdi"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Trenirajte svog chatbota",
                            "embeddings": "Umetanja",
                            "startTraining": "Počnite trening"
                        },
                        "botNotTrainedNoTrainingPossible": "Bot nije treniran - trenutno nije moguće chat-ovati",
                        "chat": "Chat",
                        "botTraining": "Bot je trenutno na treningu - molimo sačekajte",
                        "trainNow": "Trenirajte sada",
                        "uploadedDocuments": "Dokumenti za trening",
                        "document": "Dokument",
                        "uploadedDate": "Datum učitavanja",
                        "noFilesUploaded": "Nijedan dokument još nije učitan",
                        "deleteBot": "Obrišite chatbota",
                        "confirmDelete": "Da li stvarno želite da obrišete svog chatbota {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Moji chatboti",
                        "newChatbot": "Novi chatbot",
                        "chat": "Chat",
                        "edit": "Uredi",
                        "name": "Ime",
                        "numTrainingDocs": "# Dokumenti za trening",
                        "numQueries": "# Upiti",
                        "trainingStatus": "Status",
                        "noChatbotCreated": "Još nisu kreirani chatboti.",
                        "delete": "Obriši"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Kreirajte novog chatbota",
                        "name": "Ime",
                        "description": "Opis",
                        "createBot": "Kreiraj bota"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Za šta su 'Teme' korisne u mom chatu?"
                        },
                        "topic": "Tema",
                        "myChatWith": "Moj chat sa {{botName}}",
                        "initMessage": "Zdravo, ja sam {{botName}}. Znam sve o {{description}}. Šta želite da znate?",
                        "initMessageCIS": "Zdravo, ja sam {{botName}}. Želim da saznam više o vašim svakodnevnim zadacima kako bih ih dokumentovao. Ovo ima za cilj da vam pomogne da se vi i vaše kolege bolje orijentišete ili pokrijete jedni druge.",
                        "foundTheseSources": "Više informacija ovde:",
                        "userInput": "Vaš unos",
                        "dontSharePersonalData": "Istorija chata se čuva tri meseca. Molimo ne delite lične podatke."
                    },
                    "helperBot": {
                        "iCanHelp": "Mogu vam pomoći!",
                        "helpBotWelcome": "Zdravo, ja sam BotBucket. Kako mogu da pomognem?"
                    },
                    "login": {
                        "login": "Prijava",
                        "signUp": "Registracija",
                        "username": "Korisničko ime",
                        "password": "Lozinka",
                        "passwordRepeat": "Lozinka (ponovo)",
                        "mail": "Email",
                        "navToLogin": "Prijava",
                        "navToSignUp": "Registracija",
                        "usernameOrEmail": "Korisničko ime ili Email",
                        "forgotPassword": "Zaboravili ste lozinku?",
                        "resetPassword": "Resetovanje lozinke",
                        "reset": "Resetuj",
                        "passwordResetted": "Lozinka resetovana",
                        "thanksWeSentResetMail": "Hvala! Poslali smo vam email sa daljim uputstvima.",
                        "passwordSuccessfullyResettedLoginAgain": "Vaša lozinka je uspešno resetovana. Sada se možete prijaviti sa novom lozinkom.",
                        "helloPleaseChooseNewPassword": "Zdravo, {{username}}! Molimo vas da postavite novu lozinku!",
                        "newPassword": "Nova lozinka",
                        "newPasswordRepeat": "Nova lozinka (ponovo)",
                        "resetLinkNotValid": "Nažalost, vaš link nije važeći ili je istekao. Molimo ponovo zatražite resetovanje lozinke.",
                        "or": "Ili",
                        "userNotFound": "Korisnik nije pronađen - molimo",
                        "userAlreadyTaken": "Korisničko ime je već zauzeto - molimo"
                    },
                    "validations": {
                        "usernameMinChar": "Korisničko ime mora imati najmanje 3 karaktera",
                        "chatbotNameMinChar": "Ime chatbota mora imati najmanje 6 karaktera",
                        "enterValidMail": "Email nije validan",
                        "passwordMinChar": "Lozinka mora imati najmanje 8 karaktera",
                        "passwordContainDigit": "Lozinka mora sadržati broj",
                        "passwordContainUppercase": "Lozinka mora sadržati veliko slovo",
                        "passwordContainLowercase": "Lozinka mora sadržati malo slovo",
                        "passwordSame": "Lozinke se moraju poklapati"
                    },
                    "prices": {
                        "price": "Cena",
                        "join": "Pridruži se",
                        "susbcribe": "Pretplati se",
                        "active": "Aktivno"
                    },
                    "profile": {
                        "updateProfileButton": "Ažurirajte profil",
                        "noCustomer": "Nijedan korisnik nije kreiran",
                        "pricesButton": "Pregled proizvoda",
                        "createCustomer": "Ažurirajte profil"
                    },
                    "overview": {
                        "free": {
                            "description1": "Maksimalan broj stranica: 15",
                            "description2": "Maksimalan broj pregleda: 10",
                            "description3": "Više informacija..."
                        },
                        "basic": {
                            "description1": "Maksimalan broj stranica: 20",
                            "description2": "Maksimalan broj pregleda: 50",
                            "description3": "Više informacija..."
                        },
                        "premium": {
                            "description1": "Maksimalan broj stranica: 50",
                            "description2": "Maksimalan broj pregleda: 100",
                            "description3": "Više informacija..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Pretplata uspešna!",
                            "updated": "Profil ažuriran!",
                            "cancel": "Došlo je do greške!",
                            "created": "Korisnik uspešno kreiran!"
                        },
                        "invoice": "Pogledajte račun: ",
                        "invoiceLink": "Račun",
                        "button": "Nazad na meni!"
                    }
                }
            },

            // Ungarisch
            "hu": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Válasszon ki egy fájlt, vagy húzza ide"
                    },
                    "common": {
                        "somethingWentWrong": "Hoppá, valami hiba történt!",
                        "entriesPerPage": "Bejegyzések oldalanként",
                        "confirmPhrase": "Kérjük, írja be a <strong>{{confirmPhrase}}</strong> kifejezést a megerősítéshez",
                        "confirm": "Megerősít",
                        "cancel": "Mégse",
                        "copiedToClipboard": "A vágólapra másolva!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Termékek",
                            "pricing": "Árak",
                            "aboutUs": "Rólunk",
                            "docs": "Dokumentáció",
                            "contact": "Kapcsolat"
                        },
                        "hero0": "Az Ön személyre szabott chatbot asszisztense. Táplálja meg kézikönyveivel, webhelyeivel és GYIK-vel, hogy automatikusan, intelligensen és pontosan válaszoljon az ügyfélkérdésekre. Felhasználóbarát, interaktív és többnyelvű SaaS alkalmazás a hatékony és személyre szabott ügyfélszolgálat érdekében.",
                        "hero1": "Regisztráció",
                        "section0": "Egyedileg képzett chatbot asszisztens a személyre szabott ügyfélkapcsolatokhoz.",
                        "section1": "A szakértői terhek csökkentése AI által támogatott ügyfélszolgálati kérdések átvételével.",
                        "section2": "Optimalizált felhasználói élmény kontextuális tanulás, interaktív képzés és a válaszok minőségének folyamatos javítása révén.",
                        "section3": "Globális elérhetőség és rugalmasság a sok nyelv, forrás és dokumentumtípus támogatásával.",
                        "section4": "Rugalmas fizetési terv elérhető minden szolgáltatásunkhoz.",
                        "tech0": "Technológia",
                        "tech1": "A BotBucket a 'kontekstusfüggő kérdés-válasz' NLP technikát alkalmazza: az Ön által megadott információkat egy egységes memóriába töltik be, amely optimalizálva van a természetes nyelv feldolgozásához (NLP). Ezt a memóriát használják a csevegés során a felhasználói kérdésekre releváns információblokkok megtalálásához. Egy AI modell ezeket a blokkokat összekombinálja egy értelmes válasszá.",
                        "tech2": "Tudjon meg többet",
                        "aboutUs0": "Rólunk",
                        "aboutUs1": "Üdvözöljük a BotBucket-nél! Itt táplálja meg a legújabb GPT modelleket dokumentumaival. Így másodpercek alatt létrehozhatja saját személyre szabott chatbot asszisztensét. A BotBucket segítségével összekapcsolja tudásbázisát a modern természetes nyelv feldolgozásának lehetőségeivel. Így a BotBucket tehermentesíti ügyfélszolgálatát, átláthatóságot teremt és javítja folyamatait.",
                        "aboutUs2": "Kapcsolat",
                        "contact0": "Vegye fel velünk a kapcsolatot!",
                        "contact1": "Név",
                        "contact2": "Email",
                        "contact3": "Tárgy",
                        "contact4": "Üzenet",
                        "contact5": "Küldés"
                    },
                    "userMenu": {
                        "login": "Bejelentkezés",
                        "profile": "Profil",
                        "settings": "Beállítások",
                        "logout": "Kijelentkezés"
                    },
                    "dashboard": {
                        "welcome": "Üdvözöljük",
                        "myChatbots": "Saját chatbotaim",
                        "newChatbot": "Új chatbot",
                        "instantChatbot": "Azonnali chatbot"
                    },
                    "chatbot": {
                        "myBot": "Az én botom - {{botName}}",
                        "settings": "Beállítások",
                        "fileTopic": "Téma (, elválasztva)",
                        "topic": "Téma",
                        "includedInTraining": "Ezt a fájlt a legutóbbi képzés során figyelembe vették, és most már a chatbot ismeri.",
                        "notIncludedInTraining": "Ezt a fájlt még nem vették figyelembe. Képzés most, hogy ezt a fájlt is belefoglaljuk.",
                        "trainingStatus": {
                            "notTrained": "Még nincs képzett",
                            "pending": "A képzés hamarosan kezdődik",
                            "training": "Képzés folyamatban",
                            "trained": "A képzés sikeres volt",
                            "failed": "A képzés sikertelen",
                            "label": "Képzési státusz"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Testreszabhatja a chatbotját!",
                            "publicAvailability": "Nyilvános elérhetőség",
                            "publicAvailable": "A chatbotja mindenki számára látható. Megoszthatja az alábbi linken.",
                            "notPublicAvailable": "Csak Ön láthatja a chatbotját. A kapcsolóval láthatóvá teheti mindenki számára.",
                            "publicLink": "Nyilvános link",
                            "deleteYourBot": "Törölje a chatbotját",
                            allowUsers: 'Felhasználók engedélyezése',
                            searchUser: 'Felhasználó keresése',
                            addUser: 'Felhasználó hozzáadása',
                            allowedUsers: 'Engedélyezett felhasználók',
                            username: 'Felhasználónév',
                            email: 'E-mail',
                            enterEmail: 'Adja meg a felhasználó e-mail címét, akinek hozzáférést szeretne enged',
                            actions: 'Műveletek',
                            userAdded: 'Felhasználó hozzáadva',
                            userRemoved: 'Felhasználó eltávolítva',
                            noUsersAllowed: 'Nincsenek engedélyezett felhasználók',
                            removeUser: 'Felhasználó eltávolítása',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Dokumentumok hozzáadása a chatbothoz",
                            "uploadingFiles": "Dokumentumok feltöltése...",
                            "filesStillUploading": "Néhány dokumentum még nem töltődött fel teljesen, kérjük várjon",
                            "submit": "Megerősít"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Képezze ki a chatbotját",
                            "embeddings": "Beágyazások",
                            "startTraining": "Képzés indítása"
                        },
                        "botNotTrainedNoTrainingPossible": "A bot nincs kiképezve - jelenleg nem lehetséges a chat",
                        "chat": "Chat",
                        "botTraining": "A bot jelenleg képzés alatt áll - kérjük, várjon",
                        "trainNow": "Képezze most",
                        "uploadedDocuments": "Képzési dokumentumok",
                        "document": "Dokumentum",
                        "uploadedDate": "Feltöltés dátuma",
                        "noFilesUploaded": "Még nem töltöttek fel dokumentumokat",
                        "deleteBot": "Chatbot törlése",
                        "confirmDelete": "Biztosan törölni szeretné a chatbotját {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Saját chatbotaim",
                        "newChatbot": "Új chatbot",
                        "chat": "Chat",
                        "edit": "Szerkeszt",
                        "name": "Név",
                        "numTrainingDocs": "# Képzési dokumentumok",
                        "numQueries": "# Kérdések",
                        "trainingStatus": "Státusz",
                        "noChatbotCreated": "Még nem hoztak létre chatbotokat.",
                        "delete": "Törlés"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Új chatbot létrehozása",
                        "name": "Név",
                        "description": "Leírás",
                        "createBot": "Bot létrehozása"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Mire jók a 'Témák' a csevegésemben?"
                        },
                        "topic": "Téma",
                        "myChatWith": "Csevegésem {{botName}}-val",
                        "initMessage": "Szia, én vagyok {{botName}}. Mindent tudok {{description}}-ról/ről. Mit szeretnél tudni?",
                        "initMessageCIS": "Szia, én vagyok {{botName}}. Szeretnék többet megtudni a napi feladataidról, hogy dokumentálhassam őket. Ez segíteni fog neked és kollégáidnak jobban eligazodni vagy helyettesíteni téged.",
                        "foundTheseSources": "További információk itt:",
                        "userInput": "A te beviteli meződ",
                        "dontSharePersonalData": "A csevegés előzményeit három hónapig tároljuk. Kérjük, ne osszon meg személyes adatokat."
                    },
                    "helperBot": {
                        "iCanHelp": "Tudok segíteni!",
                        "helpBotWelcome": "Szia, én vagyok a BotBucket. Hogyan segíthetek?"
                    },
                    "login": {
                        "login": "Bejelentkezés",
                        "signUp": "Regisztráció",
                        "username": "Felhasználónév",
                        "password": "Jelszó",
                        "passwordRepeat": "Jelszó (ismét)",
                        "mail": "Email",
                        "navToLogin": "Bejelentkezés",
                        "navToSignUp": "Regisztráció",
                        "usernameOrEmail": "Felhasználónév vagy Email",
                        "forgotPassword": "Elfelejtette a jelszavát?",
                        "resetPassword": "Jelszó visszaállítása",
                        "reset": "Visszaállít",
                        "passwordResetted": "Jelszó visszaállítva",
                        "thanksWeSentResetMail": "Köszönjük! Küldtünk egy emailt további útmutatásokkal.",
                        "passwordSuccessfullyResettedLoginAgain": "A jelszavát sikeresen visszaállítottuk. Most már bejelentkezhet az új jelszavával.",
                        "helloPleaseChooseNewPassword": "Szia, {{username}}! Kérjük, állítson be egy új jelszót!",
                        "newPassword": "Új jelszó",
                        "newPasswordRepeat": "Új jelszó (ismét)",
                        "resetLinkNotValid": "Sajnos a linkje érvénytelen vagy lejárt. Kérjük, kérje újra a jelszó visszaállítását.",
                        "or": "Vagy",
                        "userNotFound": "A felhasználó nem található - kérjük",
                        "userAlreadyTaken": "A felhasználónév már foglalt - kérjük"
                    },
                    "validations": {
                        "usernameMinChar": "A felhasználónévnek legalább 3 karakter hosszúnak kell lennie",
                        "chatbotNameMinChar": "A chatbot nevének legalább 6 karakter hosszúnak kell lennie",
                        "enterValidMail": "Az email nem érvényes",
                        "passwordMinChar": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
                        "passwordContainDigit": "A jelszónak tartalmaznia kell egy számot",
                        "passwordContainUppercase": "A jelszónak tartalmaznia kell egy nagybetűt",
                        "passwordContainLowercase": "A jelszónak tartalmaznia kell egy kisbetűt",
                        "passwordSame": "A jelszavaknak egyezniük kell"
                    },
                    "prices": {
                        "price": "Ár",
                        "join": "Csatlakozz",
                        "susbcribe": "Feliratkozás",
                        "active": "Aktív"
                    },
                    "profile": {
                        "updateProfileButton": "Profil frissítése",
                        "noCustomer": "Nincs létrehozva ügyfél",
                        "pricesButton": "Termékek áttekintése",
                        "createCustomer": "Profil frissítése"
                    },
                    "overview": {
                        "free": {
                            "description1": "Maximális oldalszám: 15",
                            "description2": "Maximális megtekintések: 10",
                            "description3": "További információk..."
                        },
                        "basic": {
                            "description1": "Maximális oldalszám: 20",
                            "description2": "Maximális megtekintések: 50",
                            "description3": "További információk..."
                        },
                        "premium": {
                            "description1": "Maximális oldalszám: 50",
                            "description2": "Maximális megtekintések: 100",
                            "description3": "További információk..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Sikeres előfizetés!",
                            "updated": "Profil frissítve!",
                            "cancel": "Hiba történt!",
                            "created": "Ügyfél sikeresen létrehozva!"
                        },
                        "invoice": "Számla megtekintése: ",
                        "invoiceLink": "Számla",
                        "button": "Vissza a menübe!"
                    }
                }
            },

            // Polnisch
            "pl": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Wybierz plik lub przeciągnij go tutaj"
                    },
                    "common": {
                        "somethingWentWrong": "Ups, coś poszło nie tak!",
                        "entriesPerPage": "Wpisy na stronę",
                        "confirmPhrase": "Proszę wpisać <strong>{{confirmPhrase}}</strong> aby potwierdzić",
                        "confirm": "Potwierdź",
                        "cancel": "Anuluj",
                        "copiedToClipboard": "Skopiowano do schowka!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Produkty",
                            "pricing": "Ceny",
                            "aboutUs": "O nas",
                            "docs": "Dokumentacja",
                            "contact": "Kontakt"
                        },
                        "hero0": "Twój dostosowany asystent chatbot. Nakarm go swoimi podręcznikami, stronami internetowymi i FAQ, aby automatycznie, inteligentnie i precyzyjnie odpowiadać na pytania klientów. Przyjazna dla użytkownika, interaktywna i wielojęzyczna aplikacja SaaS do efektywnej i spersonalizowanej obsługi klienta.",
                        "hero1": "Zarejestruj się",
                        "section0": "Indywidualnie szkolony asystent chatbot do dostosowanej interakcji z klientami.",
                        "section1": "Odciążenie ekspertów poprzez przejęcie większości pytań wsparcia dzięki AI.",
                        "section2": "Optymalizowane doświadczenie użytkownika dzięki kontekstualnemu uczeniu się, interaktywnemu szkoleniu i ciągłemu poprawianiu jakości odpowiedzi.",
                        "section3": "Globalny zasięg i elastyczność dzięki wsparciu wielu języków, źródeł i typów dokumentów.",
                        "section4": "Dostępny jest elastyczny plan płatności dla wszystkich naszych usług.",
                        "tech0": "Technologia",
                        "tech1": "BotBucket używa techniki NLP 'kontekstowego odpowiadania na pytania': informacje dostarczone przez Ciebie są ładowane do jednolitej pamięci, która jest zoptymalizowana do przetwarzania języka naturalnego (NLP). Ta pamięć jest używana podczas czatu do znajdowania odpowiednich bloków informacji w odniesieniu do pytania użytkownika. Model AI łączy te bloki w sensowną odpowiedź.",
                        "tech2": "Dowiedz się więcej",
                        "aboutUs0": "O nas",
                        "aboutUs1": "Witamy w BotBucket! Tutaj zasilasz najnowsze modele GPT swoimi dokumentami. Tworzysz swojego indywidualnego asystenta czatu w ciągu kilku sekund. Dzięki BotBucket łączysz swoje zasoby wiedzy z możliwościami nowoczesnego przetwarzania języka naturalnego. BotBucket odciąża Twoją obsługę klienta, tworzy przejrzystość i usprawnia Twoje procesy.",
                        "aboutUs2": "Kontakt",
                        "contact0": "Skontaktuj się z nami!",
                        "contact1": "Nazwa",
                        "contact2": "Email",
                        "contact3": "Temat",
                        "contact4": "Wiadomość",
                        "contact5": "Wyślij"
                    },
                    "userMenu": {
                        "login": "Zaloguj się",
                        "profile": "Profil",
                        "settings": "Ustawienia",
                        "logout": "Wyloguj się"
                    },
                    "dashboard": {
                        "welcome": "Witamy",
                        "myChatbots": "Moje chatboty",
                        "newChatbot": "Nowy chatbot",
                        "instantChatbot": "Instant Chatbot"
                    },
                    "chatbot": {
                        "myBot": "Mój bot - {{botName}}",
                        "settings": "Ustawienia",
                        "fileTopic": "Temat (, oddzielone)",
                        "topic": "Temat",
                        "includedInTraining": "Ten plik został uwzględniony w ostatnim treningu i jest teraz znany chatbotowi.",
                        "notIncludedInTraining": "Ten plik nie został jeszcze uwzględniony. Trenuj teraz, aby uwzględnić ten plik.",
                        "trainingStatus": {
                            "notTrained": "Jeszcze nie przeszkolony",
                            "pending": "Trening wkrótce się rozpocznie",
                            "training": "Trening w toku",
                            "trained": "Trening zakończony sukcesem",
                            "failed": "Trening nie powiódł się",
                            "label": "Status treningu"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Dostosuj swojego chatbota!",
                            "publicAvailability": "Dostępność publiczna",
                            "publicAvailable": "Twój chatbot jest widoczny dla wszystkich. Możesz udostępnić go za pomocą poniższego linku.",
                            "notPublicAvailable": "Tylko Ty możesz zobaczyć swojego chatbota. Użyj przełącznika, aby uczynić go widocznym dla wszystkich.",
                            "publicLink": "Publiczny link",
                            "deleteYourBot": "Usuń swojego chatbota",
                            allowUsers: 'Zezwól użytkownikom',
                            searchUser: 'Szukaj użytkownika',
                            addUser: 'Dodaj użytkownika',
                            allowedUsers: 'Dozwoleni użytkownicy',
                            username: 'Nazwa użytkownika',
                            email: 'Email',
                            enterEmail: 'Wprowadź adres e-mail użytkownika, któremu chcesz przyznać dostęp',
                            actions: 'Działania',
                            userAdded: 'Użytkownik dodany',
                            userRemoved: 'Użytkownik usunięty',
                            noUsersAllowed: 'Brak dozwolonych użytkowników',
                            removeUser: 'Usuń użytkownika',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Dodaj dokumenty do chatbota",
                            "uploadingFiles": "Ładowanie dokumentów...",
                            "filesStillUploading": "Niektóre dokumenty nie zostały jeszcze w pełni załadowane, proszę czekać",
                            "submit": "Zatwierdź"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Trenuj swojego chatbota",
                            "embeddings": "Osadzenia",
                            "startTraining": "Rozpocznij trening"
                        },
                        "botNotTrainedNoTrainingPossible": "Bot nie jest przeszkolony - obecnie nie można czatować",
                        "chat": "Czat",
                        "botTraining": "Bot jest w trakcie treningu - proszę czekać",
                        "trainNow": "Trenuj teraz",
                        "uploadedDocuments": "Dokumenty treningowe",
                        "document": "Dokument",
                        "uploadedDate": "Data załadowania",
                        "noFilesUploaded": "Nie załadowano jeszcze żadnych dokumentów",
                        "deleteBot": "Usuń chatbota",
                        "confirmDelete": "Czy na pewno chcesz usunąć swojego chatbota {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Moje chatboty",
                        "newChatbot": "Nowy chatbot",
                        "chat": "Czat",
                        "edit": "Edytuj",
                        "name": "Nazwa",
                        "numTrainingDocs": "# Dokumenty treningowe",
                        "numQueries": "# Zapytania",
                        "trainingStatus": "Status",
                        "noChatbotCreated": "Nie utworzono jeszcze żadnych chatbotów.",
                        "delete": "Usuń"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Utwórz nowego chatbota",
                        "name": "Nazwa",
                        "description": "Opis",
                        "createBot": "Utwórz bota"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Do czego są dobre 'Tematy' w moim czacie?"
                        },
                        "topic": "Temat",
                        "myChatWith": "Mój czat z {{botName}}",
                        "initMessage": "Cześć, jestem {{botName}}. Wiem wszystko o {{description}}. Co chciałbyś wiedzieć?",
                        "initMessageCIS": "Cześć, jestem {{botName}}. Chciałbym dowiedzieć się więcej o twoich codziennych zadaniach, aby je udokumentować. Ma to na celu pomoc tobie i twoim kolegom w lepszej orientacji lub zastąpieniu cię.",
                        "foundTheseSources": "Więcej informacji tutaj:",
                        "userInput": "Twoje wejście",
                        "dontSharePersonalData": "Historia czatu jest przechowywana przez trzy miesiące. Proszę nie udostępniać danych osobowych."
                    },
                    "helperBot": {
                        "iCanHelp": "Mogę pomóc!",
                        "helpBotWelcome": "Cześć, jestem BotBucket. Jak mogę pomóc?"
                    },
                    "login": {
                        "login": "Zaloguj się",
                        "signUp": "Zarejestruj się",
                        "username": "Nazwa użytkownika",
                        "password": "Hasło",
                        "passwordRepeat": "Hasło (powtórz)",
                        "mail": "Email",
                        "navToLogin": "Zaloguj się",
                        "navToSignUp": "Zarejestruj się",
                        "usernameOrEmail": "Nazwa użytkownika lub Email",
                        "forgotPassword": "Zapomniałeś hasła?",
                        "resetPassword": "Zresetuj hasło",
                        "reset": "Reset",
                        "passwordResetted": "Hasło zresetowane",
                        "thanksWeSentResetMail": "Dziękujemy! Wysłaliśmy ci email z dalszymi instrukcjami.",
                        "passwordSuccessfullyResettedLoginAgain": "Twoje hasło zostało pomyślnie zresetowane. Możesz teraz zalogować się za pomocą nowego hasła.",
                        "helloPleaseChooseNewPassword": "Cześć, {{username}}! Proszę, ustaw nowe hasło!",
                        "newPassword": "Nowe hasło",
                        "newPasswordRepeat": "Nowe hasło (powtórz)",
                        "resetLinkNotValid": "Niestety, twój link jest nieważny lub wygasł. Proszę ponownie poproś o zresetowanie hasła.",
                        "or": "Lub",
                        "userNotFound": "Użytkownik nie znaleziony - proszę",
                        "userAlreadyTaken": "Nazwa użytkownika jest już zajęta - proszę"
                    },
                    "validations": {
                        "usernameMinChar": "Nazwa użytkownika musi mieć co najmniej 3 znaki",
                        "chatbotNameMinChar": "Nazwa chatbota musi mieć co najmniej 6 znaków",
                        "enterValidMail": "Email nie jest prawidłowy",
                        "passwordMinChar": "Hasło musi mieć co najmniej 8 znaków",
                        "passwordContainDigit": "Hasło musi zawierać cyfrę",
                        "passwordContainUppercase": "Hasło musi zawierać wielką literę",
                        "passwordContainLowercase": "Hasło musi zawierać małą literę",
                        "passwordSame": "Hasła muszą się zgadzać"
                    },
                    "prices": {
                        "price": "Cena",
                        "join": "Dołącz",
                        "susbcribe": "Subskrybuj",
                        "active": "Aktywny"
                    },
                    "profile": {
                        "updateProfileButton": "Zaktualizuj profil",
                        "noCustomer": "Nie utworzono żadnego klienta",
                        "pricesButton": "Przegląd produktów",
                        "createCustomer": "Zaktualizuj profil"
                    },
                    "overview": {
                        "free": {
                            "description1": "Maksymalna liczba stron: 15",
                            "description2": "Maksymalna liczba wyświetleń: 10",
                            "description3": "Więcej informacji..."
                        },
                        "basic": {
                            "description1": "Maksymalna liczba stron: 20",
                            "description2": "Maksymalna liczba wyświetleń: 50",
                            "description3": "Więcej informacji..."
                        },
                        "premium": {
                            "description1": "Maksymalna liczba stron: 50",
                            "description2": "Maksymalna liczba wyświetleń: 100",
                            "description3": "Więcej informacji..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Subskrypcja zakończona sukcesem!",
                            "updated": "Profil zaktualizowany!",
                            "cancel": "Wystąpił błąd!",
                            "created": "Klient utworzony pomyślnie!"
                        },
                        "invoice": "Zobacz fakturę: ",
                        "invoiceLink": "Faktura",
                        "button": "Wróć do menu!"
                    }
                }
            },

            // Russisch
            "ru": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Выберите файл или перетащите его сюда"
                    },
                    "common": {
                        "somethingWentWrong": "Упс, что-то пошло не так!",
                        "entriesPerPage": "Записей на странице",
                        "confirmPhrase": "Пожалуйста, введите <strong>{{confirmPhrase}}</strong> для подтверждения",
                        "confirm": "Подтвердить",
                        "cancel": "Отменить",
                        "copiedToClipboard": "Скопировано в буфер обмена!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Продукты",
                            "pricing": "Цены",
                            "aboutUs": "О нас",
                            "docs": "Документация",
                            "contact": "Контакт"
                        },
                        "hero0": "Ваш персонализированный ассистент чат-бот. Накормите его вашими руководствами, веб-сайтами и FAQ, чтобы автоматически, умно и точно отвечать на запросы клиентов. Удобное, интерактивное и многоязычное приложение SaaS для эффективной и персонализированной поддержки клиентов.",
                        "hero1": "Зарегистрироваться",
                        "section0": "Индивидуально обученный ассистент чат-бот для персонализированного взаимодействия с клиентами.",
                        "section1": "Разгрузка ваших специалистов благодаря AI, который берет на себя большинство запросов поддержки.",
                        "section2": "Оптимизация пользовательского опыта через контекстное обучение, интерактивное обучение и постоянное улучшение качества ответов.",
                        "section3": "Глобальный охват и гибкость благодаря поддержке множества языков, источников и типов документов.",
                        "section4": "Гибкий тарифный план доступен для всех наших услуг.",
                        "tech0": "Технология",
                        "tech1": "BotBucket использует технику NLP 'контекстного ответа на вопросы': предоставленная вами информация загружается в унифицированное хранилище, оптимизированное для обработки естественного языка (NLP). Это хранилище используется во время чата для нахождения релевантных блоков информации по запросу пользователя. Модель AI затем комбинирует эти блоки в осмысленный ответ.",
                        "tech2": "Узнать больше",
                        "aboutUs0": "О нас",
                        "aboutUs1": "Добро пожаловать в BotBucket! Здесь вы наполняете новейшие модели GPT вашими документами. Таким образом, вы создаете своего индивидуального ассистента чата за считанные секунды. С BotBucket вы связываете свои знания с возможностями современного естественного языка. Таким образом, BotBucket разгружает вашу поддержку клиентов, создает прозрачность и улучшает ваши процессы.",
                        "aboutUs2": "Контакт",
                        "contact0": "Свяжитесь с нами!",
                        "contact1": "Имя",
                        "contact2": "Электронная почта",
                        "contact3": "Тема",
                        "contact4": "Сообщение",
                        "contact5": "Отправить"
                    },
                    "userMenu": {
                        "login": "Войти",
                        "profile": "Профиль",
                        "settings": "Настройки",
                        "logout": "Выйти"
                    },
                    "dashboard": {
                        "welcome": "Добро пожаловать",
                        "myChatbots": "Мои чат-боты",
                        "newChatbot": "Новый чат-бот",
                        "instantChatbot": "Мгновенный чат-бот"
                    },
                    "chatbot": {
                        "myBot": "Мой бот - {{botName}}",
                        "settings": "Настройки",
                        "fileTopic": "Тема (через запятую)",
                        "topic": "Тема",
                        "includedInTraining": "Этот файл был включен в последнее обучение и теперь известен чат-боту.",
                        "notIncludedInTraining": "Этот файл еще не был включен. Тренируйтесь сейчас, чтобы включить этот файл.",
                        "trainingStatus": {
                            "notTrained": "Еще не обучен",
                            "pending": "Скоро начнется обучение",
                            "training": "Обучение в процессе",
                            "trained": "Обучение успешно завершено",
                            "failed": "Обучение не удалось",
                            "label": "Статус обучения"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Настройте своего чат-бота!",
                            "publicAvailability": "Доступность для всех",
                            "publicAvailable": "Ваш чат-бот виден всем. Вы можете поделиться им по ссылке ниже.",
                            "notPublicAvailable": "Только вы можете видеть своего чат-бота. Используйте переключатель, чтобы сделать его видимым для всех.",
                            "publicLink": "Публичная ссылка",
                            "deleteYourBot": "Удалить вашего чат-бота",
                            allowUsers: 'Разрешить пользователям',
                            searchUser: 'Поиск пользователя',
                            addUser: 'Добавить пользователя',
                            allowedUsers: 'Разрешенные пользователи',
                            username: 'Имя пользователя',
                            email: 'Электронная почта',
                            enterEmail: 'Введите адрес электронной почты пользователя, которому вы хотите предоставить доступ',
                            actions: 'Действия',
                            userAdded: 'Пользователь добавлен',
                            userRemoved: 'Пользователь удален',
                            noUsersAllowed: 'Нет разрешенных пользователей',
                            removeUser: 'Удалить пользователя',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Добавить документы в чат-бота",
                            "uploadingFiles": "Загрузка документов...",
                            "filesStillUploading": "Некоторые документы еще не полностью загружены, пожалуйста, подождите",
                            "submit": "Подтвердить"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Обучите своего чат-бота",
                            "embeddings": "Встраивания",
                            "startTraining": "Начать обучение"
                        },
                        "botNotTrainedNoTrainingPossible": "Бот не обучен - на данный момент чат невозможен",
                        "chat": "Чат",
                        "botTraining": "Бот в процессе обучения - пожалуйста, подождите",
                        "trainNow": "Обучить сейчас",
                        "uploadedDocuments": "Документы для обучения",
                        "document": "Документ",
                        "uploadedDate": "Дата загрузки",
                        "noFilesUploaded": "Документы еще не загружены",
                        "deleteBot": "Удалить чат-бота",
                        "confirmDelete": "Вы действительно хотите удалить своего чат-бота {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Мои чат-боты",
                        "newChatbot": "Новый чат-бот",
                        "chat": "Чат",
                        "edit": "Редактировать",
                        "name": "Имя",
                        "numTrainingDocs": "# Документы для обучения",
                        "numQueries": "# Запросы",
                        "trainingStatus": "Статус",
                        "noChatbotCreated": "Чат-боты еще не созданы.",
                        "delete": "Удалить"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Создать нового чат-бота",
                        "name": "Имя",
                        "description": "Описание",
                        "createBot": "Создать бота"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Для чего хороши 'Темы' в моем чате?"
                        },
                        "topic": "Тема",
                        "myChatWith": "Мой чат с {{botName}}",
                        "initMessage": "Привет, я {{botName}}. Я знаю все о {{description}}. Что вы хотите узнать?",
                        "initMessageCIS": "Привет, я {{botName}}. Я хочу узнать больше о ваших ежедневных задачах, чтобы задокументировать их. Это должно помочь вам и вашим коллегам лучше ориентироваться или заменять вас.",
                        "foundTheseSources": "Дополнительная информация здесь:",
                        "userInput": "Ваш ввод",
                        "dontSharePersonalData": "История чата хранится в течение трех месяцев. Пожалуйста, не делитесь персональными данными."
                    },
                    "helperBot": {
                        "iCanHelp": "Я могу помочь!",
                        "helpBotWelcome": "Привет, я BotBucket. Как я могу помочь?"
                    },
                    "login": {
                        "login": "Войти",
                        "signUp": "Зарегистрироваться",
                        "username": "Имя пользователя",
                        "password": "Пароль",
                        "passwordRepeat": "Пароль (повторно)",
                        "mail": "Электронная почта",
                        "navToLogin": "Войти",
                        "navToSignUp": "Зарегистрироваться",
                        "usernameOrEmail": "Имя пользователя или электронная почта",
                        "forgotPassword": "Забыли пароль?",
                        "resetPassword": "Сбросить пароль",
                        "reset": "Сброс",
                        "passwordResetted": "Пароль сброшен",
                        "thanksWeSentResetMail": "Спасибо! Мы отправили вам электронное письмо с дальнейшими инструкциями.",
                        "passwordSuccessfullyResettedLoginAgain": "Ваш пароль успешно сброшен. Теперь вы можете войти, используя новый пароль.",
                        "helloPleaseChooseNewPassword": "Привет, {{username}}! Пожалуйста, установите новый пароль!",
                        "newPassword": "Новый пароль",
                        "newPasswordRepeat": "Новый пароль (повторно)",
                        "resetLinkNotValid": "К сожалению, ваша ссылка недействительна или истекла. Пожалуйста, запросите сброс пароля снова.",
                        "or": "Или",
                        "userNotFound": "Пользователь не найден - пожалуйста",
                        "userAlreadyTaken": "Имя пользователя уже занято - пожалуйста"
                    },
                    "validations": {
                        "usernameMinChar": "Имя пользователя должно содержать не менее 3 символов",
                        "chatbotNameMinChar": "Имя чат-бота должно содержать не менее 6 символов",
                        "enterValidMail": "Электронная почта недействительна",
                        "passwordMinChar": "Пароль должен содержать не менее 8 символов",
                        "passwordContainDigit": "Пароль должен содержать цифру",
                        "passwordContainUppercase": "Пароль должен содержать заглавную букву",
                        "passwordContainLowercase": "Пароль должен содержать строчную букву",
                        "passwordSame": "Пароли должны совпадать"
                    },
                    "prices": {
                        "price": "Цена",
                        "join": "Присоединиться",
                        "susbcribe": "Подписаться",
                        "active": "Активный"
                    },
                    "profile": {
                        "updateProfileButton": "Обновить профиль",
                        "noCustomer": "Клиент не создан",
                        "pricesButton": "Обзор продуктов",
                        "createCustomer": "Обновить профиль"
                    },
                    "overview": {
                        "free": {
                            "description1": "Максимальное количество страниц: 15",
                            "description2": "Максимальное количество просмотров: 10",
                            "description3": "Дополнительная информация..."
                        },
                        "basic": {
                            "description1": "Максимальное количество страниц: 20",
                            "description2": "Максимальное количество просмотров: 50",
                            "description3": "Дополнительная информация..."
                        },
                        "premium": {
                            "description1": "Максимальное количество страниц: 50",
                            "description2": "Максимальное количество просмотров: 100",
                            "description3": "Дополнительная информация..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Подписка успешна!",
                            "updated": "Профиль обновлен!",
                            "cancel": "Произошла ошибка!",
                            "created": "Клиент успешно создан!"
                        },
                        "invoice": "Посмотреть счет: ",
                        "invoiceLink": "Счет",
                        "button": "Вернуться в меню!"
                    }
                }
            },

            // Mazedonisch
            "mk": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Изберете датотека или повлечете ја тука"
                    },
                    "common": {
                        "somethingWentWrong": "Упс, нешто тргна наопаку!",
                        "entriesPerPage": "Записи по страница",
                        "confirmPhrase": "Ве молиме внесете <strong>{{confirmPhrase}}</strong> за потврда",
                        "confirm": "Потврди",
                        "cancel": "Откажи",
                        "copiedToClipboard": "Копирано во меѓупростор!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Производи",
                            "pricing": "Цени",
                            "aboutUs": "За нас",
                            "docs": "Документација",
                            "contact": "Контакт"
                        },
                        "hero0": "Вашиот персонализиран асистент чатбот. Наполнете го со вашите прирачници, веб-страници и ЧПП, за автоматски, интелигентно и прецизно одговарање на прашањата на клиентите. Корисно, интерактивно и повеќејазично софтверско решение како услуга за ефикасна и персонализирана поддршка на клиенти.",
                        "hero1": "Регистрирај се",
                        "section0": "Индивидуално обучен асистент чатбот за персонализирана интеракција со клиенти.",
                        "section1": "Ослободување на вашите експерти преку AI-поддржано преземање на поголемиот дел од поддршките прашања.",
                        "section2": "Оптимизирано корисничко искуство преку контекстуално учење, интерактивна обука и континуирано подобрување на квалитетот на одговорите.",
                        "section3": "Глобален дофат и флексибилност преку поддршка на многу јазици, извори и типови на документи.",
                        "section4": "Флексибилен план за плаќање е достапен за сите наши услуги.",
                        "tech0": "Технологија",
                        "tech1": "BotBucket користи NLP-техника за 'контекстуално одговарање на прашања': Информациите што ги давате се вчитуваат во унифициран складиште оптимизирано за обработка на природен јазик (NLP). Овој складиште се користи за време на разговорот за наоѓање на релевантни блокови на информации во врска со прашањето на корисникот. Модел на AI потоа ги комбинира овие блокови во смислен одговор.",
                        "tech2": "Дознај повеќе",
                        "aboutUs0": "За нас",
                        "aboutUs1": "Добредојдовте во BotBucket! Тука ги полните најновите GPT-модели со вашите документи. Така креирате свој персонализиран асистент чат во секунди. Со BotBucket го поврзувате вашето знаење со можностите на современата обработка на природен јазик. Така BotBucket ја олеснува вашата поддршка на клиенти, создава транспарентност и ги подобрува вашите процеси.",
                        "aboutUs2": "Контакт",
                        "contact0": "Контактирајте не!",
                        "contact1": "Име",
                        "contact2": "Е-пошта",
                        "contact3": "Предмет",
                        "contact4": "Порака",
                        "contact5": "Испрати"
                    },
                    "userMenu": {
                        "login": "Најави се",
                        "profile": "Профил",
                        "settings": "Поставки",
                        "logout": "Одјави се"
                    },
                    "dashboard": {
                        "welcome": "Добредојдовте",
                        "myChatbots": "Мои чатботи",
                        "newChatbot": "Нов чатбот",
                        "instantChatbot": "Инстант чатбот"
                    },
                    "chatbot": {
                        "myBot": "Мојот бот - {{botName}}",
                        "settings": "Поставки",
                        "fileTopic": "Тема (одделено со запирка)",
                        "topic": "Тема",
                        "includedInTraining": "Оваа датотека беше вклучена во последната обука и сега е позната на чатботот.",
                        "notIncludedInTraining": "Оваа датотека сè уште не е вклучена. Тренирајте сега за да ја вклучите оваа датотека.",
                        "trainingStatus": {
                            "notTrained": "Сè уште не е обучен",
                            "pending": "Обуката наскоро ќе започне",
                            "training": "Обуката е во тек",
                            "trained": "Обуката беше успешна",
                            "failed": "Обуката не успеа",
                            "label": "Статус на обука"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Прилагодете го вашиот чатбот!",
                            "publicAvailability": "Јавна достапност",
                            "publicAvailable": "Вашиот чатбот е видлив за сите. Можете да го споделите со линкот подолу.",
                            "notPublicAvailable": "Само вие можете да го видите вашиот чатбот. Користете го прекинувачот за да го направите видлив за сите.",
                            "publicLink": "Јавна врска",
                            "deleteYourBot": "Избришете го вашиот чатбот",
                            allowUsers: 'Дозволи корисници',
                            searchUser: 'Пребарај корисник',
                            addUser: 'Додај корисник',
                            allowedUsers: 'Дозволени корисници',
                            username: 'Корисничко име',
                            email: 'Е-пошта',
                            enterEmail: 'Внесете го е-маилот на корисникот на кој сакате да му овозможите пристап',
                            actions: 'Акции',
                            userAdded: 'Корисникот е додаден',
                            userRemoved: 'Корисникот е отстранет',
                            noUsersAllowed: 'Нема дозволени корисници',
                            removeUser: 'Отстрани корисник',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Додадете документи во чатбот",
                            "uploadingFiles": "Вчитување на документи...",
                            "filesStillUploading": "Некои документи сè уште не се целосно вчитани, ве молиме почекајте",
                            "submit": "Потврди"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Обучете го вашиот чатбот",
                            "embeddings": "Вградени",
                            "startTraining": "Започнете обука"
                        },
                        "botNotTrainedNoTrainingPossible": "Ботот не е обучен - моментално не е можен чат",
                        "chat": "Чат",
                        "botTraining": "Ботот е во процес на обука - ве молиме почекајте",
                        "trainNow": "Обучете сега",
                        "uploadedDocuments": "Документи за обука",
                        "document": "Документ",
                        "uploadedDate": "Датум на вчитување",
                        "noFilesUploaded": "Сè уште не се вчитани документи",
                        "deleteBot": "Избриши го чатбот",
                        "confirmDelete": "Дали навистина сакате да го избришете вашиот чатбот {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Мои чатботи",
                        "newChatbot": "Нов чатбот",
                        "chat": "Чат",
                        "edit": "Уреди",
                        "name": "Име",
                        "numTrainingDocs": "# Документи за обука",
                        "numQueries": "# Прашања",
                        "trainingStatus": "Статус",
                        "noChatbotCreated": "Сè уште не се создадени чатботи.",
                        "delete": "Избриши"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Создај нов чатбот",
                        "name": "Име",
                        "description": "Опис",
                        "createBot": "Создај бот"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "За што се добри 'Темите' во мојот чат?"
                        },
                        "topic": "Тема",
                        "myChatWith": "Мојот чат со {{botName}}",
                        "initMessage": "Здраво, јас сум {{botName}}. Знам сè за {{description}}. Што сакате да знаете?",
                        "initMessageCIS": "Здраво, јас сум {{botName}}. Сакам да дознаам повеќе за вашите дневни задачи за да ги документирам. Ова е наменето да ви помогне вам и на вашите колеги подобро да се ориентирате или да ве заменат.",
                        "foundTheseSources": "Повеќе информации тука:",
                        "userInput": "Вашиот внес",
                        "dontSharePersonalData": "Историјата на чатот се чува три месеци. Ве молиме не споделувајте лични податоци."
                    },
                    "helperBot": {
                        "iCanHelp": "Можам да помогнам!",
                        "helpBotWelcome": "Здраво, јас сум BotBucket. Како можам да помогнам?"
                    },
                    "login": {
                        "login": "Најави се",
                        "signUp": "Регистрирај се",
                        "username": "Корисничко име",
                        "password": "Лозинка",
                        "passwordRepeat": "Лозинка (повторно)",
                        "mail": "Е-пошта",
                        "navToLogin": "Најави се",
                        "navToSignUp": "Регистрирај се",
                        "usernameOrEmail": "Корисничко име или е-пошта",
                        "forgotPassword": "Заборавена лозинка?",
                        "resetPassword": "Ресетирај лозинка",
                        "reset": "Ресетирај",
                        "passwordResetted": "Лозинката е ресетирана",
                        "thanksWeSentResetMail": "Ви благодариме! Ви испративме е-пошта со понатамошни упатства.",
                        "passwordSuccessfullyResettedLoginAgain": "Вашата лозинка е успешно ресетирана. Сега можете да се најавите со вашата нова лозинка.",
                        "helloPleaseChooseNewPassword": "Здраво, {{username}}! Ве молиме поставете нова лозинка!",
                        "newPassword": "Нова лозинка",
                        "newPasswordRepeat": "Нова лозинка (повторно)",
                        "resetLinkNotValid": "Вашиот линк е невалиден или истечен. Ве молиме побарајте повторно ресетирање на лозинката.",
                        "or": "Или",
                        "userNotFound": "Корисникот не е пронајден - ве молиме",
                        "userAlreadyTaken": "Корисничкото име веќе е зафатено - ве молиме"
                    },
                    "validations": {
                        "usernameMinChar": "Корисничкото име мора да има најмалку 3 карактери",
                        "chatbotNameMinChar": "Името на чатботот мора да има најмалку 6 карактери",
                        "enterValidMail": "Е-поштата не е валидна",
                        "passwordMinChar": "Лозинката мора да има најмалку 8 карактери",
                        "passwordContainDigit": "Лозинката мора да содржи број",
                        "passwordContainUppercase": "Лозинката мора да содржи голема буква",
                        "passwordContainLowercase": "Лозинката мора да содржи мала буква",
                        "passwordSame": "Лозинките мора да се совпаѓаат"
                    },
                    "prices": {
                        "price": "Цена",
                        "join": "Придружи се",
                        "susbcribe": "Претплати се",
                        "active": "Активно"
                    },
                    "profile": {
                        "updateProfileButton": "Ажурирај профил",
                        "noCustomer": "Нема создадено клиент",
                        "pricesButton": "Преглед на производи",
                        "createCustomer": "Ажурирај профил"
                    },
                    "overview": {
                        "free": {
                            "description1": "Максимален број на страници: 15",
                            "description2": "Максимален број на прегледи: 10",
                            "description3": "Повеќе информации..."
                        },
                        "basic": {
                            "description1": "Максимален број на страници: 20",
                            "description2": "Максимален број на прегледи: 50",
                            "description3": "Повеќе информации..."
                        },
                        "premium": {
                            "description1": "Максимален број на страници: 50",
                            "description2": "Максимален број на прегледи: 100",
                            "description3": "Повеќе информации..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Претплатата е успешна!",
                            "updated": "Профилот е ажуриран!",
                            "cancel": "Се случи грешка!",
                            "created": "Клиентот е успешно создаден!"
                        },
                        "invoice": "Прегледај фактура: ",
                        "invoiceLink": "Фактура",
                        "button": "Назад во мени!"
                    }
                }
            },

            // Spanisch
            "es": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Seleccionar archivo o arrastrar aquí"
                    },
                    "common": {
                        "somethingWentWrong": "¡Vaya, algo salió mal!",
                        "entriesPerPage": "Entradas por página",
                        "confirmPhrase": "Por favor, ingrese <strong>{{confirmPhrase}}</strong> para confirmar",
                        "confirm": "Confirmar",
                        "cancel": "Cancelar",
                        "copiedToClipboard": "¡Copiado al portapapeles!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Productos",
                            "pricing": "Precios",
                            "aboutUs": "Sobre nosotros",
                            "docs": "Documentación",
                            "contact": "Contacto"
                        },
                        "hero0": "Su asistente de chatbot personalizado. Aliméntelo con sus manuales, sitios web y preguntas frecuentes para responder automáticamente, de manera inteligente y precisa, a las consultas de los clientes. Una aplicación de software como servicio, fácil de usar, interactiva y multilingüe, para una atención al cliente eficiente y personalizada.",
                        "hero1": "Registrarse",
                        "section0": "Asistente de chatbot entrenado individualmente para una interacción personalizada con el cliente.",
                        "section1": "Desahogo de sus expertos mediante la asunción de la mayoría de las consultas de soporte impulsadas por IA.",
                        "section2": "Experiencia de usuario optimizada mediante aprendizaje contextual, entrenamiento interactivo y mejora continua de la calidad de las respuestas.",
                        "section3": "Alcance global y flexibilidad mediante el soporte de múltiples idiomas, fuentes y tipos de documentos.",
                        "section4": "Un plan de pago flexible está disponible para todos nuestros servicios.",
                        "tech0": "Tecnología",
                        "tech1": "BotBucket utiliza la técnica de NLP de 'respuesta a preguntas contextuales': la información que proporciona se carga en un almacenamiento unificado, optimizado para el procesamiento de lenguaje natural (NLP). Este almacenamiento se utiliza durante el chat para encontrar bloques de información relevantes en relación con la pregunta del usuario. Un modelo de IA luego combina estos bloques en una respuesta coherente.",
                        "tech2": "Más información",
                        "aboutUs0": "Sobre nosotros",
                        "aboutUs1": "¡Bienvenido a BotBucket! Aquí alimentas los últimos modelos GPT con tus documentos. Así, creas tu asistente de chat personalizado en segundos. Con BotBucket, conectas tu tesoro de conocimientos con las posibilidades de procesamiento de lenguaje natural más avanzadas. De esta manera, BotBucket alivia tu atención al cliente, crea transparencia y mejora tus procesos.",
                        "aboutUs2": "Contacto",
                        "contact0": "¡Contáctenos!",
                        "contact1": "Nombre",
                        "contact2": "Correo electrónico",
                        "contact3": "Asunto",
                        "contact4": "Mensaje",
                        "contact5": "Enviar"
                    },
                    "userMenu": {
                        "login": "Iniciar sesión",
                        "profile": "Perfil",
                        "settings": "Configuraciones",
                        "logout": "Cerrar sesión"
                    },
                    "dashboard": {
                        "welcome": "Bienvenido",
                        "myChatbots": "Mis chatbots",
                        "newChatbot": "Nuevo chatbot",
                        "instantChatbot": "Chatbot instantáneo"
                    },
                    "chatbot": {
                        "myBot": "Mi bot - {{botName}}",
                        "settings": "Configuraciones",
                        "fileTopic": "Tema (separado por comas)",
                        "topic": "Tema",
                        "includedInTraining": "Este archivo fue incluido en el último entrenamiento y ahora es conocido por el chatbot.",
                        "notIncludedInTraining": "Este archivo aún no ha sido considerado. Entrena ahora para incluir este archivo.",
                        "trainingStatus": {
                            "notTrained": "Aún no entrenado",
                            "pending": "Entrenamiento comenzará pronto",
                            "training": "Entrenando",
                            "trained": "Entrenamiento exitoso",
                            "failed": "Entrenamiento fallido",
                            "label": "Estado del entrenamiento"
                        },
                        "settingsModal": {
                            "customizeYourBot": "¡Personaliza tu chatbot!",
                            "publicAvailability": "Disponibilidad pública",
                            "publicAvailable": "Tu chatbot es visible para todos. Puedes compartirlo con el enlace de abajo.",
                            "notPublicAvailable": "Solo tú puedes ver tu chatbot. Usa el interruptor para hacerlo visible para todos.",
                            "publicLink": "Enlace público",
                            "deleteYourBot": "Eliminar tu chatbot",
                            allowUsers: 'Permitir usuarios',
                            searchUser: 'Buscar usuario',
                            addUser: 'Agregar usuario',
                            allowedUsers: 'Usuarios permitidos',
                            username: 'Nombre de usuario',
                            email: 'Correo electrónico',
                            enterEmail: 'Ingresa el correo electrónico del usuario al que deseas dar acceso',
                            actions: 'Acciones',
                            userAdded: 'Usuario agregado',
                            userRemoved: 'Usuario eliminado',
                            noUsersAllowed: 'No se permiten usuarios',
                            removeUser: 'Eliminar usuario',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Agregar documentos al chatbot",
                            "uploadingFiles": "Subiendo documentos...",
                            "filesStillUploading": "Algunos documentos aún se están subiendo, por favor espera",
                            "submit": "Confirmar"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Entrena tu chatbot",
                            "embeddings": "Embeddings",
                            "startTraining": "Iniciar entrenamiento"
                        },
                        "botNotTrainedNoTrainingPossible": "El bot no está entrenado - actualmente no se puede chatear",
                        "chat": "Chat",
                        "botTraining": "El bot está en entrenamiento - por favor espera",
                        "trainNow": "Entrenar ahora",
                        "uploadedDocuments": "Documentos de entrenamiento",
                        "document": "Documento",
                        "uploadedDate": "Fecha de subida",
                        "noFilesUploaded": "Aún no se han subido documentos",
                        "deleteBot": "Eliminar chatbot",
                        "confirmDelete": "¿Realmente deseas eliminar tu chatbot {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "Mis chatbots",
                        "newChatbot": "Nuevo chatbot",
                        "chat": "Chatear",
                        "edit": "Editar",
                        "name": "Nombre",
                        "numTrainingDocs": "# Documentos de entrenamiento",
                        "numQueries": "# Consultas",
                        "trainingStatus": "Estado",
                        "noChatbotCreated": "Aún no hay chatbots creados.",
                        "delete": "Eliminar"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Crear nuevo chatbot",
                        "name": "Nombre",
                        "description": "Descripción",
                        "createBot": "Crear bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "¿Para qué sirven los 'temas' en mi chat?"
                        },
                        "topic": "Tema",
                        "myChatWith": "Mi chat con {{botName}}",
                        "initMessage": "Hola, soy {{botName}}. Sé todo sobre {{description}}. ¿Qué te gustaría saber?",
                        "initMessageCIS": "Hola, soy {{botName}}. Me gustaría saber más sobre tus tareas diarias para documentarlas. Esto está destinado a ayudarte a ti y a tus colegas a orientarse mejor o a cubrirte.",
                        "foundTheseSources": "Más información aquí:",
                        "userInput": "Tu entrada",
                        "dontSharePersonalData": "El historial de chat se guarda durante tres meses. Por favor, no compartas datos personales."
                    },
                    "helperBot": {
                        "iCanHelp": "¡Puedo ayudarte!",
                        "helpBotWelcome": "Hola, soy BotBucket. ¿Cómo puedo ayudar?"
                    },
                    "login": {
                        "login": "Iniciar sesión",
                        "signUp": "Registrarse",
                        "username": "Nombre de usuario",
                        "password": "Contraseña",
                        "passwordRepeat": "Repetir contraseña",
                        "mail": "Correo electrónico",
                        "navToLogin": "Iniciar sesión",
                        "navToSignUp": "Registrarse",
                        "usernameOrEmail": "Nombre de usuario o correo electrónico",
                        "forgotPassword": "¿Olvidaste tu contraseña?",
                        "resetPassword": "Restablecer contraseña",
                        "reset": "Restablecer",
                        "passwordResetted": "Contraseña restablecida",
                        "thanksWeSentResetMail": "¡Gracias! Te hemos enviado un correo electrónico con más instrucciones.",
                        "passwordSuccessfullyResettedLoginAgain": "Tu contraseña ha sido restablecida con éxito. Ahora puedes iniciar sesión con tu nueva contraseña.",
                        "helloPleaseChooseNewPassword": "Hola, {{username}}! Por favor, establece tu nueva contraseña!",
                        "newPassword": "Nueva contraseña",
                        "newPasswordRepeat": "Repetir nueva contraseña",
                        "resetLinkNotValid": "Lamentablemente, tu enlace no es válido o ha expirado. Por favor, solicita restablecer tu contraseña nuevamente.",
                        "or": "O",
                        "userNotFound": "Usuario no encontrado - por favor",
                        "userAlreadyTaken": "Nombre de usuario ya tomado - por favor"
                    },
                    "validations": {
                        "usernameMinChar": "El nombre de usuario debe tener al menos 3 caracteres",
                        "chatbotNameMinChar": "El nombre del chatbot debe tener al menos 6 caracteres",
                        "enterValidMail": "Correo electrónico no válido",
                        "passwordMinChar": "La contraseña debe tener al menos 8 caracteres",
                        "passwordContainDigit": "La contraseña debe contener un número",
                        "passwordContainUppercase": "La contraseña debe contener una letra mayúscula",
                        "passwordContainLowercase": "La contraseña debe contener una letra minúscula",
                        "passwordSame": "Las contraseñas deben coincidir"
                    },
                    "prices": {
                        "price": "Precio",
                        "join": "Unirse",
                        "susbcribe": "Suscribirse",
                        "active": "Activo"
                    },
                    "profile": {
                        "updateProfileButton": "Actualizar perfil",
                        "noCustomer": "No se ha creado ningún cliente",
                        "pricesButton": "Ver productos",
                        "createCustomer": "Actualizar perfil"
                    },
                    "overview": {
                        "free": {
                            "description1": "Número máximo de páginas: 15",
                            "description2": "Máximo de visitas: 10",
                            "description3": "Más información..."
                        },
                        "basic": {
                            "description1": "Número máximo de páginas: 20",
                            "description2": "Máximo de visitas: 50",
                            "description3": "Más información..."
                        },
                        "premium": {
                            "description1": "Número máximo de páginas: 50",
                            "description2": "Máximo de visitas: 100",
                            "description3": "Más información..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "¡Suscripción exitosa!",
                            "updated": "¡Perfil actualizado!",
                            "cancel": "¡Ha ocurrido un error!",
                            "created": "¡Cliente creado con éxito!"
                        },
                        "invoice": "Ver factura: ",
                        "invoiceLink": "Factura",
                        "button": "¡Volver al menú!"
                    }
                }
            },

            // Französisch
            "fr": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Choisir un fichier ou le glisser ici"
                    },
                    "common": {
                        "somethingWentWrong": "Oups, quelque chose a mal tourné !",
                        "entriesPerPage": "Entrées par page",
                        "confirmPhrase": "Veuillez entrer <strong>{{confirmPhrase}}</strong> pour confirmer",
                        "confirm": "Confirmer",
                        "cancel": "Annuler",
                        "copiedToClipboard": "Ajouté au presse-papiers !"
                    },
                    "homepage": {
                        "header": {
                            "products": "Produits",
                            "pricing": "Tarifs",
                            "aboutUs": "À propos de nous",
                            "docs": "Docs",
                            "contact": "Contact"
                        },
                        "hero0": "Votre assistant chatbot personnalisé. Nourrissez-le avec vos manuels, sites web et FAQ pour répondre automatiquement, intelligemment et précisément aux demandes des clients. Une application SaaS conviviale, interactive et multilingue pour un support client efficace et personnalisé.",
                        "hero1": "S'inscrire",
                        "section0": "Assistant chatbot entraîné individuellement pour une interaction client personnalisée.",
                        "section1": "Soulagement de vos experts grâce à la prise en charge par l'IA de la plupart des demandes de support.",
                        "section2": "Expérience utilisateur optimisée grâce à l'apprentissage contextuel, à la formation interactive et à l'amélioration continue de la qualité des réponses.",
                        "section3": "Portée mondiale et flexibilité grâce au support de nombreuses langues, sources et types de documents.",
                        "section4": "Un plan de paiement flexible est disponible pour tous nos services.",
                        "tech0": "Technologie",
                        "tech1": "BotBucket utilise la technique NLP de 'réponse aux questions contextuelles' : les informations que vous fournissez sont chargées dans un stockage unifié, optimisé pour le traitement du langage naturel (NLP). Ce stockage est utilisé pendant le chat pour trouver des blocs d'informations pertinents en relation avec la question de l'utilisateur. Un modèle d'IA combine ensuite ces blocs en une réponse cohérente.",
                        "tech2": "En savoir plus",
                        "aboutUs0": "À propos de nous",
                        "aboutUs1": "Bienvenue chez BotBucket ! Ici, vous nourrissez les derniers modèles GPT avec vos documents. Ainsi, vous créez votre assistant de chat personnalisé en quelques secondes. Avec BotBucket, vous connectez votre trésor de connaissances avec les possibilités de traitement du langage naturel les plus modernes. Ainsi, BotBucket soulage votre support client, crée de la transparence et améliore vos processus.",
                        "aboutUs2": "Contact",
                        "contact0": "Contactez-nous !",
                        "contact1": "Nom",
                        "contact2": "Email",
                        "contact3": "Sujet",
                        "contact4": "Message",
                        "contact5": "Envoyer"
                    },
                    "userMenu": {
                        "login": "Connexion",
                        "profile": "Profil",
                        "settings": "Paramètres",
                        "logout": "Déconnexion"
                    },
                    "dashboard": {
                        "welcome": "Bienvenue",
                        "myChatbots": "Mes chatbots",
                        "newChatbot": "Nouveau chatbot",
                        "instantChatbot": "Chatbot instantané"
                    },
                    "chatbot": {
                        "myBot": "Mon bot - {{botName}}",
                        "settings": "Paramètres",
                        "fileTopic": "Thème (séparé par des virgules)",
                        "topic": "Thème",
                        "includedInTraining": "Ce fichier a été inclus dans le dernier entraînement et est maintenant connu du chatbot.",
                        "notIncludedInTraining": "Ce fichier n'a pas encore été pris en compte. Entraînez-le maintenant pour inclure ce fichier.",
                        "trainingStatus": {
                            "notTrained": "Pas encore entraîné",
                            "pending": "L'entraînement va bientôt commencer",
                            "training": "Entraînement en cours",
                            "trained": "Entraînement réussi",
                            "failed": "Échec de l'entraînement",
                            "label": "Statut de l'entraînement"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Personnalisez votre chatbot !",
                            "publicAvailability": "Disponibilité publique",
                            "publicAvailable": "Votre chatbot est visible par tous. Vous pouvez le partager avec le lien ci-dessous.",
                            "notPublicAvailable": "Seul vous pouvez voir votre chatbot. Utilisez le basculeur pour le rendre visible par tous.",
                            "publicLink": "Lien public",
                            "deleteYourBot": "Supprimer votre chatbot",
                            allowUsers: 'Autoriser les utilisateurs',
                            searchUser: 'Rechercher un utilisateur',
                            addUser: 'Ajouter un utilisateur',
                            allowedUsers: 'Utilisateurs autorisés',
                            username: 'Nom d\'utilisateur',
                            email: 'Email',
                            enterEmail: 'Entrez l\'email de l\'utilisateur auquel vous souhaitez donner accès',
                            actions: 'Actions',
                            userAdded: 'Utilisateur ajouté',
                            userRemoved: 'Utilisateur supprimé',
                            noUsersAllowed: 'Aucun utilisateur autorisé',
                            removeUser: 'Supprimer l\'utilisateur',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Ajouter des documents au chatbot",
                            "uploadingFiles": "Téléchargement des documents...",
                            "filesStillUploading": "Certains documents ne sont pas encore complètement téléchargés, veuillez patienter",
                            "submit": "Confirmer"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Entraînez votre chatbot",
                            "embeddings": "Embeddings",
                            "startTraining": "Commencer l'entraînement"
                        },
                        "botNotTrainedNoTrainingPossible": "Le bot n'est pas entraîné - actuellement aucun chat possible",
                        "chat": "Chat",
                        "botTraining": "Le bot est actuellement en entraînement - veuillez patienter",
                        "trainNow": "Entraînez maintenant",
                        "uploadedDocuments": "Documents d'entraînement",
                        "document": "Document",
                        "uploadedDate": "Date de téléchargement",
                        "noFilesUploaded": "Aucun document téléchargé",
                        "deleteBot": "Supprimer le chatbot",
                        "confirmDelete": "Voulez-vous vraiment supprimer votre chatbot {{botName}} ?"
                    },
                    "chatbots": {
                        "myChatbots": "Mes chatbots",
                        "newChatbot": "Nouveau chatbot",
                        "chat": "Discuter",
                        "edit": "Éditer",
                        "name": "Nom",
                        "numTrainingDocs": "# Documents d'entraînement",
                        "numQueries": "# Requêtes",
                        "trainingStatus": "Statut",
                        "noChatbotCreated": "Aucun chatbot créé.",
                        "delete": "Supprimer"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Créer un nouveau chatbot",
                        "name": "Nom",
                        "description": "Description",
                        "createBot": "Créer un bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "À quoi servent les 'sujets' dans mon chat ?"
                        },
                        "topic": "Sujet",
                        "myChatWith": "Mon chat avec {{botName}}",
                        "initMessage": "Salut, je suis {{botName}}. Je sais tout sur {{description}}. Que voulez-vous savoir ?",
                        "initMessageCIS": "Salut, je suis {{botName}}. Je veux en savoir plus sur vos tâches quotidiennes pour les documenter. Cela est destiné à vous aider, vous et vos collègues, à mieux vous orienter ou à vous remplacer.",
                        "foundTheseSources": "Plus d'informations ici :",
                        "userInput": "Votre entrée",
                        "dontSharePersonalData": "L'historique des chats est conservé pendant trois mois. Veuillez ne pas partager de données personnelles."
                    },
                    "helperBot": {
                        "iCanHelp": "Je peux vous aider !",
                        "helpBotWelcome": "Salut, je suis BotBucket. Comment puis-je aider ?"
                    },
                    "login": {
                        "login": "Connexion",
                        "signUp": "S'inscrire",
                        "username": "Nom d'utilisateur",
                        "password": "Mot de passe",
                        "passwordRepeat": "Répéter le mot de passe",
                        "mail": "Email",
                        "navToLogin": "Connexion",
                        "navToSignUp": "S'inscrire",
                        "usernameOrEmail": "Nom d'utilisateur ou email",
                        "forgotPassword": "Mot de passe oublié ?",
                        "resetPassword": "Réinitialiser le mot de passe",
                        "reset": "Réinitialiser",
                        "passwordResetted": "Mot de passe réinitialisé",
                        "thanksWeSentResetMail": "Merci ! Nous vous avons envoyé un email avec des instructions supplémentaires.",
                        "passwordSuccessfullyResettedLoginAgain": "Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.",
                        "helloPleaseChooseNewPassword": "Salut, {{username}} ! Veuillez définir votre nouveau mot de passe !",
                        "newPassword": "Nouveau mot de passe",
                        "newPasswordRepeat": "Répéter le nouveau mot de passe",
                        "resetLinkNotValid": "Malheureusement, votre lien n'est pas valide ou a expiré. Veuillez demander à nouveau la réinitialisation de votre mot de passe.",
                        "or": "Ou",
                        "userNotFound": "Utilisateur non trouvé - veuillez",
                        "userAlreadyTaken": "Nom d'utilisateur déjà pris - veuillez"
                    },
                    "validations": {
                        "usernameMinChar": "Le nom d'utilisateur doit comporter au moins 3 caractères",
                        "chatbotNameMinChar": "Le nom du chatbot doit comporter au moins 6 caractères",
                        "enterValidMail": "L'email n'est pas valide",
                        "passwordMinChar": "Le mot de passe doit comporter au moins 8 caractères",
                        "passwordContainDigit": "Le mot de passe doit contenir un chiffre",
                        "passwordContainUppercase": "Le mot de passe doit contenir une majuscule",
                        "passwordContainLowercase": "Le mot de passe doit contenir une minuscule",
                        "passwordSame": "Les mots de passe doivent correspondre"
                    },
                    "prices": {
                        "price": "Prix",
                        "join": "Rejoindre",
                        "susbcribe": "S'abonner",
                        "active": "Actif"
                    },
                    "profile": {
                        "updateProfileButton": "Mettre à jour le profil",
                        "noCustomer": "Aucun client créé",
                        "pricesButton": "Voir les produits",
                        "createCustomer": "Mettre à jour le profil"
                    },
                    "overview": {
                        "free": {
                            "description1": "Nombre maximum de pages : 15",
                            "description2": "Nombre maximum de visites : 10",
                            "description3": "Plus d'informations..."
                        },
                        "basic": {
                            "description1": "Nombre maximum de pages : 20",
                            "description2": "Nombre maximum de visites : 50",
                            "description3": "Plus d'informations..."
                        },
                        "premium": {
                            "description1": "Nombre maximum de pages : 50",
                            "description2": "Nombre maximum de visites : 100",
                            "description3": "Plus d'informations..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Abonnement réussi !",
                            "updated": "Profil mis à jour !",
                            "cancel": "Une erreur s'est produite !",
                            "created": "Client créé avec succès !"
                        },
                        "invoice": "Voir la facture : ",
                        "invoiceLink": "Facture",
                        "button": "Retour au menu !"
                    }
                }
            },

            // Italienisch
            "it": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Scegli un file o trascinalo qui"
                    },
                    "common": {
                        "somethingWentWrong": "Ops, qualcosa è andato storto!",
                        "entriesPerPage": "Voci per pagina",
                        "confirmPhrase": "Per favore, inserisci <strong>{{confirmPhrase}}</strong> per confermare",
                        "confirm": "Confermare",
                        "cancel": "Annulla",
                        "copiedToClipboard": "Aggiunto agli appunti!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Prodotti",
                            "pricing": "Prezzi",
                            "aboutUs": "Chi siamo",
                            "docs": "Documenti",
                            "contact": "Contatto"
                        },
                        "hero0": "Il tuo assistente chatbot personalizzato. Alimentalo con i tuoi manuali, siti web e FAQ per rispondere automaticamente, in modo intelligente e preciso alle richieste dei clienti. Un'applicazione SaaS multilingue, interattiva e facile da usare per un supporto clienti efficiente e personalizzato.",
                        "hero1": "Registrati",
                        "section0": "Assistente chatbot addestrato individualmente per un'interazione cliente personalizzata.",
                        "section1": "Allevia i tuoi esperti grazie alla gestione delle richieste di supporto da parte dell'IA.",
                        "section2": "Esperienza utente ottimizzata grazie all'apprendimento contestuale, alla formazione interattiva e al miglioramento continuo della qualità delle risposte.",
                        "section3": "Portata globale e flessibilità grazie al supporto di molte lingue, fonti e tipi di documenti.",
                        "section4": "Un piano di pagamento flessibile è disponibile per tutti i nostri servizi.",
                        "tech0": "Tecnologia",
                        "tech1": "BotBucket utilizza la tecnica NLP della 'risposta alle domande contestuali': le informazioni che fornisci vengono caricate in una memoria unificata, ottimizzata per l'elaborazione del linguaggio naturale (NLP). Questa memoria viene utilizzata durante la chat per trovare blocchi di informazioni rilevanti in relazione alla domanda dell'utente. Un modello di IA combina quindi questi blocchi in una risposta sensata.",
                        "tech2": "Scopri di più",
                        "aboutUs0": "Chi siamo",
                        "aboutUs1": "Benvenuti su BotBucket! Qui alimenti i modelli GPT più recenti con i tuoi documenti. Così crei il tuo assistente di chat personalizzato in pochi secondi. Con BotBucket colleghi il tuo patrimonio di conoscenze con le possibilità di elaborazione del linguaggio naturale più moderne. In questo modo, BotBucket alleggerisce il tuo supporto clienti, crea trasparenza e migliora i tuoi processi.",
                        "aboutUs2": "Contatto",
                        "contact0": "Contattaci!",
                        "contact1": "Nome",
                        "contact2": "Email",
                        "contact3": "Oggetto",
                        "contact4": "Messaggio",
                        "contact5": "Invia"
                    },
                    "userMenu": {
                        "login": "Accedi",
                        "profile": "Profilo",
                        "settings": "Impostazioni",
                        "logout": "Disconnetti"
                    },
                    "dashboard": {
                        "welcome": "Benvenuto",
                        "myChatbots": "I miei chatbot",
                        "newChatbot": "Nuovo chatbot",
                        "instantChatbot": "Chatbot istantaneo"
                    },
                    "chatbot": {
                        "myBot": "Il mio bot - {{botName}}",
                        "settings": "Impostazioni",
                        "fileTopic": "Tema (separati da virgole)",
                        "topic": "Tema",
                        "includedInTraining": "Questo file è stato incluso nell'ultimo addestramento ed è ora conosciuto dal chatbot.",
                        "notIncludedInTraining": "Questo file non è ancora stato considerato. Addestralo ora per includere questo file.",
                        "trainingStatus": {
                            "notTrained": "Non ancora addestrato",
                            "pending": "L'addestramento inizierà presto",
                            "training": "Addestramento in corso",
                            "trained": "Addestramento riuscito",
                            "failed": "Addestramento fallito",
                            "label": "Stato dell'addestramento"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Personalizza il tuo chatbot!",
                            "publicAvailability": "Disponibilità pubblica",
                            "publicAvailable": "Il tuo chatbot è visibile a tutti. Puoi condividerlo con il link qui sotto.",
                            "notPublicAvailable": "Solo tu puoi vedere il tuo chatbot. Usa l'interruttore per renderlo visibile a tutti.",
                            "publicLink": "Link pubblico",
                            "deleteYourBot": "Elimina il tuo chatbot",
                            allowUsers: 'Permetti agli utenti',
                            searchUser: 'Cerca utente',
                            addUser: 'Aggiungi utente',
                            allowedUsers: 'Utenti autorizzati',
                            username: 'Nome utente',
                            email: 'Email',
                            enterEmail: 'Inserisci l\'email dell\'utente a cui vuoi dare accesso',
                            actions: 'Azioni',
                            userAdded: 'Utente aggiunto',
                            userRemoved: 'Utente rimosso',
                            noUsersAllowed: 'Nessun utente autorizzato',
                            removeUser: 'Rimuovi utente',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Aggiungi documenti al chatbot",
                            "uploadingFiles": "Caricamento dei documenti in corso...",
                            "filesStillUploading": "Alcuni documenti non sono ancora stati completamente caricati, attendere prego",
                            "submit": "Conferma"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Addestra il tuo chatbot",
                            "embeddings": "Embeddings",
                            "startTraining": "Inizia l'addestramento"
                        },
                        "botNotTrainedNoTrainingPossible": "Il bot non è addestrato - attualmente nessuna chat possibile",
                        "chat": "Chat",
                        "botTraining": "Il bot è attualmente in addestramento - attendere prego",
                        "trainNow": "Addestra ora",
                        "uploadedDocuments": "Documenti di addestramento",
                        "document": "Documento",
                        "uploadedDate": "Data di caricamento",
                        "noFilesUploaded": "Nessun documento caricato",
                        "deleteBot": "Elimina il chatbot",
                        "confirmDelete": "Vuoi davvero eliminare il tuo chatbot {{botName}}?"
                    },
                    "chatbots": {
                        "myChatbots": "I miei chatbot",
                        "newChatbot": "Nuovo chatbot",
                        "chat": "Chatta",
                        "edit": "Modifica",
                        "name": "Nome",
                        "numTrainingDocs": "# Documenti di addestramento",
                        "numQueries": "# Richieste",
                        "trainingStatus": "Stato",
                        "noChatbotCreated": "Nessun chatbot creato.",
                        "delete": "Elimina"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Crea un nuovo chatbot",
                        "name": "Nome",
                        "description": "Descrizione",
                        "createBot": "Crea un bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "A cosa servono gli 'argomenti' nella mia chat?"
                        },
                        "topic": "Argomento",
                        "myChatWith": "La mia chat con {{botName}}",
                        "initMessage": "Ciao, sono {{botName}}. So tutto su {{description}}. Cosa vuoi sapere?",
                        "initMessageCIS": "Ciao, sono {{botName}}. Voglio saperne di più sulle tue attività quotidiane per documentarle. Questo è destinato ad aiutarti, te e i tuoi colleghi, a orientarti meglio o a sostituirti.",
                        "foundTheseSources": "Ulteriori informazioni qui:",
                        "userInput": "Il tuo input",
                        "dontSharePersonalData": "La cronologia delle chat viene conservata per tre mesi. Si prega di non condividere dati personali."
                    },
                    "helperBot": {
                        "iCanHelp": "Posso aiutarti!",
                        "helpBotWelcome": "Ciao, sono BotBucket. Come posso aiutarti?"
                    },
                    "login": {
                        "login": "Accedi",
                        "signUp": "Registrati",
                        "username": "Nome utente",
                        "password": "Password",
                        "passwordRepeat": "Ripeti la password",
                        "mail": "Email",
                        "navToLogin": "Accedi",
                        "navToSignUp": "Registrati",
                        "usernameOrEmail": "Nome utente o email",
                        "forgotPassword": "Hai dimenticato la password?",
                        "resetPassword": "Reimposta la password",
                        "reset": "Reimposta",
                        "passwordResetted": "Password reimpostata",
                        "thanksWeSentResetMail": "Grazie! Ti abbiamo inviato un'email con ulteriori istruzioni.",
                        "passwordSuccessfullyResettedLoginAgain": "La tua password è stata reimpostata con successo. Puoi ora accedere con la tua nuova password.",
                        "helloPleaseChooseNewPassword": "Ciao, {{username}}! Per favore, imposta la tua nuova password!",
                        "newPassword": "Nuova password",
                        "newPasswordRepeat": "Ripeti la nuova password",
                        "resetLinkNotValid": "Purtroppo, il tuo link non è valido o è scaduto. Per favore, richiedi nuovamente la reimpostazione della password.",
                        "or": "Oppure",
                        "userNotFound": "Utente non trovato - per favore",
                        "userAlreadyTaken": "Nome utente già preso - per favore"
                    },
                    "validations": {
                        "usernameMinChar": "Il nome utente deve essere lungo almeno 3 caratteri",
                        "chatbotNameMinChar": "Il nome del chatbot deve essere lungo almeno 6 caratteri",
                        "enterValidMail": "L'email non è valida",
                        "passwordMinChar": "La password deve essere lunga almeno 8 caratteri",
                        "passwordContainDigit": "La password deve contenere un numero",
                        "passwordContainUppercase": "La password deve contenere una lettera maiuscola",
                        "passwordContainLowercase": "La password deve contenere una lettera minuscola",
                        "passwordSame": "Le password devono coincidere"
                    },
                    "prices": {
                        "price": "Prezzo",
                        "join": "Unisciti",
                        "susbcribe": "Iscriviti",
                        "active": "Attivo"
                    },
                    "profile": {
                        "updateProfileButton": "Aggiorna il profilo",
                        "noCustomer": "Nessun cliente creato",
                        "pricesButton": "Panoramica dei prodotti",
                        "createCustomer": "Aggiorna il profilo"
                    },
                    "overview": {
                        "free": {
                            "description1": "Numero massimo di pagine: 15",
                            "description2": "Numero massimo di visite: 10",
                            "description3": "Ulteriori informazioni..."
                        },
                        "basic": {
                            "description1": "Numero massimo di pagine: 20",
                            "description2": "Numero massimo di visite: 50",
                            "description3": "Ulteriori informazioni..."
                        },
                        "premium": {
                            "description1": "Numero massimo di pagine: 50",
                            "description2": "Numero massimo di visite: 100",
                            "description3": "Ulteriori informazioni..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Abbonamento riuscito!",
                            "updated": "Profilo aggiornato!",
                            "cancel": "Si è verificato un errore!",
                            "created": "Cliente creato con successo!"
                        },
                        "invoice": "Vedi la fattura: ",
                        "invoiceLink": "Fattura",
                        "button": "Torna al menu!"
                    }
                }
            },

            // Japanisch
            "ja": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "ファイルを選択するか、ここにドラッグ＆ドロップしてください"
                    },
                    "common": {
                        "somethingWentWrong": "おっと、何かがうまくいかなかったようです！",
                        "entriesPerPage": "ページあたりのエントリ数",
                        "confirmPhrase": "確認のために<strong>{{confirmPhrase}}</strong>を入力してください",
                        "confirm": "確認",
                        "cancel": "キャンセル",
                        "copiedToClipboard": "クリップボードにコピーしました！"
                    },
                    "homepage": {
                        "header": {
                            "products": "製品",
                            "pricing": "価格",
                            "aboutUs": "私たちについて",
                            "docs": "ドキュメント",
                            "contact": "連絡先"
                        },
                        "hero0": "あなた専用のチャットボットアシスタント。マニュアル、ウェブサイト、FAQでチャットボットを育成し、顧客の質問に自動的、知的かつ正確に回答します。効率的でパーソナライズされたカスタマーサポートのための、使いやすく、対話型で多言語対応のSaaSアプリケーションです。",
                        "hero1": "登録",
                        "section0": "カスタマイズされた顧客インタラクションのための個別にトレーニングされたチャットボットアシスタント。",
                        "section1": "多くのサポートリクエストをAIが処理することで、専門家の負担を軽減。",
                        "section2": "コンテキスト学習、対話型トレーニング、回答の質の継続的な改善による最適化されたユーザー体験。",
                        "section3": "多言語、多くのソース、ドキュメントタイプのサポートによるグローバルなリーチと柔軟性。",
                        "section4": "すべてのサービスに対応する柔軟な支払いプランをご用意しています。",
                        "tech0": "テクノロジー",
                        "tech1": "BotBucketは「コンテキスト認識質問応答」のNLP技術を使用しています。提供された情報は、自然言語処理（NLP）に最適化された統一ストレージにロードされます。このストレージはチャット中にユーザーの質問に関連する情報ブロックを見つけるために使用され、AIモデルがこれらのブロックを意味のある回答にまとめます。",
                        "tech2": "詳細を読む",
                        "aboutUs0": "私たちについて",
                        "aboutUs1": "BotBucketへようこそ！ここでは最新のGPTモデルにあなたのドキュメントを入力し、数秒で個別のチャットアシスタントを作成します。BotBucketを使用すると、最新の自然言語処理技術とあなたの知識の宝庫を結びつけることができます。これにより、BotBucketはカスタマーサポートを軽減し、透明性を高め、プロセスを改善します。",
                        "aboutUs2": "連絡先",
                        "contact0": "お問い合わせください！",
                        "contact1": "名前",
                        "contact2": "メール",
                        "contact3": "件名",
                        "contact4": "メッセージ",
                        "contact5": "送信"
                    },
                    "userMenu": {
                        "login": "ログイン",
                        "profile": "プロフィール",
                        "settings": "設定",
                        "logout": "ログアウト"
                    },
                    "dashboard": {
                        "welcome": "ようこそ",
                        "myChatbots": "私のチャットボット",
                        "newChatbot": "新しいチャットボット",
                        "instantChatbot": "インスタントチャットボット"
                    },
                    "chatbot": {
                        "myBot": "私のボット - {{botName}}",
                        "settings": "設定",
                        "fileTopic": "テーマ（, で区切る）",
                        "topic": "テーマ",
                        "includedInTraining": "このファイルは最新のトレーニングに含まれており、チャットボットに認識されています。",
                        "notIncludedInTraining": "このファイルはまだ考慮されていません。今すぐトレーニングしてこのファイルを含めてください。",
                        "trainingStatus": {
                            "notTrained": "まだトレーニングされていません",
                            "pending": "トレーニングがまもなく開始されます",
                            "training": "トレーニング中",
                            "trained": "トレーニングが成功しました",
                            "failed": "トレーニングに失敗しました",
                            "label": "トレーニングステータス"
                        },
                        "settingsModal": {
                            "customizeYourBot": "チャットボットをカスタマイズしよう！",
                            "publicAvailability": "公開設定",
                            "publicAvailable": "あなたのチャットボットは全員に公開されています。以下のリンクで共有できます。",
                            "notPublicAvailable": "あなたのチャットボットはあなただけが見ることができます。トグルで全員に公開することができます。",
                            "publicLink": "公開リンク",
                            deleteYourBot: "チャットボットを削除",
                            allowUsers: "ユーザーを許可",
                            searchUser: "ユーザーを検索",
                            addUser: "ユーザーを追加",
                            allowedUsers: "許可されたユーザー",
                            username: "ユーザー名",
                            email: "メール",
                            enterEmail: "アクセスを許可するユーザーのメールアドレスを入力してください",
                            actions: "アクション",
                            userAdded: "ユーザーが追加されました",
                            userRemoved: "ユーザーが削除されました",
                            noUsersAllowed: "許可されたユーザーはいませ���",
                            removeUser: "ユーザーを削除"
                        },
                        "botNotTrainedNoTrainingPossible": "ボットはトレーニングされていません - 現在チャットはできません",
                        "chat": "チャット",
                        "botTraining": "ボットは現在トレーニング中です - お待ちください",
                        "trainNow": "今すぐトレーニング",
                        "uploadedDocuments": "トレーニングドキュメント",
                        "document": "ドキュメント",
                        "uploadedDate": "アップロード日",
                        "noFilesUploaded": "まだドキュメントがアップロードされていません",
                        "deleteBot": "チャットボットを削除",
                        "confirmDelete": "本当にチャットボット{{botName}}を削除しますか？"
                    },
                    "chatbots": {
                        "myChatbots": "私のチャットボット",
                        "newChatbot": "新しいチャットボット",
                        "chat": "チャット",
                        "edit": "編集",
                        "name": "名前",
                        "numTrainingDocs": "# トレーニングドキュメント",
                        "numQueries": "# クエリ",
                        "trainingStatus": "ステータス",
                        "noChatbotCreated": "まだチャットボットが作成されていません。",
                        "delete": "削除"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "新しいチャットボットを作成",
                        "name": "名前",
                        "description": "説明",
                        "createBot": "ボットを作成"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "チャットで「トピック」は何のために役立ちますか？"
                        },
                        "topic": "トピック",
                        "myChatWith": "{{botName}}とのチャット",
                        "initMessage": "こんにちは、私は{{botName}}です。{{description}}についてすべて知っています。何を知りたいですか？",
                        "initMessageCIS": "こんにちは、私は{{botName}}です。あなたの日常の業務についてもっと知りたいので、それを記録します。これはあなたや同僚がより良くオンボードしたり代わりを務めたりするのに役立ちます。",
                        "foundTheseSources": "詳細情報はこちら：",
                        "userInput": "あなたの入力",
                        "dontSharePersonalData": "チャット履歴は3か月間保存されます。個人情報は共有しないでください。"
                    },
                    "helperBot": {
                        "iCanHelp": "手伝います！",
                        "helpBotWelcome": "こんにちは、私はBotBucketです。どうお手伝いできますか？"
                    },
                    "login": {
                        "login": "ログイン",
                        "signUp": "サインアップ",
                        "username": "ユーザー名",
                        "password": "パスワード",
                        "passwordRepeat": "パスワード（確認）",
                        "mail": "メール",
                        "navToLogin": "ログイン",
                        "navToSignUp": "サインアップ",
                        "usernameOrEmail": "ユーザー名またはメール",
                        "forgotPassword": "パスワードをお忘れですか？",
                        "resetPassword": "パスワードをリセット",
                        "reset": "リセット",
                        "passwordResetted": "パスワードがリセットされました",
                        "thanksWeSentResetMail": "ありがとうございます！次の手順を記載したメールをお送りしました。",
                        "passwordSuccessfullyResettedLoginAgain": "パスワードが正常にリセットされました。新しいパスワードでログインできます。",
                        "helloPleaseChooseNewPassword": "こんにちは、{{username}}！新しいパスワードを設定してください！",
                        "newPassword": "新しいパスワード",
                        "newPasswordRepeat": "新しいパスワード（確認）",
                        "resetLinkNotValid": "リンクが無効か期限切れです。パスワードリセットを再度要求してください。",
                        "or": "または",
                        "userNotFound": "ユーザーが見つかりません - どうぞ",
                        "userAlreadyTaken": "ユーザー名がすでに使用されています - どうぞ"
                    },
                    "validations": {
                        "usernameMinChar": "ユーザー名は少なくとも3文字でなければなりません",
                        "chatbotNameMinChar": "チャットボットの名前は少なくとも6文字でなければなりません",
                        "enterValidMail": "メールが無効です",
                        "passwordMinChar": "パスワードは少なくとも8文字でなければなりません",
                        "passwordContainDigit": "パスワードには数字が含まれている必要があります",
                        "passwordContainUppercase": "パスワードには大文字が含まれている必要があります",
                        "passwordContainLowercase": "パスワードには小文字が含まれている必要があります",
                        "passwordSame": "パスワードが一致しなければなりません"
                    },
                    "prices": {
                        "price": "価格",
                        "join": "参加",
                        "susbcribe": "購読",
                        "active": "アクティブ"
                    },
                    "profile": {
                        "updateProfileButton": "プロフィールを更新",
                        "noCustomer": "顧客が作成されていません",
                        "pricesButton": "製品の概要",
                        "createCustomer": "プロフィールを更新"
                    },
                    "overview": {
                        "free": {
                            "description1": "最大ページ数：15",
                            "description2": "最大訪問数：10",
                            "description3": "詳細情報..."
                        },
                        "basic": {
                            "description1": "最大ページ数：20",
                            "description2": "最大訪問数：50",
                            "description3": "詳細情報..."
                        },
                        "premium": {
                            "description1": "最大ページ数：50",
                            "description2": "最大訪問数：100",
                            "description3": "詳細情報..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "サブスクリプション成功！",
                            "updated": "プロフィールが更新されました！",
                            "cancel": "エラーが発生しました！",
                            "created": "顧客が正常に作成されました！"
                        },
                        "invoice": "請求書を表示：",
                        "invoiceLink": "請求書",
                        "button": "メニューに戻る！"
                    }
                }
            },

            // Griechisch
            "el": {
                "translation": {
                    "dragAndDrop": {
                        "chooseFile": "Επιλέξτε αρχείο ή σύρετε το εδώ"
                    },
                    "common": {
                        "somethingWentWrong": "Ωχ, κάτι πήγε στραβά!",
                        "entriesPerPage": "Καταχωρήσεις ανά σελίδα",
                        "confirmPhrase": "Παρακαλώ εισάγετε <strong>{{confirmPhrase}}</strong> για επιβεβαίωση",
                        "confirm": "Επιβεβαίωση",
                        "cancel": "Ακύρωση",
                        "copiedToClipboard": "Αντιγράφηκε στο πρόχειρο!"
                    },
                    "homepage": {
                        "header": {
                            "products": "Προϊόντα",
                            "pricing": "Τιμολόγηση",
                            "aboutUs": "Σχετικά με εμάς",
                            "docs": "Έγγραφα",
                            "contact": "Επικοινωνία"
                        },
                        "hero0": "Ο εξατομικευμένος βοηθός chatbot σας. Τροφοδοτήστε τον με τα εγχειρίδιά σας, ιστοσελίδες και Συχνές Ερωτήσεις για να απαντά αυτόματα, έξυπνα και με ακρίβεια στις ερωτήσεις των πελατών. Μια φιλική προς τον χρήστη, διαδραστική και πολύγλωσση εφαρμογή λογισμικού ως υπηρεσία (SaaS) για αποδοτική και εξατομικευμένη υποστήριξη πελατών.",
                        "hero1": "Εγγραφή",
                        "section0": "Εξατομικευμένος βοηθός chatbot για προσαρμοσμένη αλληλεπίδραση με πελάτες.",
                        "section1": "Ανακούφιση των ειδικών σας μέσω της ανάληψης των περισσότερων αιτημάτων υποστήριξης με τη βοήθεια της τεχνητής νοημοσύνης.",
                        "section2": "Βελτιστοποιημένη εμπειρία χρήστη μέσω της εκμάθησης του πλαισίου, διαδραστικής εκπαίδευσης και συνεχούς βελτίωσης της ποιότητας των απαντήσεων.",
                        "section3": "Παγκόσμια εμβέλεια και ευελιξία με την υποστήριξη πολλών γλωσσών, πηγών και τύπων εγγράφων.",
                        "section4": "Ένα ευέλικτο σχέδιο πληρωμών είναι διαθέσιμο για όλες τις υπηρεσίες μας.",
                        "tech0": "Τεχνολογία",
                        "tech1": "Το BotBucket χρησιμοποιεί την τεχνική NLP της 'ερωταποκρίσης με βάση το πλαίσιο': Οι πληροφορίες που παρέχετε φορτώνονται σε μια ενιαία αποθήκη, η οποία είναι βελτιστοποιημένη για την επεξεργασία φυσικής γλώσσας (NLP). Αυτή η αποθήκη χρησιμοποιείται κατά τη διάρκεια της συνομιλίας για να βρεθούν σχετικοί μπλοκ πληροφοριών σχετικά με την ερώτηση του χρήστη. Ένα μοντέλο AI συνδυάζει αυτά τα μπλοκ σε μια νόημα απάντηση.",
                        "tech2": "Μάθετε περισσότερα",
                        "aboutUs0": "Σχετικά με εμάς",
                        "aboutUs1": "Καλώς ήρθατε στο BotBucket! Εδώ τροφοδοτείτε τα τελευταία μοντέλα GPT με τα έγγραφά σας. Έτσι δημιουργείτε τον δικό σας εξατομικευμένο βοηθό chatbot σε δευτερόλεπτα. Με το BotBucket συνδέετε τον θησαυρό της γνώσης σας με τις δυνατότητες της σύγχρονης επεξεργασίας φυσικής γλώσσας. Έτσι το BotBucket ανακουφίζει την υποστήριξη πελατών σας, δημιουργεί διαφάνεια και βελτιώνει τις διαδικασίες σας.",
                        "aboutUs2": "Επικοινωνία",
                        "contact0": "Επικοινωνήστε μαζί μας!",
                        "contact1": "Όνομα",
                        "contact2": "Email",
                        "contact3": "Θέμα",
                        "contact4": "Μήνυμα",
                        "contact5": "Αποστολή"
                    },
                    "userMenu": {
                        "login": "Σύνδεση",
                        "profile": "Προφίλ",
                        "settings": "Ρυθμίσεις",
                        "logout": "Αποσύνδεση"
                    },
                    "dashboard": {
                        "welcome": "Καλώς ήρθατε",
                        "myChatbots": "Τα Chatbots μου",
                        "newChatbot": "Νέο Chatbot",
                        "instantChatbot": "Instant Chatbot"
                    },
                    "chatbot": {
                        "myBot": "Ο Μποτ μου - {{botName}}",
                        "settings": "Ρυθμίσεις",
                        "fileTopic": "Θέμα (χωρισμένα με κόμμα)",
                        "topic": "Θέμα",
                        "includedInTraining": "Αυτό το αρχείο συμπεριλήφθηκε στην τελευταία εκπαίδευση και είναι πλέον γνωστό στο chatbot.",
                        "notIncludedInTraining": "Αυτό το αρχείο δεν έχει συμπεριληφθεί ακόμη. Τώρα εκπαιδεύστε για να συμπεριλάβετε αυτό το αρχείο.",
                        "trainingStatus": {
                            "notTrained": "Δεν έχει εκπαιδευτεί ακόμη",
                            "pending": "Η εκπαίδευση θα ξεκινήσει σύντομα",
                            "training": "Εκπαίδευση σε εξέλιξη",
                            "trained": "Η εκπαίδευση ήταν επιτυχής",
                            "failed": "Η εκπαίδευση απέτυχε",
                            "label": "Κατάσταση εκπαίδευσης"
                        },
                        "settingsModal": {
                            "customizeYourBot": "Προσαρμόστε τον chatbot σας!",
                            "publicAvailability": "Δημόσια διαθεσιμότητα",
                            "publicAvailable": "Ο chatbot σας είναι ορατός σε όλους. Μπορείτε να τον μοιραστείτε με τον παρακάτω σύνδεσμο.",
                            "notPublicAvailable": "Μόνο εσείς μπορείτε να δείτε τον chatbot σας. Μέσω του εναλλασσόμενου κουμπιού μπορείτε να τον κάνετε ορατό σε όλους.",
                            "publicLink": "Δημόσιος σύνδεσμος",
                            "deleteYourBot": "Διαγραφή του chatbot σας",
                            allowUsers: 'Επιτρέψτε χρήστες',
                            searchUser: 'Αναζήτηση χρήστη',
                            addUser: 'Προσθήκη χρήστη',
                            allowedUsers: 'Επιτρεπόμενοι χρήστες',
                            username: 'Όνομα χρήστη',
                            email: 'Ηλεκτρονικό ταχυδρομείο',
                            enterEmail: 'Εισαγάγετε το email του χρήστη που θέλετε να επιτρέψετε την πρόσβαση',
                            actions: 'Ενέργειες',
                            userAdded: 'Ο χρήστης προστέθηκε',
                            userRemoved: 'Ο χρήστης αφαιρέθηκε',
                            noUsersAllowed: 'Δεν επιτρέπονται χρήστες',
                            removeUser: 'Αφαίρεση χρήστη',
                        },
                        "uploadFiles": {
                            "addFilesToChatbot": "Προσθέστε έγγραφα στον chatbot",
                            "uploadingFiles": "Ανεβάζω έγγραφα...",
                            "filesStillUploading": "Ορισμένα έγγραφα δεν έχουν ανεβεί πλήρως, παρακαλώ περιμένετε",
                            "submit": "Επιβεβαίωση"
                        },
                        "trainChatbot": {
                            "trainYourChatbot": "Εκπαιδεύστε τον chatbot σας",
                            "embeddings": "Embeddings",
                            "startTraining": "Έναρξη εκπαίδευσης"
                        },
                        "botNotTrainedNoTrainingPossible": "Ο bot δεν είναι εκπαιδευμένος - επί του παρόντος δεν είναι δυνατή η συνομιλία",
                        "chat": "Συνομιλία",
                        "botTraining": "Ο bot είναι επί του παρόντος σε εκπαίδευση - παρακαλώ περιμένετε",
                        "trainNow": "Εκπαιδεύστε τώρα",
                        "uploadedDocuments": "Έγγραφα εκπαίδευσης",
                        "document": "Έγγραφο",
                        "uploadedDate": "Ημερομηνία ανάρτησης",
                        "noFilesUploaded": "Δεν έχουν ανεβεί έγγραφα ακόμη",
                        "deleteBot": "Διαγραφή Chatbot",
                        "confirmDelete": "Θέλετε πραγματικά να διαγράψετε τον chatbot {{botName}};"
                    },
                    "chatbots": {
                        "myChatbots": "Τα Chatbots μου",
                        "newChatbot": "Νέο Chatbot",
                        "chat": "Συνομιλία",
                        "edit": "Επεξεργασία",
                        "name": "Όνομα",
                        "numTrainingDocs": "# Έγγραφα εκπαίδευσης",
                        "numQueries": "# Αιτήματα",
                        "trainingStatus": "Κατάσταση",
                        "noChatbotCreated": "Δεν υπάρχουν ακόμη chatbots.",
                        "delete": "Διαγραφή"
                    },
                    "createChatbots": {
                        "createNewBotHeadline": "Δημιουργία νέου chatbot",
                        "name": "Όνομα",
                        "description": "Περιγραφή",
                        "createBot": "Δημιουργία bot"
                    },
                    "chat": {
                        "help": {
                            "whatAreTopicsGoodFor": "Γιατί είναι χρήσιμα τα 'Θέματα' στην συνομιλία μου;"
                        },
                        "topic": "Θέμα",
                        "myChatWith": "Η συνομιλία μου με τον {{botName}}",
                        "initMessage": "Γεια, είμαι ο {{botName}}. Ξέρω τα πάντα για τον/την {{description}}. Τι θέλετε να μάθετε;",
                        "initMessageCIS": "Γεια, είμαι ο {{botName}}. Θέλω να μάθω περισσότερα για τις καθημερινές σας εργασίες, για να τις καταγράψω. Αυτό θα βοηθήσει εσάς και τους συναδέλφους σας να ενσωματωθείτε καλύτερα ή να αντικατασταθείτε.",
                        "foundTheseSources": "Περισσότερες πληροφορίες εδώ:",
                        "userInput": "Η εισαγωγή σας",
                        "dontSharePersonalData": "Το ιστορικό συνομιλιών αποθηκεύεται για τρεις μήνες. Μην μοιράζεστε προσωπικά δεδομένα."
                    },
                    "helperBot": {
                        "iCanHelp": "Μπορώ να βοηθήσω!",
                        "helpBotWelcome": "Γεια, είμαι ο BotBucket. Πώς μπορώ να βοηθήσω;"
                    },
                    "login": {
                        "login": "Σύνδεση",
                        "signUp": "Εγγραφή",
                        "username": "Όνομα χρήστη",
                        "password": "Κωδικός",
                        "passwordRepeat": "Κωδικός (επιβεβαίωση)",
                        "mail": "Email",
                        "navToLogin": "Σύνδεση",
                        "navToSignUp": "Εγγραφή",
                        "usernameOrEmail": "Όνομα χρήστη ή Email",
                        "forgotPassword": "Ξεχάσατε τον κωδικό;",
                        "resetPassword": "Επαναφορά κωδικού",
                        "reset": "Επαναφορά",
                        "passwordResetted": "Ο κωδικός επαναφέρθηκε",
                        "thanksWeSentResetMail": "Ευχαριστούμε! Σας στείλαμε ένα email με περαιτέρω οδηγίες.",
                        "passwordSuccessfullyResettedLoginAgain": "Ο κωδικός επαναφέρθηκε με επιτυχία. Τώρα μπορείτε να συνδεθείτε με τον νέο σας κωδικό.",
                        "helloPleaseChooseNewPassword": "Γεια, {{username}}! Παρακαλώ ορίστε τον νέο σας κωδικό!",
                        "newPassword": "Νέος κωδικός",
                        "newPasswordRepeat": "Νέος κωδικός (επιβεβαίωση)",
                        "resetLinkNotValid": "Δυστυχώς, ο σύνδεσμος σας δεν είναι έγκυρος ή έχει λήξει. Παρακαλώ ζητήστε ξανά επαναφορά του κωδικού.",
                        "or": "Ή",
                        "userNotFound": "Ο χρήστης δεν βρέθηκε - παρακαλώ",
                        "userAlreadyTaken": "Το όνομα χρήστη είναι ήδη καταχωρημένο - παρακαλώ"
                    },
                    "validations": {
                        "usernameMinChar": "Το όνομα χρήστη πρέπει να έχει τουλάχιστον 3 χαρακτήρες",
                        "chatbotNameMinChar": "Το όνομα του chatbot πρέπει να έχει τουλάχιστον 6 χαρακτήρες",
                        "enterValidMail": "Το email δεν είναι έγκυρο",
                        "passwordMinChar": "Ο κωδικός πρέπει να έχει τουλάχιστον 8 χαρακτήρες",
                        "passwordContainDigit": "Ο κωδικός πρέπει να περιέχει έναν αριθμό",
                        "passwordContainUppercase": "Ο κωδικός πρέπει να περιέχει ένα κεφαλαίο γράμμα",
                        "passwordContainLowercase": "Ο κωδικός πρέπει να περιέχει ένα πεζό γράμμα",
                        "passwordSame": "Οι κωδικοί πρέπει να ταιριάζουν"
                    },
                    "prices": {
                        "price": "Τιμή",
                        "join": "Εγγραφή",
                        "susbcribe": "Εγγραφή",
                        "active": "Ενεργός"
                    },
                    "profile": {
                        "updateProfileButton": "Ενημέρωση προφίλ",
                        "noCustomer": "Δεν έχει δημιουργηθεί πελάτης",
                        "pricesButton": "Επισκόπηση προϊόντων",
                        "createCustomer": "Ενημέρωση προφίλ"
                    },
                    "overview": {
                        "free": {
                            "description1": "Μέγιστος αριθμός σελίδων: 15",
                            "description2": "Μέγιστες προβολές: 10",
                            "description3": "Περισσότερες πληροφορίες..."
                        },
                        "basic": {
                            "description1": "Μέγιστος αριθμός σελίδων: 20",
                            "description2": "Μέγιστες προβολές: 50",
                            "description3": "Περισσότερες πληροφορίες..."
                        },
                        "premium": {
                            "description1": "Μέγιστος αριθμός σελίδων: 50",
                            "description2": "Μέγιστες προβολές: 100",
                            "description3": "Περισσότερες πληροφορίες..."
                        }
                    },
                    "success": {
                        "headline": {
                            "success": "Η εγγραφή ήταν επιτυχής!",
                            "updated": "Το προφίλ ενημερώθηκε!",
                            "cancel": "Παρουσιάστηκε σφάλμα!",
                            "created": "Ο πελάτης δημιουργήθηκε με επιτυχία!"
                        },
                        "invoice": "Δείτε το τιμολόγιο: ",
                        "invoiceLink": "Τιμολόγιο",
                        "button": "Επιστροφή στο μενού!"
                    }
                }
            },

        }
    });

export default i18n;