import * as React from "react";
import {useCallback, useEffect} from "react";
import {
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import {Line, Pie} from "react-chartjs-2";
import {useChatbotApiClient} from "../../clients/ChatbotApiClient";
import {useParams} from "react-router-dom";
import {ChatQueryStat, ChatUsageResponse} from "../../model/Chatbot";

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

export interface IStatisticsDashboardProps {}

const StatisticsDashboard: React.FunctionComponent<IStatisticsDashboardProps> = () => {

    const {botId} = useParams<{ botId?: string }>();
    
    const chatbotClient = useChatbotApiClient()
    
    const [numberOfQueries, setNumberOfQueries] = React.useState<number>(0);
    const [avgQueriesPerDay, setAvgQueriesPerDay] = React.useState<number>(0.00);
    const [lineChartData, setLineChartData] = React.useState<any>(undefined);
    const [pieChartData, setPieChartData] = React.useState<any>(undefined);
    const [startDateTime, setStartDateTime] = React.useState<string>(new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10) + "T00:00");
    const [endDateTime, setEndDateTime] = React.useState<string>(new Date().toISOString().slice(0, 10) + "T23:59");
    const [filteredQueryTable, setFilteredQueryTable] = React.useState<ChatQueryStat[]>([]);

    const calculateDaysBetween = (date1: string, date2: string): number => {
        // Parse the input date strings into Date objects
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        // Calculate the difference in milliseconds
        const diffInMilliseconds = Math.abs(d2.getTime() - d1.getTime());

        return diffInMilliseconds / (1000 * 60 * 60 * 24);
    }

    const fetchStats = useCallback(() => {
        if (botId) {
            
            const strt = startDateTime + ":00"
            const endt = endDateTime + ":59"
            chatbotClient.getChatbotUsageStats(botId, {botId, startDateTime: strt, endDateTime: endt})
                .then((res: ChatUsageResponse) => {
                    
                    let sumQueries = 0
                    res.usageByDay.map((entry) => sumQueries += entry.count)
                    setNumberOfQueries(sumQueries)
                    
                    const daysBetween = calculateDaysBetween(startDateTime, endDateTime)
                    setAvgQueriesPerDay(sumQueries / daysBetween)
                    
                    setLineChartData({
                        labels: res.usageByDay.map((entry) => entry.date),
                        datasets: [
                            {
                                label: "Queries per Day",
                                data: res.usageByDay.map((entry) => entry.count),
                                fill: false,
                                borderColor: "blue",
                                tension: 0.1,
                            },
                        ],
                    })

                    setPieChartData({
                        labels: res.usageByTopic.map((entry) => entry.date),
                        datasets: [
                            {
                                data: res.usageByTopic.map((entry) => entry.count),
                                backgroundColor: ["red", "green", "blue"],
                            },
                        ],
                    })

                    setFilteredQueryTable(res.lastQueries)
                })
                .catch((err) => {
                    console.error(err)
                })
        }
        // eslint-disable-next-line
    }, [chatbotClient, startDateTime, endDateTime])

    useEffect(() => {
        fetchStats()
    }, [fetchStats])
    
    const handleDateFilter = () => {
        return
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography>

            {/* Date-Time Filters */}
            <Grid container spacing={3} style={{ marginBottom: "16px" }}>
                <Grid item xs={4}>
                    <TextField
                        label="Start Date & Time"
                        type="datetime-local"
                        fullWidth
                        value={startDateTime}
                        onChange={(e) => setStartDateTime(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="End Date & Time"
                        type="datetime-local"
                        fullWidth
                        value={endDateTime}
                        onChange={(e) => setEndDateTime(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleDateFilter}
                    >
                        Apply Filter
                    </Button>
                </Grid>
            </Grid>

            {/* Grid Layout */}
            <Grid container spacing={3}>

                {/* Total Queries */}
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ padding: "16px", textAlign: "center" }}>
                        <Typography variant="h6">Total Queries</Typography>
                        <Typography variant="h3" color="primary">
                            {numberOfQueries}
                        </Typography>
                    </Paper>
                </Grid>
                {/* Avg Queries per Day */}
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ padding: "16px", textAlign: "center" }}>
                        <Typography variant="h6">⌀ Queries per Day</Typography>
                        <Typography variant="h3" color="primary">
                            {Math.round(avgQueriesPerDay * 100) / 100}
                        </Typography>
                    </Paper>
                </Grid>
                
                {/* Timeline */}
                <Grid item xs={8}>
                    <Paper elevation={3} style={{ padding: "16px" }}>
                        <Typography variant="h6">Timeline: Queries per Day</Typography>
                        {lineChartData && <Line data={lineChartData} /> }
                    </Paper>
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: "16px" }}>
                        <Typography variant="h6">Query Topics Distribution</Typography>
                        {pieChartData && <Pie data={pieChartData} /> }
                    </Paper>
                </Grid>

                {/* Query Table */}
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: "16px" }}>
                        <Typography variant="h6">Queries Information</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell>Topic</TableCell>
                                        <TableCell>Question</TableCell>
                                        <TableCell>Answer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredQueryTable.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
                                            <TableCell>{row.about}</TableCell>
                                            <TableCell>{row.question}</TableCell>
                                            <TableCell>{row.answer}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default StatisticsDashboard;
