import React from "react";
import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";

const dotAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  33% {
    opacity: 1;
    transform: scale(1.2);
  }
  66% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

interface LoadingDotsProps {
    size?: number;
    color?: string;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ size = 8, color = "primary.main" }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
        >
            {[0, 1, 2].map((index) => (
                <Box
                    key={index}
                    sx={{
                        width: `${size}px`,
                        height: `${size}px`,
                        backgroundColor: color,
                        borderRadius: "50%",
                        animation: `${dotAnimation} 1.5s infinite`,
                        animationDelay: `${index * 0.3}s`,
                    }}
                />
            ))}
        </Box>
    );
};

export default LoadingDots;